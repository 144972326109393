import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { ForgotPasswordForm } from '@components';
import { adminForgotPasswordAction, setLoading, useAppDispatch } from '@redux';
import { ForgotPasswordSchema } from '@validations';
import { useFakeLoading } from '@utils';
import { FAKE_WAITING_MS } from '@configs';

export const ForgotPasswordModule: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ForgotPasswordSchema),
  });

  useFakeLoading(FAKE_WAITING_MS);

  const handleForgotPassword = handleSubmit(async (values, event) => {
    event?.preventDefault();
    if (!isValid) return;
    try {
      dispatch(setLoading(true));
      await dispatch(adminForgotPasswordAction({ email: values.email })).then(() => {
        dispatch(setLoading(false));
      });
    } catch (err: any) {
      dispatch(setLoading(false));
    }
  });

  return (
    <ForgotPasswordForm
      register={register}
      errors={errors}
      handleForgotPassword={handleForgotPassword}
    />
  );
};
