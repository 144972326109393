import {
  DetailsAchievements,
  DetailsItemHeading,
  DetailsListItem,
  ShareSelectInput,
  SharedButton,
  SharedIcon,
  StyledSection,
} from '@components';
import { enumGender, enumTailorRegistrationStatus } from '@configs';
import { ITailorDetails, ITailorSocialMedia } from '@interfaces';
import { selectApp, useAppSelector } from '@redux';
import { APP_COLORS } from '@theme';
import { Button } from 'antd';
import { type Dictionary } from 'lodash';
import styled from 'styled-components';

interface IProps {
  tailorDetails?: ITailorDetails;
  tailorMaterialsWorked?: string;
  tailorSocialMedia: Dictionary<ITailorSocialMedia[]>;
  status?: enumTailorRegistrationStatus;
  handleRenderTailorSkill: (gender: enumGender) => string | undefined;
  onStatusChange: (value: string) => void;
  handleCancel: () => void;
  handleSave: () => void;
}

export const TailorRegistrationDetailsSection: React.FC<IProps> = ({
  tailorDetails,
  tailorMaterialsWorked,
  tailorSocialMedia,
  status,
  handleRenderTailorSkill,
  onStatusChange,
  handleCancel,
  handleSave,
}) => {
  const { loading } = useAppSelector(selectApp);

  return (
    <StyledSection>
      <DetailsSectionStyled>
        <DetailsItemHeading level="h3">Contact information</DetailsItemHeading>
        <ListStyled>
          <DetailsListItem label="First Name:" content={tailorDetails?.firstName} />
          <DetailsListItem label="Last Name:" content={tailorDetails?.lastName} />
          <DetailsListItem label="Email:" content={tailorDetails?.email} />
          <DetailsListItem
            label="Address 1:"
            content={tailorDetails?.tailorAddress?.addressLine1}
          />
          <DetailsListItem
            label="Address 2:"
            content={tailorDetails?.tailorAddress?.addressLine2}
          />
          <DetailsListItem
            label="Code Postal:"
            content={tailorDetails?.tailorAddress?.postalCode}
          />
          <DetailsListItem label="City:" content={tailorDetails?.tailorAddress?.city} />
          <DetailsListItem label="Country:" content={tailorDetails?.tailorAddress?.country} />
          <DetailsListItem label="Phone Number:" content={tailorDetails?.phone} />
          <DetailsListItem
            label={<SharedIcon name="facebook" size={32} />}
            content={
              tailorSocialMedia?.facebook && (
                <a
                  href={tailorSocialMedia.facebook[0].url}
                  target="_blank"
                  className="underline hover:underline underline-offset-4"
                >
                  {tailorSocialMedia?.facebook[0].url}
                </a>
              )
            }
          />
          <DetailsListItem
            label={<SharedIcon name="instagram" size={32} />}
            content={
              tailorSocialMedia?.instagram && (
                <a
                  href={tailorSocialMedia.instagram[0].url}
                  target="_blank"
                  className="underline hover:underline underline-offset-4"
                >
                  {tailorSocialMedia.instagram[0].url}
                </a>
              )
            }
          />
        </ListStyled>
      </DetailsSectionStyled>
      <DetailsSectionStyled>
        <DetailsItemHeading level="h3">Skill</DetailsItemHeading>
        <ListStyled>
          <DetailsListItem label="Women:" content={handleRenderTailorSkill(enumGender.WOMAN)} />
          <DetailsListItem label="Male:" content={handleRenderTailorSkill(enumGender.MAN)} />
          <DetailsListItem label="Daughter:" content={handleRenderTailorSkill(enumGender.GIRL)} />
          <DetailsListItem label="Boy:" content={handleRenderTailorSkill(enumGender.BOY)} />
          <DetailsListItem label="Material Worked:" content={tailorMaterialsWorked} />
          <DetailsListItem label="Capabilities:" content={tailorDetails?.clientCapacity} />
          <DetailsListItem
            label="Achievements:"
            content={
              tailorDetails &&
              tailorDetails.tailorCreations.length > 0 && (
                <DetailsAchievements achievements={tailorDetails?.tailorCreations} />
              )
            }
          />
        </ListStyled>
      </DetailsSectionStyled>

      <StatusActionStyled>
        <span>Status:</span>
        <ShareSelectInput
          disabled={tailorDetails?.validationStatus === enumTailorRegistrationStatus.APPROVED}
          value={status}
          onChange={onStatusChange}
          data={[
            { label: 'Approve', value: enumTailorRegistrationStatus.APPROVED },
            { label: 'Reject', value: enumTailorRegistrationStatus.REJECTED },
          ]}
          containerClassName="select-status"
        />
      </StatusActionStyled>

      <ActionStyled>
        <Button size="large" shape="round" onClick={handleCancel}>
          Cancel
        </Button>
        {tailorDetails?.validationStatus !== enumTailorRegistrationStatus.APPROVED && (
          <Button
            shape="round"
            size="large"
            htmlType="submit"
            form="inspiration-form"
            type="primary"
            onClick={handleSave}
            disabled={
              status === enumTailorRegistrationStatus.PENDING ||
              status === tailorDetails?.validationStatus
            }
            loading={loading}
          >
            Save
          </Button>
        )}
      </ActionStyled>
    </StyledSection>
  );
};

const DetailsSectionStyled = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2.4rem;
`;

const ListStyled = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1.2rem;
`;

const StatusActionStyled = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 18rem 1fr;
  column-gap: 6.4rem;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 1.6rem;
  color: ${(p: any) => p?.theme.colors?.text ?? APP_COLORS.gray900};
  padding: 2.4rem;
  & > span {
    font-size: 1.6rem;
    justify-self: end;
  }
  & > .select-status {
    justify-self: start;
    width: 12rem;
  }
  .ant-select-selection-item {
    text-transform: capitalize;
    font-size: 1.6rem !important;
  }
`;

const ActionStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4.8rem;
  margin-bottom: 2.4rem;
`;
