import { InspirationClothCategorySection } from '@components';
import { IGenderAPI, ICategoryAPI, ISubcategoryAPI } from '@interfaces';
import {
  createInspirationFormSelector,
  getCategoryByGenderIdAction,
  getSubcategoryByCategoryIdAction,
  inspirationSelect,
  resetListPersonalizations,
  setCategories,
  setSelectedCategory,
  setSelectedGender,
  setSelectedSubcategory,
  setSubcategories,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { RadioChangeEvent } from 'antd';
import { useEffect } from 'react';
import { getGendersAction } from 'src/redux/actions/gender';

export const InspirationClothCategoryModule: React.FC = () => {
  const dispatch = useAppDispatch();

  const { listGenders, listCategories, listSubcategories } = useAppSelector(inspirationSelect);
  const { genderId, categoryId, subcategoryId } = useAppSelector(createInspirationFormSelector);

  const onSelectedGenderChange = async (e: RadioChangeEvent): Promise<void> => {
    dispatch(setCategories([]));
    dispatch(setSelectedCategory(undefined));
    dispatch(setSubcategories(undefined));
    dispatch(setSelectedGender(e.target.value));
    await dispatch(getCategoryByGenderIdAction(e.target.value));
    dispatch(resetListPersonalizations());
  };

  const onSelectedCategoryChange = async (e: RadioChangeEvent): Promise<void> => {
    dispatch(setSubcategories([]));
    dispatch(setSelectedSubcategory(undefined));
    dispatch(setSelectedCategory(e.target.value));
    await dispatch(getSubcategoryByCategoryIdAction(e.target.value));
    dispatch(resetListPersonalizations());
  };

  const onSelectedSubcategoryChange = (e: RadioChangeEvent): void => {
    dispatch(setSelectedSubcategory(e.target.value));
    dispatch(resetListPersonalizations());
  };

  useEffect(() => {
    const promise = dispatch(getGendersAction());

    return () => {
      promise.abort();
    };
  }, []);

  return (
    <InspirationClothCategorySection
      genders={listGenders}
      onSelectedGenderChange={onSelectedGenderChange}
      categories={listCategories}
      onSelectedCategoryChange={onSelectedCategoryChange}
      subCategories={listSubcategories}
      onSelectedSubcategoryChange={onSelectedSubcategoryChange}
      selectedGender={genderId}
      selectedCategory={categoryId}
      selectedSubcategory={subcategoryId}
    />
  );
};
