import { promotionAPI } from '@api';
import {
  IPromotionBody,
  IGetListParams,
  IGetListResponse,
  IPromotionAPI,
  IPromotionDetails,
  IUpdatePromotionBody,
  ITogglePromotionBody,
  BaseResponseAPI,
} from '@interfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { showAppToast } from '@utils';
import { setLoading } from '../Slices';

const prefix = 'promotion';

export const getListPromotionsAction = createAsyncThunk<
  IGetListResponse<IPromotionAPI>,
  IGetListParams
>(`${prefix}/getListPromotionsAction`, async (params) => {
  try {
    const response = await promotionAPI.getListPromotions(params);
    return response.data;
  } catch (error: any) {
    showAppToast(error.response.data.message, 'error', 'light');
  }
});

export const getPromotionAction = createAsyncThunk<IPromotionDetails, number>(
  `${prefix}/getPromotionAction`,
  async (id, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response: any = await promotionAPI.getPromotion(id);
      if (response.success) {
        dispatch(setLoading(false));
      }
      return response.data;
    } catch (error) {
      dispatch(setLoading(false));
    }
  }
);

export const addPromotionAction = createAsyncThunk<BaseResponseAPI<IPromotionAPI>, IPromotionBody>(
  `${prefix}/addPromotionAction`,
  async (body, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response: any = await promotionAPI.addPromotion(body);
      if (response.success) {
        showAppToast(response.message, 'success', 'light');
        dispatch(setLoading(false));
      }
      return response;
    } catch (error: any) {
      showAppToast(error.response.data.message, 'error', 'light');
      dispatch(setLoading(false));
    }
  }
);

export const updatePromotionAction = createAsyncThunk<
  BaseResponseAPI<IPromotionAPI>,
  IUpdatePromotionBody
>(`${prefix}/updatePromotionAction`, async (body, { dispatch }) => {
  try {
    dispatch(setLoading(true));
    const response: any = await promotionAPI.updatePromotion(body);
    if (response.success) {
      showAppToast(response.message, 'success', 'light');
      dispatch(setLoading(false));
    }
    return response;
  } catch (error: any) {
    showAppToast(error.response.data.message, 'error', 'light');
    dispatch(setLoading(false));
  }
});

export const togglePromotionAction = createAsyncThunk<IPromotionAPI, ITogglePromotionBody>(
  `${prefix}/togglePromotionAction`,
  async (body) => {
    try {
      const response: any = await promotionAPI.togglePromotion(body);
      if (response.success) {
        showAppToast(response.message, 'success', 'light');
      }
      return response.data;
    } catch (error: any) {
      showAppToast(error.response.data.message, 'error', 'light');
    }
  }
);
