import { Fragment } from 'react';
import { CheckboxButton } from '../CheckboxButton';
import { CustomizationContainerStyled, ModalContainerStyled } from './styled';
import { AppModal } from 'src/components/AppModal';
import { IPatternAPI, IPersonalizationItem } from '@interfaces';
import styled from 'styled-components';
import { PatternItemmRadio } from 'src/components/inspiration/create-basic';
import { createInspirationFormSelector, useAppSelector } from '@redux';

interface IProps {
  openModal: boolean;
  onOpenModalClick: () => void;
  onModalCancel: () => void;
  patterns?: IPatternAPI[];
  personalizationItem: IPersonalizationItem;
  onSelectedPatternRadioChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleResetMotif: () => void;
}

export const MotifCustomization: React.FC<IProps> = ({
  openModal,
  onOpenModalClick,
  onModalCancel,
  patterns,
  personalizationItem,
  onSelectedPatternRadioChange,
  handleResetMotif,
}) => {
  const { patternId } = useAppSelector(createInspirationFormSelector);

  return (
    <Fragment>
      <CustomizationContainerStyled>
        <h5 className="customization-title">Motif</h5>
        <span className="customization-description">Select motif</span>
        <div className="motif-customization">
          <CheckboxButton
            onClick={handleResetMotif}
            checked={personalizationItem.patternId === patternId}
          >
            Same as main criteria
          </CheckboxButton>
          <button className="browse-btn" onClick={onOpenModalClick}>
            Browse motifs
          </button>
        </div>
      </CustomizationContainerStyled>
      <AppModal open={openModal} onClose={onModalCancel}>
        <ModalContainerStyled>
          {patterns &&
            patterns.map((pattern) => (
              <PatternItemmRadio
                key={pattern.id}
                name="pattern-customization"
                value={pattern.id}
                imageSrc={pattern.imageUrl}
                checked={personalizationItem.patternId === pattern.id}
                onChange={onSelectedPatternRadioChange}
              >
                {pattern.pattern}
              </PatternItemmRadio>
            ))}
        </ModalContainerStyled>
      </AppModal>
    </Fragment>
  );
};
