import { setLoading, useAppDispatch } from '@redux';
import { useLayoutEffect } from 'react';

export const fakeWaiting = async (ms: number): Promise<void> => {
  return await new Promise((resolve) => setTimeout(resolve, ms));
};

export const useFakeLoading = (ms: number): void => {
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch(setLoading(true));
    void fakeWaiting(ms).then(() => {
      dispatch(setLoading(false));
    });
  }, []);
};
