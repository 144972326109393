import { createAsyncThunk } from '@reduxjs/toolkit';

import { tailorAPI, uploadAPI } from '@api';
import {
  IGetAllTailorsParams,
  IGetListResponse,
  ISubcategoryAPI,
  ITailor,
  ITailorCreation,
  ITailorDetails,
  IToggleTailorStatus,
  IUpdateTailorApplicationStatusParams,
} from '@interfaces';
import { showAppToast } from '@utils';
import { setLoading, setTableLoading } from '../Slices';

const prefix = 'tailor';

export const getAllTailorsAction = createAsyncThunk<
  IGetListResponse<ITailor>,
  IGetAllTailorsParams
>(`${prefix}/getAllTailorsAction`, async (params) => {
  try {
    const response = await tailorAPI.getAllTailors(params);
    return response.data;
  } catch (error: any) {
    showAppToast(error.response.data.message, 'error', 'light');
  }
});

export const getTailorDetailsAction = createAsyncThunk<ITailorDetails, number>(
  `${prefix}/getTailorDetailsAction`,
  async (id, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response: any = await tailorAPI.getTailorDetails(id);
      return response.data;
    } catch (error: any) {
      showAppToast(error.response.data.message, 'error', 'light');
    } finally {
      dispatch(setLoading(false));
    }
  },
);

export const updateTailorApplicationStatusAction = createAsyncThunk<
  void,
  IUpdateTailorApplicationStatusParams
>(`${prefix}/updateTailorStatusAction`, async (params, { dispatch }) => {
  try {
    dispatch(setLoading(true));
    const response: any = await tailorAPI.updateTailorApplicationStatus(params);
    if (response.success) {
      showAppToast(response.data, 'success', 'light');
      dispatch(setLoading(false));
    }
  } catch (error: any) {
    showAppToast(error.response.data.message, 'error', 'light');
    dispatch(setTableLoading(false));
  }
});

export const toggleTailorStatus = createAsyncThunk<void, IToggleTailorStatus>(
  `${prefix}/toggleTailorStatusAction`,
  async (params) => {
    try {
      const { id, status } = params;
      const response: any = await tailorAPI.toggleTailorStatus({ id, status });
      if (response.success) {
        showAppToast('Changed status successfully!', 'success', 'light');
      }
    } catch (error: any) {
      showAppToast(error.response.data.message, 'error', 'light');
    }
  },
);

export const getSubcategoryByCategoryIdAction = createAsyncThunk<ISubcategoryAPI[], number>(
  `${prefix}/getSubcategoryByCategoryIdAction`,
  async (categoryId) => {
    try {
      const response = await tailorAPI.getSubcategoryByCategoryId(categoryId);
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  },
);
