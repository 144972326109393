import { PATH_PROMOTION, enumNavKey } from '@configs';
import { ICon } from '@interfaces';
import { selectLayout, useAppSelector } from '@redux';
import { useTheme } from '@theme';
import styled from 'styled-components';
import { OrderMenuIcon, ShirtMenuIcon, UserIcon, CashMenuIcon } from '../Icon';
import { Link } from 'react-router-dom';

interface IProps extends ICon {
  pathKey: enumNavKey;
  path: string;
  isSubMenu?: boolean;
}

export const NavMenuIcon = (props: IProps) => {
  const { pathKey, path, isSubMenu } = props;

  const { navCurrentKey } = useAppSelector(selectLayout);

  const { theme } = useTheme();

  const genIcon = () => {
    switch (pathKey) {
      case enumNavKey.TAILORS:
        return <ShirtMenuIcon size={24} color="currentColor" {...props} />;
      case enumNavKey.CUSTOMERS:
        return <UserIcon size={24} color="currentColor" {...props} />;
      case enumNavKey.PROMOTIONS:
        return <CashMenuIcon color="currentColor" {...props} />;
      case enumNavKey.INSPIRATIONS:
        return <OrderMenuIcon color="currentColor" size={24} {...props} />;
      default:
        return <></>;
    }
  };

  // // Remove later
  // if (path === PATH_PROMOTION) {
  //   return (
  //     <span className="cursor-auto menu__icon ant-menu-item-icon sidebar-menu-icon">
  //       {genIcon()}
  //     </span>
  //   );
  // }

  return (
    <Link to={path} className="menu__icon ant-menu-item-icon sidebar-menu-icon">
      {genIcon()}
    </Link>

    // <>{genIcon()}</>
  );
};

const StyledNavMenuIcon = styled.div<{
  $appTheme?: string;
}>``;
