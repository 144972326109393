import { InspirationInfoForm } from '@components';
import { PATH_INSPIRATION } from '@configs';
import { yupResolver } from '@hookform/resolvers/yup';
import { IInspirationDetails } from '@interfaces';
import { inspirationDetailsSelector, setLoading, useAppDispatch, useAppSelector } from '@redux';
import { InspirationSchema } from '@validations';
import { useEffect, useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { updateInspirationAction } from 'src/redux/actions/inspiration';

interface IProps {
  isViewPage: boolean;
}

export const InspirationInfoModule: React.FC<IProps> = ({ isViewPage }) => {
  const dispatch = useAppDispatch();
  const inspirationDetails = useAppSelector(inspirationDetailsSelector);
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(InspirationSchema),
    mode: 'onChange',
  });

  const handleSave = handleSubmit(async (values, event) => {
    event?.preventDefault();
    if (!isValid || !id) return;
    setValue('title', values.title.trim(), { shouldValidate: true });
    setValue('description', values.description.trim(), { shouldValidate: true });
    try {
      dispatch(setLoading(true));
      await dispatch(
        updateInspirationAction({
          id: +id,
          title: values.title,
          indicativePrice: +values.price,
          description: values.description,
        })
      );
      dispatch(setLoading(false));
      navigate(PATH_INSPIRATION);
    } catch (err: any) {
      dispatch(setLoading(false));
    }
  });

  useLayoutEffect(() => {
    reset({
      title: inspirationDetails.title,
      price: inspirationDetails.price,
      description: inspirationDetails.description,
    });
  }, [JSON.stringify(inspirationDetails)]);

  return (
    <InspirationInfoForm
      isViewPage={isViewPage}
      register={register}
      errors={errors}
      handleSave={handleSave}
    />
  );
};
