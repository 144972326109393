import { Divider, Radio, RadioChangeEvent, Space } from 'antd';
import {
  ContainerStyled,
  RadioContainer,
  SectionCreateBasicStyled,
  SelectContainerStyled,
} from './styled';
import { SharedIcon } from 'src/components/Icon';
import styled from 'styled-components';
import { PropertyCheckbox } from './widgets';
import { IFabricAPI, IPropertyAPI } from '@interfaces';
import { property } from 'lodash';

interface IProps {
  fabrics?: IFabricAPI[];
  selectedFabric?: number;
  groupedProperties?: {
    [x: string]: _.Omit<IPropertyAPI, 'family'>[];
  };
  listSelectedProperties: Omit<IPropertyAPI, 'family'>[];
  onSelectedFabricChange: (e: RadioChangeEvent) => void;
  onSelectedPropertyChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    currentProperty: Omit<IPropertyAPI, 'family'>
  ) => void;
}

export const InspirationMaterialPrincipleSection: React.FC<IProps> = ({
  fabrics,
  selectedFabric,
  groupedProperties,
  listSelectedProperties,
  onSelectedFabricChange,
  onSelectedPropertyChange,
}) => {
  return (
    <SectionCreateBasicStyled>
      <ContainerStyled>
        <SelectContainerStyled>
          <h2>Material</h2>
          {fabrics && (
            <RadioContainer className="h-[80vh] overflow-scroll overflow-x-hidden">
              <Radio.Group
                buttonStyle="solid"
                size="large"
                value={selectedFabric}
                onChange={onSelectedFabricChange}
              >
                <Space direction="vertical">
                  {fabrics.map((fabric) => (
                    <Radio.Button key={fabric.id} value={fabric.id} style={radioBtnStyles}>
                      <span>{fabric.fabric}</span>
                      <SharedIcon name="rightOutlined" size={18} />
                    </Radio.Button>
                  ))}
                </Space>
              </Radio.Group>
            </RadioContainer>
          )}
        </SelectContainerStyled>
        {groupedProperties && (
          <SelectContainerStyled>
            <h2>Properties</h2>
            <RadioContainer>
              <Space direction="vertical" split={<Divider />}>
                {Object.entries(groupedProperties).map(([family, properties]) => (
                  <PropertyWrapperStyled key={family}>
                    <h3>{family}</h3>
                    <CheckboxGroupStyled>
                      {properties.map((item) => (
                        <PropertyCheckbox
                          key={item.id}
                          value={item.id}
                          name="property"
                          onChange={(e) => onSelectedPropertyChange(e, item)}
                          checked={listSelectedProperties.some(
                            (property) => property.id === item.id
                          )}
                          disabled={listSelectedProperties.some(
                            (property) =>
                              property.id !== item.id &&
                              !property.propertyCompatibility.includes(item.id)
                          )}
                        >
                          {item.property}
                        </PropertyCheckbox>
                      ))}
                    </CheckboxGroupStyled>
                  </PropertyWrapperStyled>
                ))}
              </Space>
            </RadioContainer>
          </SelectContainerStyled>
        )}
      </ContainerStyled>
    </SectionCreateBasicStyled>
  );
};

const radioBtnStyles: React.CSSProperties = {
  minWidth: '28rem',
  borderRadius: '1.6rem',
  textTransform: 'capitalize',
};

const PropertyWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  & > h3 {
    font-family: 'Gotham', sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
    text-transform: capitalize;
  }
`;

const CheckboxGroupStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  flex-wrap: wrap;
`;
