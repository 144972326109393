import { ChangePasswordForm } from '@components';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { selectApp, selectAuth, setLoading, useAppDispatch, useAppSelector } from '@redux';
import { useEffect } from 'react';
import { ChangePasswordSchema } from '@validations';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '@firebase-config';
import { adminChangePasswordAction } from 'src/redux/actions';
import { showAppToast } from '@utils';

export const ChangePasswordModule: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange', resolver: yupResolver(ChangePasswordSchema) });

  const dispatch = useAppDispatch();

  const { showChangePasswordModal } = useAppSelector(selectApp);
  const { accountInfo } = useAppSelector(selectAuth);

  useEffect(() => {
    reset({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
  }, [showChangePasswordModal]);

  const handleChangePassword = handleSubmit(async (data, event) => {
    event?.preventDefault();
    if (!isValid || !accountInfo) return;
    try {
      dispatch(setLoading(true));
      await signInWithEmailAndPassword(auth, accountInfo.email, data.oldPassword).then(
        (userCredential: any) => {
          const { user } = userCredential;
          const { accessToken } = user;
          void dispatch(
            adminChangePasswordAction({
              idToken: accessToken,
              password: data.newPassword,
              confirmPassword: data.confirmPassword,
            })
          ).then(() => {
            reset({
              oldPassword: '',
              newPassword: '',
              confirmPassword: '',
            });
            dispatch(setLoading(false));
          });
        }
      );
    } catch (err: any) {
      setError('oldPassword', { type: 'custom', message: 'Old password is incorrect' });
      dispatch(setLoading(false));
    }
  });

  return (
    <ChangePasswordForm
      register={register}
      errors={errors}
      handleChangePassword={handleChangePassword}
    />
  );
};
