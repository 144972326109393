import { fabricAPI } from '@api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IFabricAPI } from 'src/interfaces/fabric';

const prefix = '/fabric';

export const getUserFabricsAction = createAsyncThunk<IFabricAPI[], void>(
  `${prefix}/getUserFabricsAction`,
  async () => {
    try {
      const response = await fabricAPI.getUserFabrics();
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
);
