import { AppModal, SharedIcon, StyledSection } from '@components';
import { IPersonalizationItem } from '@interfaces';
import { InspirationPersonalizationItemModule, SelectElementModule } from '@modules';
import { Button } from 'antd';
import { Fragment } from 'react';
import styled from 'styled-components';
import { useAutoAnimate } from '@formkit/auto-animate/react';

interface IProps {
  personalizationItems: IPersonalizationItem[];
  handleReturn: () => void;
  openElementModal: boolean;
  handleOpenElementModal: () => void;
  handleCloseElementModal: () => void;
  handleCreateInspiration: () => Promise<void>;
  loading: boolean;
}

export const InspirationCreatePersonalizationSection: React.FC<IProps> = ({
  personalizationItems,
  openElementModal,
  handleOpenElementModal,
  handleCloseElementModal,
  handleReturn,
  handleCreateInspiration,
  loading,
}) => {
  const [containerRef] = useAutoAnimate();

  return (
    <Fragment>
      <StyledSection>
        <ContainerStyled>
          <PersonalizationWrapper>
            <h3>Personalization</h3>
            <PersonalizationCustomizeContainer ref={containerRef}>
              {personalizationItems.map((item) => (
                <InspirationPersonalizationItemModule key={item.elementId} item={item} />
              ))}
              <Button
                size="large"
                shape="round"
                icon={<SharedIcon name="plus" size={16} />}
                className="add-btn"
                onClick={handleOpenElementModal}
              >
                Add Personalization
              </Button>
            </PersonalizationCustomizeContainer>
          </PersonalizationWrapper>
          <ActionsStyled>
            <Button size="large" shape="round" onClick={handleReturn}>
              Return
            </Button>
            <Button
              type="primary"
              size="large"
              shape="round"
              onClick={handleCreateInspiration}
              loading={loading}
            >
              Save
            </Button>
          </ActionsStyled>
        </ContainerStyled>
      </StyledSection>
      <SelectElementModule open={openElementModal} onClose={handleCloseElementModal} />
    </Fragment>
  );
};

const ContainerStyled = styled.div`
  min-height: 58vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4.8rem;
`;

const PersonalizationCustomizeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  align-items: flex-start;
  .add-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: 5.2rem;
  }
`;

const PersonalizationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  h3 {
    font-family: 'Gotham', sans-serif;
    font-size: 2.4rem;
  }
`;

const ActionsStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.2rem;
`;
