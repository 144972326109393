import { PATH_REGISTRATION_TAILOR, enumGender, enumTailorRegistrationStatus } from '@configs';
import { TailorRegistrationDetailsSection } from '@pages';
import {
  getTailorDetailsAction,
  selectTailor,
  updateTailorApplicationStatusAction,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';

export const TailorRegistrationDetailsModule: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { tailorDetails } = useAppSelector(selectTailor);

  const [validationStatus, setValidationStatus] = useState<
    enumTailorRegistrationStatus | undefined
  >(tailorDetails?.validationStatus);

  const tailorMaterialsWorked =
    Object.keys(_.groupBy(tailorDetails?.tailorFabrics, 'family'))
      .map((material) => material?.charAt(0)?.toUpperCase() + material.slice(1))
      .join(', ') || undefined;

  const handleCancel = (): void => {
    navigate(PATH_REGISTRATION_TAILOR);
  };

  const tailorSocialMedia = _.groupBy(tailorDetails?.tailorSocialMedia, 'platform');

  const tailorSkills = _.mapValues(
    _.groupBy(tailorDetails?.subCategories, 'category.gender.gender'),
    (subCategories) =>
      subCategories.map((subCategory) => _.omit(subCategory, ['category', 'translation'])),
  );

  const handleRenderTailorSkill = (gender: enumGender): string | undefined => {
    return tailorSkills[gender]
      ?.map((skill) => skill.subcategory.charAt(0).toUpperCase() + skill.subcategory.slice(1))
      .join(', ');
  };

  const onStatusChange = (value: string): void => {
    setValidationStatus(value as enumTailorRegistrationStatus);
  };

  const handleSave = (): void => {
    if (id === undefined || validationStatus === undefined) return;
    void dispatch(updateTailorApplicationStatusAction({ id: +id, validationStatus })).then(() => {
      navigate(PATH_REGISTRATION_TAILOR);
    });
  };

  useEffect(() => {
    if (id === undefined) {
      navigate(PATH_REGISTRATION_TAILOR);
      return;
    }
    const promise = dispatch(getTailorDetailsAction(+id));
    return () => {
      promise.abort();
    };
  }, []);

  useEffect(() => {
    setValidationStatus(tailorDetails?.validationStatus);
  }, [tailorDetails?.validationStatus]);

  return (
    <TailorRegistrationDetailsSection
      tailorMaterialsWorked={tailorMaterialsWorked}
      tailorDetails={tailorDetails}
      handleCancel={handleCancel}
      tailorSocialMedia={tailorSocialMedia}
      handleRenderTailorSkill={handleRenderTailorSkill}
      onStatusChange={onStatusChange}
      status={validationStatus}
      handleSave={handleSave}
    />
  );
};
