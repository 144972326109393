import React from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components';

import { IClothesElement } from '@interfaces';
import { APP_COLORS } from '@theme';
import { AppImage } from 'src/components/shared';
import { ColorBox, MotifBox } from 'src/components/styles';
import { formatPersonalElementName } from '@utils';

interface IProps {
  personlizationItem: IClothesElement;
}

export const PersonalizationItemInfo: React.FC<IProps> = ({ personlizationItem }) => {
  const { colorRange, color, pattern, userFabric, clothesImage, properties, element } =
    personlizationItem;
  const groupedProperties = _.mapValues(_.groupBy(properties, 'family'), (properties) =>
    properties.map((property) => property.property),
  );

  return (
    <CardStyled>
      <CardTitleStyled>
        <h4>{formatPersonalElementName(element.elementFamily, element.element)}</h4>
      </CardTitleStyled>
      <CardBodyStyled>
        <PersonalizationInfoList>
          <li>
            <span className="list-item-label">Color:</span>
            <span className="list-item-content">
              <span>{color.colorRange}</span>
              <ColorBox $color={colorRange} />
            </span>
          </li>
          <li>
            <span className="list-item-label">Motif:</span>
            <span className="list-item-content">
              <span>{pattern.pattern}</span>
              <MotifBox $imageSrc={pattern.imageUrl} />
            </span>
          </li>
          <li>
            <span className="list-item-label">Material:</span>
            <span className="list-item-content">{userFabric.fabric}</span>
          </li>
          <li>
            <span className="list-item-label">Properties:</span>
            <ul className="list-properties">
              {Object.entries(groupedProperties).map(([family, properties]) => (
                <li key={family} className="list-property-item">
                  <span className="property-family">{family}:</span>
                  <span className="properties-text">{properties.join(', ')}</span>
                </li>
              ))}
            </ul>
          </li>
        </PersonalizationInfoList>
        <AppImage
          firebaseResourceKey={clothesImage[0].imageUrl}
          width={350}
          height={250}
          containerClassName="rounded-3xl overflow-hidden shadow-md"
          className="object-cover w-full h-full"
        />
      </CardBodyStyled>
    </CardStyled>
  );
};

const CardStyled = styled.div`
  border: 0.1rem solid ${APP_COLORS.normal};
  border-radius: 2rem;
  max-width: 90rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const CardTitleStyled = styled.div`
  padding: 1.2rem 1.8rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.1rem solid ${APP_COLORS.normal};
  & > h4 {
    font-family: 'Gotham', sans-serif;
    font-size: 1.8rem;
    text-transform: capitalize;
  }
`;

const CardBodyStyled = styled.div`
  padding: 1.2rem 1.8rem;
  width: 100%;
  display: flex;
  gap: 6.4rem;
  justify-content: space-between;
`;

const PersonalizationInfoList = styled.ul`
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  & > li {
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    & > .list-item-label {
      color: ${APP_COLORS.gray500};
    }
    & > .list-item-content {
      text-transform: capitalize;
      display: flex;
      align-items: center;
      gap: 0.8rem;
    }
    & > .list-properties {
      margin: 0;
      & > .list-property-item {
        display: flex;
        align-items: flex-start;
        gap: 0.6rem;
        & > .property-family {
          text-transform: capitalize;
        }
        & > .properties-text {
          text-transform: capitalize;
        }
      }
    }
  }
`;
