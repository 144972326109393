import { uploadAPI } from '@api';
import {
  IGetFirebaseResourceBody,
  IGetFirebaseResourceResponse,
  IUploadAPI,
  IUploadToFirebaseBody,
  IUploadToFirebaseResponse,
} from '@interfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';

const prefix = '/upload';

export const uploadToAmazonAction = createAsyncThunk<IUploadAPI, FormData>(
  `${prefix}/uploadToAmazonAction`,
  async (formData) => {
    try {
      const response = await uploadAPI.uploadToAmazon(formData);
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  },
);

export const uploadToFirebaseAction = createAsyncThunk<
  IUploadToFirebaseResponse,
  IUploadToFirebaseBody
>(`${prefix}/uploadToFirebaseAction`, async (body) => {
  try {
    const response: any = await uploadAPI.uploadToFirebase(body);
    if (!response.success) {
      throw response.data.message || 'Something went wrong';
    }
    return response;
  } catch (error: any) {
    throw new Error(error.response.data.message || 'Something went wrong');
  }
});

export const getFirebaseResourceAction = createAsyncThunk<
  IGetFirebaseResourceResponse,
  IGetFirebaseResourceBody
>(`${prefix}/getFirebaseResourceAction`, async (body) => {
  try {
    const response: any = await uploadAPI.getFirebaseResource(body);
    if (!response.success) {
      throw response.data.message || 'Something went wrong';
    }
    return response;
  } catch (error: any) {
    throw new Error(error.response.data.message || 'Something went wrong');
  }
});
