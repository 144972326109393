import { useNavigate } from 'react-router-dom';
import { MenuProps } from 'antd';
import { useRef } from 'react';

import { MenuDropdown } from '@components';
import {
  setNavCurrentIsOpenKey,
  setNavCurrentKey,
  setShowChangePasswordModal,
  setShowHeaderMenu,
  useAppDispatch,
} from '@redux';
import { enumHeaderMenuItemKey, RESET } from '@configs';
import { ChangePasswordModal } from 'src/pages/app/header';
import { signOut } from 'firebase/auth';
import { auth } from '@firebase-config';
import { LogApp } from '@utils';
import { adminLogoutAction } from 'src/redux/actions';
import { toast } from 'react-toastify';

interface IProps {
  any?: any;
}

export const MenuDropdownModule = (props: IProps) => {
  const dispatch = useAppDispatch();

  const handleOpenChangePasswordModal = (): void => {
    dispatch(setShowChangePasswordModal(true));
  };

  const handleCloseDropdown = () => {
    dispatch(setShowHeaderMenu(false));
  };

  const menuDropdown = useRef<HTMLDivElement>(null);

  const handleSelectMenuItem: MenuProps['onClick'] = (e) => {
    const currentKey = Number(e.key);
    switch (currentKey) {
      case enumHeaderMenuItemKey.CHANGE_PASSWORD:
        handleOpenChangePasswordModal();
        break;
      case enumHeaderMenuItemKey.LOG_OUT:
        handleLogout();
        break;
      default:
        return;
    }
    dispatch(setNavCurrentKey(undefined));
    dispatch(setNavCurrentIsOpenKey(undefined));
  };

  const handleLogout = async (): Promise<void> => {
    try {
      await dispatch(adminLogoutAction());
      dispatch({ type: RESET });
      await signOut(auth);
    } catch (error: any) {
      LogApp('Firebase Logout Error', error);
    }
  };

  return (
    <MenuDropdown
      ref={menuDropdown}
      handleCloseDropdown={handleCloseDropdown}
      handleLogout={handleLogout}
      onSelectMenuItem={handleSelectMenuItem}
    />
  );
};
