import { propertyAPI } from '@api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPropertyAPI } from 'src/interfaces/property';

const prefix = '/property';

export const getPropertiesAction = createAsyncThunk<IPropertyAPI[], void>(
  `${prefix}/getPropertiesAction`,
  async () => {
    try {
      const response = await propertyAPI.getProperties();
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
);
