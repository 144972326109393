import { combineReducers } from 'redux';
import { AnyAction, Reducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import toastReducer from './toast';
import appReducer from './app';
import authReducer from './auth';
import layoutReducer from './layout';
import tailorReducer from './tailor';
import customerReducer from './customer';
import inspirationReducer from './inspiration';
import promotionReducer from './promotion';
import { inspirationPersistConfig } from '../persistConfigs';

export * from './toast';
export * from './app';
export * from './auth';
export * from './layout';
export * from './tailor';
export * from './customer';
export * from './inspiration';
export * from './promotion';

const productReducer = combineReducers({
  toast: toastReducer,
  app: appReducer,
  auth: authReducer,
  layout: layoutReducer,
  tailor: tailorReducer,
  customer: customerReducer,
  inspiration: persistReducer(inspirationPersistConfig, inspirationReducer),
  promotion: promotionReducer,
});

export type RootState = ReturnType<typeof productReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'RESET') {
    // reset state
    state = {} as RootState;
    // reset local storage
    localStorage.clear();
  }
  return productReducer(state, action);
};
export default rootReducer;
