import { APP_COLORS } from '@theme';
import styled from 'styled-components';

export const CustomizationContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  border: 0.1rem solid ${APP_COLORS.normal};
  border-radius: 0.8rem;
  padding: 1.6rem 2rem;
  & > .customization-title {
    font-size: 1.6rem;
    font-family: 'Gotham', sans-serif;
  }
  & > .customization-description {
    font-size: 1.4rem;
    color: ${APP_COLORS.gray500};
  }
  .color-customization {
    display: grid;
    grid-template-columns: repeat(5, min-content);
    column-gap: 1.2rem;
    row-gap: 1.2rem;
  }
  .motif-customization,
  .material-customization,
  .property-customization {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    align-items: flex-start;
    & > .browse-btn {
      width: 100%;
      font-size: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.2rem;
      border: 0.1rem solid ${APP_COLORS.primary};
      color: ${APP_COLORS.primary};
      border-radius: 100rem;
      transition: all 0.2s;
      &:active {
        transform: scale(1.01);
      }
    }
  }
`;

export const ModalContainerStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.6rem;
  justify-items: center;
  padding: 1.6rem 2.4rem;
  min-width: 60vw;
`;
