import { APP_COLORS } from '@theme';
import React from 'react';
import { SharedIcon } from 'src/components/Icon';
import { AppImage } from 'src/components/shared';
import styled from 'styled-components';

interface PatternItemRadioProps extends React.ComponentPropsWithRef<'input'> {
  imageSrc?: string;
  children?: React.ReactNode;
}

export const PatternItemmRadio = React.forwardRef<HTMLInputElement, PatternItemRadioProps>(
  ({ children, imageSrc, ...rest }, ref) => {
    return (
      <RadioStyled>
        <input type="radio" ref={ref} {...rest} />
        <div className="pattern-wrapper">
          <AppImage className="pattern-item-img" size={96} src={imageSrc} />
          <span>{children}</span>
        </div>
      </RadioStyled>
    );
  }
);

PatternItemmRadio.displayName = 'PatternItemRadio';

const RadioStyled = styled.label`
  display: inline-block;
  cursor: pointer;
  input[type='radio'] {
    display: none;
    &:checked + .pattern-wrapper {
      border-color: ${APP_COLORS.primary};
      background-color: ${APP_COLORS.background};
    }
  }
  &:hover > .pattern-wrapper {
    border-color: ${APP_COLORS.primary};
    transform: translateY(-0.6rem);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px, rgba(0, 0, 0, 0.05) 0px 2px 3px;
    background-color: ${APP_COLORS.background};
  }
  .pattern-wrapper {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 12px, rgba(0, 0, 0, 0.05) 0px 3px 5px;
    padding: 2rem 2.4rem;
    border-radius: 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 1.2rem;
    font-size: 1.6rem;
    text-align: center;
    text-transform: capitalize;
    border: 0.2rem solid ${APP_COLORS.normal};
    transition: all 0.3s;
    width: 18rem;
    height: 100%;
    .pattern-item-img {
      border: 0.1rem solid ${APP_COLORS.normal};
      border-radius: 1.2rem;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`;
