import { APP_COLORS } from '@theme';
import styled, { css } from 'styled-components';

export const CenterBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ColorBox = styled.span<{ $color?: string | null; $linearGradient?: string | null }>`
  width: 2.4rem;
  height: 2.4rem;
  border: 0.05rem solid transparent;
  border-radius: 0.6rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  ${(props) =>
    props.$color &&
    css`
      background-color: ${props.$color};
      background-image: ${props.$linearGradient ? props.$linearGradient : props.$color};
      border: 0.05rem solid ${APP_COLORS.normal};
    `};
  ${(props) =>
    !props.$color &&
    css`
      display: none;
    `};
`;

export const MotifBox = styled.span<{ $imageSrc?: string | null }>`
  width: 2.4rem;
  height: 2.4rem;
  border: 0.1rem solid transparent;
  border-radius: 0.6rem;
  ${(props) =>
    props.$imageSrc &&
    css`
      background-image: url(${props.$imageSrc});
      background-position: center;
      background-size: cover;
      border: 0.05rem solid ${APP_COLORS.normal};
    `};
  ${(props) =>
    !props.$imageSrc &&
    css`
      display: none;
    `};
`;
