import { InspirationColorPrincipleSection } from '@components';
import {
  createInspirationFormSelector,
  getColorRangeAction,
  getTintsByColorAction,
  inspirationSelect,
  setSelectedColor,
  setSelectedTint,
  setTints,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { RadioChangeEvent } from 'antd';
import { useEffect } from 'react';

export const InspirationColorPrincipleModule: React.FC = () => {
  const dispatch = useAppDispatch();

  const { listColors, listTints } = useAppSelector(inspirationSelect);
  const { rangeHexCode, tintHexCode } = useAppSelector(createInspirationFormSelector);

  const onSelectedColorChange = async (e: RadioChangeEvent): Promise<void> => {
    dispatch(setTints([]));
    dispatch(setSelectedTint(undefined));
    dispatch(setSelectedColor(e.target.value));
    await dispatch(getTintsByColorAction(e.target.value));
  };

  const onSelectedTintChange = (e: RadioChangeEvent): void => {
    dispatch(setSelectedTint(e.target.value));
  };

  useEffect(() => {
    const promise = dispatch(getColorRangeAction());

    return () => {
      promise.abort();
    };
  }, []);

  return (
    <InspirationColorPrincipleSection
      colors={listColors}
      onSelectedColorChange={onSelectedColorChange}
      tints={listTints}
      onSelectedTintChange={onSelectedTintChange}
      selectedColor={rangeHexCode}
      selectedTint={tintHexCode}
    />
  );
};
