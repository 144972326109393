import { StyledPage } from '@components';
import { BreadcrumbsModule, TailorRegistrationModule } from '@modules';

export const TailorRegistrationPage: React.FC = () => {
  return (
    <StyledPage>
      <BreadcrumbsModule />
      <TailorRegistrationModule />
    </StyledPage>
  );
};
