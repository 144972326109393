import { StyledPage } from '@components';
import { BreadcrumbsModule, InspirationManagementModule } from '@modules';

export const InspirationManagementPage: React.FC = () => {
  return (
    <StyledPage>
      <BreadcrumbsModule />
      <InspirationManagementModule />
    </StyledPage>
  );
};
