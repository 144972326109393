import { APP_COLORS } from '@theme';
import React from 'react';
import { SharedIcon } from 'src/components/Icon';
import styled from 'styled-components';

interface CheckboxButtonProps extends React.ComponentPropsWithRef<'input'> {
  children?: React.ReactNode;
}

export const CheckboxButton = React.forwardRef<HTMLInputElement, CheckboxButtonProps>(
  ({ children, ...rest }, ref) => {
    return (
      <CheckboxButtonStyled>
        <input type="checkbox" ref={ref} {...rest} />
        <div className="checkbox-wrapper">
          <span className="checkbox-content">{children}</span>
          <span className="check-icon">
            <SharedIcon name="check" size={18} />
          </span>
        </div>
      </CheckboxButtonStyled>
    );
  }
);

CheckboxButton.displayName = 'CheckboxButton';

const CheckboxButtonStyled = styled.label`
  display: inline-block;
  cursor: pointer;
  input[type='checkbox'] {
    display: none;
    &:checked + .checkbox-wrapper > .check-icon {
      display: inline-block;
    }
  }
  .checkbox-wrapper {
    border: 0.1rem solid ${APP_COLORS.normal};
    padding: 0.8rem 1.6rem;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    border-radius: 100rem;
    & > .check-icon {
      display: none;
    }
  }
`;
