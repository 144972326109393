import styled from 'styled-components';
import { SectionCreateBasicStyled } from './styled';
import { UploadImageCard } from '@components';
import { ALLOWED_BUCKET_NAMES_OBJECT, enumImagePosition } from '@configs';
import { IUploadInspirationPicture } from '@interfaces';

interface IProps {
  inspirationPictures?: IUploadInspirationPicture[];
  onUploadImageEnd: (position: enumImagePosition, data?: any) => void;
  onRemoveImage: (position: enumImagePosition) => void;
}

export const InspirationUploadPhotoSection: React.FC<IProps> = (props) => {
  const { inspirationPictures, onRemoveImage, onUploadImageEnd } = props;

  return (
    <SectionCreateBasicStyled>
      <ContainerStyled>
        <UploadImageCard
          bucketName={ALLOWED_BUCKET_NAMES_OBJECT.ADMIN_INSPIRATION}
          height={400}
          width={275}
          textUpload="Front view"
          onUploadEnd={(data) => onUploadImageEnd(enumImagePosition.FRONT, data)}
          onRemoveImage={() => onRemoveImage(enumImagePosition.FRONT)}
          previewUrl={
            inspirationPictures?.find((p) => p.imagePosition === enumImagePosition.FRONT)?.url
          }
          key="front"
        />
        <UploadImageCard
          bucketName={ALLOWED_BUCKET_NAMES_OBJECT.ADMIN_INSPIRATION}
          height={400}
          width={275}
          textUpload="Profile view"
          onUploadEnd={(data) => onUploadImageEnd(enumImagePosition.PROFILE, data)}
          onRemoveImage={() => onRemoveImage(enumImagePosition.PROFILE)}
          previewUrl={
            inspirationPictures?.find((p) => p.imagePosition === enumImagePosition.PROFILE)?.url
          }
          key="profile"
        />
        <UploadImageCard
          bucketName={ALLOWED_BUCKET_NAMES_OBJECT.ADMIN_INSPIRATION}
          height={400}
          width={275}
          textUpload="Back view"
          onUploadEnd={(data) => onUploadImageEnd(enumImagePosition.BACK, data)}
          onRemoveImage={() => onRemoveImage(enumImagePosition.BACK)}
          previewUrl={
            inspirationPictures?.find((p) => p.imagePosition === enumImagePosition.BACK)?.url
          }
          key="back"
        />
      </ContainerStyled>
    </SectionCreateBasicStyled>
  );
};

const ContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 2.4rem;
  flex-wrap: wrap;
`;
