import { SelectElementModal } from '@components';
import { IElementAPI } from '@interfaces';
import {
  addPersonalizationItem,
  createInspirationFormSelector,
  getElementsAction,
  inspirationSelect,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { PATH_CREATE_INSPIRATION_BASIC } from '@configs';
import { showAppToast } from '@utils';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const SelectElementModule: React.FC<IProps> = ({ open, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { listElements } = useAppSelector(inspirationSelect);
  const {
    genderId,
    categoryId,
    subcategoryId,
    fabricId,
    patternId,
    rangeHexCode,
    tintHexCode,
    properties,
  } = useAppSelector(createInspirationFormSelector);

  const groupedElements = _.mapValues(
    _.groupBy(
      listElements?.filter((element) => element.element && element.elementFamily),
      'elementFamily'
    ),
    (elements) => elements.map((element) => _.omit(element, ['elementFamily']))
  );

  const handleAddPersonalizationItem = (elementId: number): void => {
    if (
      fabricId === undefined ||
      patternId === undefined ||
      rangeHexCode === undefined ||
      tintHexCode === undefined
    )
      return;
    dispatch(
      addPersonalizationItem({
        selectedImage: [],
        fabricId,
        patternId,
        rangeHexCode,
        tintHexCode,
        elementId,
        properties,
      })
    );
    onClose();
  };

  useEffect(() => {
    if (genderId === undefined || categoryId === undefined || subcategoryId === undefined) {
      navigate(PATH_CREATE_INSPIRATION_BASIC);
      showAppToast('Something went wrong', 'error', 'light');
      return;
    }
    const promise = dispatch(
      getElementsAction({ genderId, categoryId, subCategoryId: subcategoryId })
    );

    return () => {
      promise.abort();
    };
  }, []);

  return (
    <SelectElementModal
      open={open}
      onClose={onClose}
      groupedElements={groupedElements}
      handleAddPersonalizationItem={handleAddPersonalizationItem}
    />
  );
};
