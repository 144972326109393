import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { LoginForm } from '@components';
import { setLoading, useAppDispatch } from '@redux';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '@firebase-config';
import { adminLoginAction } from 'src/redux/actions/auth';
import { LoginSchema } from '@validations';

export const LoginModule = () => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(LoginSchema),
    mode: 'onChange',
  });

  const handleLogin = handleSubmit(async (values, event) => {
    event?.preventDefault();
    if (!isValid) return;
    setValue('email', values.email.trim(), { shouldValidate: true });
    try {
      dispatch(setLoading(true));
      await signInWithEmailAndPassword(auth, values.email, values.password).then(
        (userCredential: any) => {
          const { user } = userCredential;
          const { accessToken } = user;
          void dispatch(adminLoginAction({ token: accessToken })).then(() => {
            reset({ email: '', password: '' });
            dispatch(setLoading(false));
          });
        }
      );
    } catch (err: any) {
      setError('password', { type: 'custom', message: 'The email or password is incorrect' });
      dispatch(setLoading(false));
    }
  });

  return <LoginForm register={register} errors={errors} handleLogin={handleLogin} />;
};
