import axiosClient from './axiosClient';

export const colorAPI = {
  getColorRange: () => {
    const url = '/api/color/color-range';
    return axiosClient.get(url);
  },
  getTintsByColor: (colorCode: string) => {
    const url = '/api/color/tint';
    return axiosClient.get(url, {
      params: {
        colorCode,
      },
    });
  },
};
