import { APP_COLORS } from '@theme';
import React from 'react';
import { SharedIcon } from 'src/components/Icon';
import styled, { css } from 'styled-components';

interface ColorButtonProps extends React.ComponentPropsWithRef<'button'> {
  backgroundColor?: string;
  checked?: boolean;
}

export const ColorButton = React.forwardRef<HTMLButtonElement, ColorButtonProps>(
  ({ backgroundColor = '#000000', checked = false, ...rest }, ref) => {
    return (
      <ButtonWrapper $backgroundColor={backgroundColor} $checked={checked} ref={ref} {...rest}>
        <span className="check-icon">
          <SharedIcon name="check" />
        </span>
      </ButtonWrapper>
    );
  }
);

ColorButton.displayName = 'ColorButton';

const ButtonWrapper = styled.button<{ $backgroundColor: string; $checked: boolean }>`
  display: inline-block;
  cursor: pointer;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 0.4rem;
  border: 0.1rem solid ${APP_COLORS.normal};
  background-color: ${(p) => p.$backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  & > .check-icon {
    color: ${APP_COLORS.cream};
    opacity: 1;
    visibility: visible;
    ${(props) =>
      !props.$checked &&
      css`
        opacity: 0;
        visibility: hidden;
      `};
    transition: all 0.2s;
  }
`;
