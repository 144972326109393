import { ICategoryAPI, IGetListResponse, ITailor, ITailorDetails } from '@interfaces';
import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { getAllTailorsAction, getTailorDetailsAction } from '../actions';
import { IMAGES } from '@assets';
import { formatUserFullname } from '@utils';

interface ITailorState {
  tailors?: ITailor[];
  totalTailors: number;
  limitPerPage: number;
  tailorDetails?: ITailorDetails;
  listCategory?: ICategoryAPI[];
}

const initialState: ITailorState = {
  tailors: undefined,
  totalTailors: 0,
  limitPerPage: 10,
  tailorDetails: undefined,
  listCategory: undefined,
};

const tailorSlice = createSlice({
  name: 'tailor',
  initialState,
  reducers: {
    resetTailor: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllTailorsAction.fulfilled,
      (state, action: PayloadAction<IGetListResponse<ITailor>>) => {
        state.tailors = action.payload.items;
        state.totalTailors = action.payload.total;
        state.limitPerPage = action.payload.limit;
      },
    );
    builder.addCase(
      getTailorDetailsAction.fulfilled,
      (state, action: PayloadAction<ITailorDetails>) => {
        state.tailorDetails = action.payload;
      },
    );
  },
});

export const selectTailor = createSelector(
  (state: RootState) => state.tailor,
  (state) => state,
);

export const tailorsTableSelector = createSelector(
  (state: RootState) => state.tailor,
  (state) =>
    state.tailors
      ?.map((tailor) => ({
        key: tailor.id,
        name: formatUserFullname(tailor?.firstName, tailor?.lastName),
        email: tailor?.email,
        address: tailor?.tailorAddress?.addressLine1 ?? '',
        profile: tailor?.imageUrl,
        status: tailor?.status,
        createdAt: tailor?.createdAt,
      }))
      .sort((a, b) => Date.parse(b?.createdAt) - Date.parse(a?.createdAt)),
);

export const tailorsRegistrationTableSelector = createSelector(
  (state: RootState) => state.tailor,
  (state) =>
    state.tailors
      ?.map((tailor) => ({
        key: tailor.id,
        email: tailor.email,
        status: tailor.validationStatus,
        createdAt: tailor.createdAt,
      }))
      .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)),
);

export const { resetTailor } = tailorSlice.actions;
export default tailorSlice.reducer;
