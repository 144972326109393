import { InspirationPatternPrincipleSection } from '@components';
import { IPatternAPI } from '@interfaces';
import { inspirationSelect, setSelectedPattern, useAppDispatch, useAppSelector } from '@redux';
import { useEffect, useState } from 'react';
import { getPatternsAction } from 'src/redux/actions/pattern';

export const InspirationPatternPrincipleModule: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    listPatterns,
    createInspirationForm: { patternId },
  } = useAppSelector(inspirationSelect);

  const onSelectedPatternChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setSelectedPattern(+e.target.value));
  };

  useEffect(() => {
    const promise = dispatch(getPatternsAction());

    return () => {
      promise.abort();
    };
  }, []);

  return (
    <InspirationPatternPrincipleSection
      patterns={listPatterns}
      selectedPattern={patternId}
      onSelectedPatternChange={onSelectedPatternChange}
    />
  );
};
