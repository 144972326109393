import React, { Fragment } from 'react';
import { CustomizationContainerStyled } from './styled';
import { ColorButton } from '../ColorButton';
import { IColorRangeAPI, ITintAPI } from '@interfaces';
import { AppModal } from 'src/components/AppModal';
import styled from 'styled-components';
import { TintButton } from '../TintButton';
import { Empty } from 'antd';

interface IProps {
  colors?: IColorRangeAPI[];
  tints?: ITintAPI[];
  onColorBtnClick: (color: IColorRangeAPI) => Promise<void>;
  onCloseModal: () => void;
  openModal: boolean;
  colorTitle: string;
  selectedColor: string;
  selectedTint: string;
  onTintBtnClick: (tintHexCode: string) => void;
}

export const ColorCustomization: React.FC<IProps> = ({
  colors,
  tints,
  onColorBtnClick,
  onCloseModal,
  openModal,
  colorTitle,
  selectedColor,
  selectedTint,
  onTintBtnClick,
}) => {
  return (
    <Fragment>
      <CustomizationContainerStyled>
        <h5 className="customization-title">Color</h5>
        <span className="customization-description">Select color range</span>
        <div className="color-customization">
          {colors &&
            colors.map((color) => (
              <ColorButton
                key={color.range_hex_code}
                name="color-advance"
                backgroundColor={color.range_hex_code}
                onClick={() => {
                  void onColorBtnClick(color);
                }}
                checked={selectedColor === color.range_hex_code}
              />
            ))}
        </div>
      </CustomizationContainerStyled>
      <AppModal open={openModal} onClose={onCloseModal}>
        <ModalContainerStyled>
          <div className="modal-title">
            <span>Select a tint of </span>
            <strong>{colorTitle}</strong>
          </div>
          <div className="modal-body">
            {tints &&
              tints.map((tint) => (
                <TintButton
                  key={tint.tintHexCode}
                  colorText={tint.tint}
                  color={tint.tintHexCode}
                  checked={selectedTint === tint.tintHexCode}
                  onClick={() => onTintBtnClick(tint.tintHexCode)}
                />
              ))}
            {!tints && <Empty />}
          </div>
        </ModalContainerStyled>
      </AppModal>
    </Fragment>
  );
};

const ModalContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 30vw;
  .modal-title {
    text-align: center;
    width: 100%;
    font-size: 2rem;
    & > strong {
      text-transform: capitalize;
    }
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
  }
`;
