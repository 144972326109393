import styled from 'styled-components';

import logoUrl from './../../assets/images/logo-light.svg';
import { LoginModule } from '@modules';
import { IMAGES } from '@assets';
import { AUTH_THEME_COLOR } from '@configs';
import { AppImage } from '@components';
import { opacityHex } from '@theme';

export const LoginPage: React.FC = () => {
  return (
    <StyledAuth className="login-page">
      <div className="flex items-center justify-center h-full">
        <AppImage
          src={IMAGES.authImage}
          className="object-cover w-full h-full"
          alt="Filyou Image"
        />
        <div className="flex flex-col items-center justify-center w-full gap-12 px-28 auth-section">
          <div className="flex items-center -intro-x main-logo">
            <AppImage src={logoUrl} alt="Filyou logo" />
          </div>
          <div className="flex flex-col w-full gap-8 py-12 bg-white rounded shadow-lg px-11">
            <h2 className="m-0 font-bold text-center intro-x heading auth-title">
              Sign in Admin Account
            </h2>
            <LoginModule />
          </div>
        </div>
      </div>
    </StyledAuth>
  );
};

export const StyledAuth = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: linear-gradient(180deg, ${AUTH_THEME_COLOR}, ${AUTH_THEME_COLOR + opacityHex[80]})
    no-repeat center;
  background-size: cover;
  background-position: top;
  .auth-section {
    flex-direction: column;
    .heading {
      font-size: 3.2rem;
      margin-bottom: 0.8rem;
    }
    .desc {
      margin-bottom: 2.8rem;
    }
    .main-logo {
      margin: 0 auto 0.8rem;
    }
  }
  .auth-content {
    min-width: 500px;
    .auth-title {
      font-size: 3.2rem;
      font-weight: 600;
      text-align: center;
      margin: 2.5rem 0 3.6rem;
    }
  }
  .intro {
    position: relative;
    min-width: 780px;
    height: 100%;
    .intro-image {
      width: 100%;
      height: 100%;
    }
  }
`;
