import React, { Fragment } from 'react';
import { CustomizationContainerStyled, ModalContainerStyled } from './styled';
import { CheckboxButton } from '../CheckboxButton';
import { AppModal } from 'src/components/AppModal';
import { IFabricAPI, IPersonalizationItem } from '@interfaces';
import styled from 'styled-components';
import { MaterialRadio } from '../MaterialRadio';
import { createInspirationFormSelector, useAppSelector } from '@redux';

interface IProps {
  openModal: boolean;
  onOpenModalClick: () => void;
  onModalCancel: () => void;
  fabrics?: IFabricAPI[];
  personalizationItem: IPersonalizationItem;
  onSelectedMaterialRadioChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleResetFabric: () => void;
}

export const MaterialCustomization: React.FC<IProps> = ({
  openModal,
  onOpenModalClick,
  onModalCancel,
  fabrics,
  personalizationItem,
  onSelectedMaterialRadioChange,
  handleResetFabric,
}) => {
  const { fabricId } = useAppSelector(createInspirationFormSelector);

  return (
    <Fragment>
      <CustomizationContainerStyled>
        <h5 className="customization-title">Material</h5>
        <span className="customization-description">Select material</span>
        <div className="material-customization">
          <CheckboxButton
            onClick={handleResetFabric}
            checked={personalizationItem.fabricId === fabricId}
          >
            Same as main criteria
          </CheckboxButton>
          <button className="browse-btn" onClick={onOpenModalClick}>
            Browse material
          </button>
        </div>
      </CustomizationContainerStyled>
      <AppModal open={openModal} onClose={onModalCancel}>
        <ModalContainerStyled>
          {fabrics &&
            fabrics.map((fabric) => (
              <MaterialRadio
                key={fabric.id}
                name="fabric-customization"
                value={fabric.id}
                onChange={onSelectedMaterialRadioChange}
                checked={personalizationItem.fabricId === fabric.id}
              >
                {fabric.fabric}
              </MaterialRadio>
            ))}
        </ModalContainerStyled>
      </AppModal>
    </Fragment>
  );
};
