import React, { Fragment } from 'react';
import _ from 'lodash';
import { Button, Divider, Space } from 'antd';
import styled from 'styled-components';

import { CustomizationContainerStyled } from './styled';
import { CheckboxButton } from '../CheckboxButton';
import { AppModal } from 'src/components/AppModal';
import { IPersonalizationItem, IPropertyAPI } from '@interfaces';
import { createInspirationFormSelector, useAppSelector } from '@redux';
import { PropertyCheckbox } from 'src/components/inspiration/create-basic';

interface IProps {
  openModal: boolean;
  onOpenModalClick: () => void;
  onModalCancel: () => void;
  groupedProperties?: {
    [x: string]: _.Omit<IPropertyAPI, 'family'>[];
  };
  personalizationItem: IPersonalizationItem;
  handleResetProperties: () => void;
  selectedProperties: Array<Omit<IPropertyAPI, 'family'>>;
  onSelectedPropertyChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    currentProperty: Omit<IPropertyAPI, 'family'>
  ) => void;
  handleUpdateProperties: () => void;
}

export const PropertyCustomization: React.FC<IProps> = ({
  openModal,
  onOpenModalClick,
  onModalCancel,
  groupedProperties,
  personalizationItem,
  handleResetProperties,
  selectedProperties,
  onSelectedPropertyChange,
  handleUpdateProperties,
}) => {
  const { properties } = useAppSelector(createInspirationFormSelector);

  return (
    <Fragment>
      <CustomizationContainerStyled>
        <h5 className="customization-title">Property</h5>
        <span className="customization-description">Select property</span>
        <div className="property-customization">
          <CheckboxButton
            onClick={handleResetProperties}
            checked={_.isEqual(properties, personalizationItem.properties)}
          >
            Same as main criteria
          </CheckboxButton>
          <button className="browse-btn" onClick={onOpenModalClick}>
            Browse property
          </button>
        </div>
      </CustomizationContainerStyled>
      <AppModal open={openModal} onClose={onModalCancel}>
        <ContainerStyled>
          {groupedProperties && (
            <Space direction="vertical" split={<Divider />}>
              {Object.entries(groupedProperties).map(([family, properties]) => (
                <PropertyWrapperStyled key={family}>
                  <h3>{family}</h3>
                  <CheckboxGroupStyled>
                    {properties.map((item) => (
                      <PropertyCheckbox
                        key={item.id}
                        value={item.id}
                        name="property-customization"
                        onChange={(e) => onSelectedPropertyChange(e, item)}
                        checked={selectedProperties.some((property) => property.id === item.id)}
                        disabled={selectedProperties.some(
                          (property) =>
                            property.id !== item.id &&
                            !property.propertyCompatibility.includes(item.id)
                        )}
                      >
                        {item.property}
                      </PropertyCheckbox>
                    ))}
                  </CheckboxGroupStyled>
                </PropertyWrapperStyled>
              ))}
            </Space>
          )}
          <Button
            className="validate-btn"
            size="large"
            shape="round"
            type="primary"
            onClick={handleUpdateProperties}
          >
            Validate properties
          </Button>
        </ContainerStyled>
      </AppModal>
    </Fragment>
  );
};

const ContainerStyled = styled.div`
  padding: 3.2rem 6.4rem;
  width: 60vw;
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
  align-items: flex-start;
  .validate-btn {
    min-width: 12rem;
    min-height: 6.4rem;
    padding: 1.6rem 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    font-weight: 500;
  }
`;

const PropertyWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  & > h3 {
    font-family: 'Gotham', sans-serif;
    font-size: 2rem;
    text-transform: capitalize;
  }
`;

const CheckboxGroupStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  flex-wrap: wrap;
`;
