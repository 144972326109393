import React from 'react';
import _ from 'lodash';
import { Tag } from 'antd';
import styled, { css } from 'styled-components';

import { APP_COLORS } from '@theme';
import { SharedIcon, UploadImageCard } from '@components';
import {
  ColorCustomizationModule,
  MaterialCustomizationModule,
  MotifCustomizationModule,
  PropertyCustomizationModule,
} from '@modules';
import { IPersonalizationItem } from '@interfaces';
import { inspirationSelect, useAppSelector } from '@redux';
import { formatPersonalElementName } from '@utils';
import { ALLOWED_BUCKET_NAMES_OBJECT } from '@configs';

interface IProps {
  personalizationItem: IPersonalizationItem;
  openAdvance: boolean;
  toggleOpenAdvance: () => void;
  handleRemoveItem: (itemId: number) => void;
  onUploadEnd: (data: any) => void;
  onRemoveImage: () => void;
}

export const PersonalizationItem: React.FC<IProps> = ({
  personalizationItem,
  openAdvance,
  toggleOpenAdvance,
  handleRemoveItem,
  onUploadEnd,
  onRemoveImage,
}) => {
  const { listElements, listColors, listPatterns, listFabrics, listProperties } =
    useAppSelector(inspirationSelect);

  const element = listElements?.find((element) => element.id === personalizationItem.elementId);
  const color = listColors?.find(
    (color) => color.range_hex_code === personalizationItem.rangeHexCode,
  );
  const pattern = listPatterns?.find((pattern) => pattern.id === personalizationItem.patternId);
  const fabric = listFabrics?.find((fabric) => fabric.id === personalizationItem.fabricId);
  const properties = listProperties?.filter((property) =>
    personalizationItem.properties.some((p) => p.id === property.id),
  );
  const groupedProperties = _.mapValues(_.groupBy(properties, 'family'), (properties) =>
    properties.map((property) => property.property),
  );

  return (
    <CardStyled>
      <CardTitleStyled>
        <h4>{formatPersonalElementName(element?.elementFamily, element?.element)}</h4>
        <button
          className="remove-btn"
          onClick={() => handleRemoveItem(personalizationItem.elementId)}
        >
          <SharedIcon name="trash" size={20} />
        </button>
      </CardTitleStyled>
      <CardBodyStyled>
        <PersonalizationInfoList>
          <li>
            <span className="list-item-label">Color:</span>
            <span className="list-item-content">{color?.colorRange}</span>
          </li>
          <li>
            <span className="list-item-label">Motif:</span>
            <span className="list-item-content">{pattern?.pattern}</span>
          </li>
          <li>
            <span className="list-item-label">Material:</span>
            <span className="list-item-content">{fabric?.fabric}</span>
          </li>
          <li>
            <span className="list-item-label">Properties:</span>
            {Object.keys(groupedProperties).length > 0 && (
              <ul className="list-properties">
                {Object.entries(groupedProperties).map(([family, properties]) => (
                  <li key={family} className="list-property-item">
                    <span className="property-family">{family}:</span>
                    <span className="properties-text">{properties.join(', ')}</span>
                  </li>
                ))}
              </ul>
            )}
            {Object.keys(groupedProperties).length < 1 && (
              <span className="list-item-content">
                <Tag color="error">Empty</Tag>
              </span>
            )}
          </li>
        </PersonalizationInfoList>
        <UploadImageCard
          bucketName={ALLOWED_BUCKET_NAMES_OBJECT.ADMIN_INSPIRATION}
          width={350}
          height={250}
          onUploadEnd={onUploadEnd}
          onRemoveImage={onRemoveImage}
          previewUrl={personalizationItem.selectedImage?.[0]?.imageUrl}
        />
      </CardBodyStyled>
      <AdvanceContainaerStyled $openAdvance={openAdvance}>
        <ColorCustomizationModule personalizationItem={personalizationItem} />
        <MotifCustomizationModule personalizationItem={personalizationItem} />
        <MaterialCustomizationModule personalizationItem={personalizationItem} />
        <PropertyCustomizationModule personalizationItem={personalizationItem} />
      </AdvanceContainaerStyled>
      <button className="advance-btn" onClick={toggleOpenAdvance}>
        <span>
          {openAdvance ? (
            <SharedIcon name="upOutlined" size={16} />
          ) : (
            <SharedIcon name="downOutlined" size={16} />
          )}
        </span>
        <span>Advance Customization</span>
      </button>
    </CardStyled>
  );
};

const CardStyled = styled.div`
  border: 0.1rem solid ${APP_COLORS.normal};
  border-radius: 2rem;
  width: 90rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  overflow: hidden;
  & > .advance-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    gap: 0.8rem;
    border-top: 0.1rem solid ${APP_COLORS.normal};
    padding: 0.8rem;
  }
`;

const CardTitleStyled = styled.div`
  padding: 1.2rem 2.4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > h4 {
    font-family: 'Gotham', sans-serif;
    font-size: 2.2rem;
    text-transform: capitalize;
  }
  & > .remove-btn {
    transition: all 0.2s ease-in-out;
    &:hover {
      color: ${APP_COLORS.primary};
    }
    &:active {
      transform: scale(1.1);
    }
  }
`;

const CardBodyStyled = styled.div`
  padding: 0.8rem 2.4rem;
  width: 100%;
  display: flex;
  gap: 6.4rem;
  justify-content: space-between;
`;

const PersonalizationInfoList = styled.ul`
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  & > li {
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    & > .list-item-label {
      color: ${APP_COLORS.gray500};
    }
    & > .list-item-content {
      text-transform: uppercase;
    }
    & > .list-properties {
      margin: 0;
      & > .list-property-item {
        display: flex;
        align-items: flex-start;
        gap: 0.6rem;
        & > .property-family {
          text-transform: capitalize;
        }
        & > .properties-text {
          text-transform: uppercase;
        }
      }
    }
  }
`;

const AdvanceContainaerStyled = styled.div<{ $openAdvance: boolean }>`
  padding: 0.8rem 2.4rem;
  ${(props) =>
    props.$openAdvance &&
    css`
      display: grid;
    `};
  ${(props) =>
    !props.$openAdvance &&
    css`
      display: none;
    `};
  grid-template-columns: repeat(2, 1fr);
  gap: 3.2rem;
  justify-content: space-between;
`;

const CustomizationContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  border: 0.1rem solid ${APP_COLORS.normal};
  border-radius: 0.8rem;
  padding: 1.6rem 2rem;
  & > .customization-title {
    font-size: 1.6rem;
    font-family: 'Gotham', sans-serif;
  }
  & > .customization-description {
    font-size: 1.4rem;
    color: ${APP_COLORS.gray500};
  }
  .color-customization {
    display: grid;
    grid-template-columns: repeat(5, min-content);
    column-gap: 1.2rem;
    row-gap: 1.2rem;
  }
  .motif-customization,
  .material-customization,
  .property-customization {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    align-items: flex-start;
    & > .browse-btn {
      width: 100%;
      font-size: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.2rem;
      border: 0.1rem solid ${APP_COLORS.primary};
      color: ${APP_COLORS.primary};
      border-radius: 100rem;
      transition: all 0.2s;
      &:active {
        transform: scale(1.01);
      }
    }
  }
`;
