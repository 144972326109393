import { patternAPI } from '@api';
import { IPatternAPI } from '@interfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';

const prefix = '/pattern';

export const getPatternsAction = createAsyncThunk<IPatternAPI[], void>(
  `${prefix}/getPatternsAction`,
  async () => {
    try {
      const response = await patternAPI.getPatterns();
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.message.data);
    }
  }
);
