import { IMAGES } from '@assets';
import { AppImage } from '@components';
import { enumStatus, enumTailorRegistrationStatus } from '@configs';
import { IGetAllTailorsParams } from '@interfaces';
import { TailorManagementSection } from '@pages';
import {
  getAllTailorsAction,
  selectTailor,
  setTableLoading,
  tailorsTableSelector,
  toggleTailorStatus,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { Switch } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useMemo, useState } from 'react';

export interface TailorDataType {
  key: number;
  name?: string | null;
  email?: string | null;
  address?: string | null;
  profile?: string | null;
  status?: enumStatus | null;
}

export const TailorManagementModule: React.FC = () => {
  const dispatch = useAppDispatch();
  const tailorsTableData = useAppSelector(tailorsTableSelector);
  const { limitPerPage, totalTailors } = useAppSelector(selectTailor);

  const [payload, setPayload] = useState<IGetAllTailorsParams>({
    validationStatus: enumTailorRegistrationStatus.APPROVED,
    page: 1,
    limit: 10,
  });

  const [pagination, setPagination] = useState({
    totalItems: 0,
    perPage: 10,
  });

  const handleChangePage = (page: number) => {
    setPayload({ ...payload, page });
  };

  const handleChangePageSize = (size: number) => {
    setPayload({ ...payload, limit: size, page: 1 });
  };

  const columns: ColumnsType<TailorDataType> = useMemo(() => {
    return [
      {
        title: 'No',
        key: 'no',
        render: (_value, _record, index) => (payload.page - 1) * pagination.perPage + index + 1,
        width: 85,
      },
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: 'Email',
        key: 'email',
        dataIndex: 'email',
      },
      {
        title: 'Address',
        key: 'address',
        dataIndex: 'address',
      },
      {
        title: 'Profile',
        key: 'profile',
        dataIndex: 'profile',
        width: 125,
        render: (value, record) => {
          return (
            <AppImage
              size={50}
              firebaseResourceKey={value}
              alt={`${record.name} photo`}
              containerClassName="rounded-full overflow-hidden"
              className="object-cover w-full h-full rounded-full"
              fallbackImg={IMAGES.user64}
            />
          );
        },
      },
      {
        title: 'Action',
        key: 'action',
        width: 125,
        render: (_value, record) => {
          const onTailorStatusChange = (checked: boolean): void => {
            if (!checked) {
              dispatch(toggleTailorStatus({ id: record.key, status: enumStatus.INACTIVE }));
              return;
            }
            dispatch(toggleTailorStatus({ id: record.key, status: enumStatus.ACTIVE }));
          };
          return (
            <Switch
              onChange={onTailorStatusChange}
              defaultChecked={record.status === enumStatus.ACTIVE}
            />
          );
        },
      },
    ];
  }, [payload.page, pagination.perPage]);

  useEffect(() => {
    dispatch(setTableLoading(true));
    const promise = dispatch(getAllTailorsAction({ ...payload }));
    void promise
      .then(() => {
        setPagination({
          totalItems: totalTailors,
          perPage: limitPerPage,
        });
        dispatch(setTableLoading(false));
      })
      .catch(() => {
        dispatch(setTableLoading(false));
      });

    return () => {
      promise.abort();
    };
  }, [payload.limit, payload.page, totalTailors, limitPerPage]);

  return (
    <TailorManagementSection
      currentPage={payload.page}
      columns={columns}
      dataSource={tailorsTableData}
      pagination={pagination}
      onChangePageSize={handleChangePageSize}
      onPageChange={handleChangePage}
    />
  );
};
