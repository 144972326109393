import React, { useRef } from 'react';
import { ContainerStyled, SectionCreateBasicStyled } from './styled';
import { ShareAreaInput, ShareInput } from 'src/components/shared';
import styled from 'styled-components';
import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';
import { Switch } from 'antd';

interface IProps {
  register: UseFormRegister<FieldValues>;
  errors: Partial<FieldErrorsImpl<any>>;
  onSwitchChange: (checked: boolean) => void;
  isOnHomePage: boolean;
}

export const InspirationInfoFormSection: React.FC<IProps> = ({
  register,
  errors,
  onSwitchChange,
  isOnHomePage,
}) => {
  return (
    <SectionCreateBasicStyled>
      <ContainerStyled>
        {/* @ts-ignore */}
        <WrapperStyled>
          <ShareInput
            name="title"
            label="Title"
            required
            className="input"
            errors={errors['title']?.message}
            register={register}
            maxLength={101}
          />
          <ShareInput
            name="price"
            label="Price"
            required
            className="input"
            errors={errors['price']?.message}
            register={register}
            suffix="€"
            inputDefaultStyle="postTab"
            type="number"
            onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
          />
          <ShareAreaInput
            name="description"
            label="Description"
            inputClassName="textarea"
            errors={errors['description']?.message}
            register={register}
            required
            maxLength={256}
          />
          <div className="highlight-box">
            <label>Hightlight</label>
            <Switch checked={isOnHomePage} onChange={onSwitchChange} />
          </div>
        </WrapperStyled>
      </ContainerStyled>
    </SectionCreateBasicStyled>
  );
};

const WrapperStyled = styled.div`
  width: 48rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.4rem;
  .input__label {
    font-size: 1.6rem;
  }
  .input {
    font-size: 1.6rem;
  }
  .textarea {
    min-height: 12rem;
    max-height: 32rem;
    font-size: 1.6rem;
  }
  .highlight-box {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    font-size: 1.6rem;
  }
`;
