import { InspirationMaterialPrincipleSection } from '@components';
import { IPropertyAPI } from '@interfaces';
import {
  createInspirationFormSelector,
  getPropertiesAction,
  getUserFabricsAction,
  inspirationSelect,
  setSelectedFabric,
  setSelectedProperties,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { RadioChangeEvent } from 'antd';
import React, { useEffect } from 'react';
import _ from 'lodash';

export const InspirationMaterialPrincipleModule: React.FC = () => {
  const dispatch = useAppDispatch();
  const { listFabrics, listProperties } = useAppSelector(inspirationSelect);
  const { fabricId, properties } = useAppSelector(createInspirationFormSelector);

  const groupedProperties = _.mapValues(_.groupBy(listProperties, 'family'), (properties) =>
    properties.map((property) => _.omit(property, ['family']))
  );

  const onSelectedFabricChange = (e: RadioChangeEvent): void => {
    dispatch(setSelectedFabric(e.target.value));
  };

  const onSelectedPropertyChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    currentProperty: Omit<IPropertyAPI, 'family'>
  ): void => {
    if (e.target.checked) {
      dispatch(
        setSelectedProperties([
          ...properties.filter((property) =>
            currentProperty.propertyCompatibility.includes(property.id)
          ),
          currentProperty,
        ])
      );
      return;
    }
    dispatch(
      setSelectedProperties(properties.filter((property) => property.id !== currentProperty.id))
    );
  };

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await dispatch(getUserFabricsAction());
      await dispatch(getPropertiesAction());
    };

    void fetchData();
  }, []);

  return (
    <InspirationMaterialPrincipleSection
      fabrics={listFabrics}
      selectedFabric={fabricId}
      onSelectedFabricChange={onSelectedFabricChange}
      groupedProperties={groupedProperties}
      listSelectedProperties={properties}
      onSelectedPropertyChange={onSelectedPropertyChange}
    />
  );
};
