import { InspirationUploadPhotoSection } from '@components';
import { enumImagePosition } from '@configs';
import {
  createInspirationFormSelector,
  setInspirationPicture,
  useAppDispatch,
  useAppSelector,
} from '@redux';

export const InspirationUploadPhotoModule: React.FC = () => {
  const dispatch = useAppDispatch();
  const { inspirationPictures } = useAppSelector(createInspirationFormSelector);

  const onUploadImageEnd = (position: enumImagePosition, data: any): void => {
    dispatch(
      setInspirationPicture({
        position,
        mediaId: data?.id,
        url: data?.url,
      }),
    );
  };

  const onRemoveImage = (position: enumImagePosition): void => {
    dispatch(
      dispatch(
        setInspirationPicture({
          position,
          mediaId: undefined,
          url: undefined,
        }),
      ),
    );
  };

  return (
    <InspirationUploadPhotoSection
      inspirationPictures={inspirationPictures}
      onUploadImageEnd={onUploadImageEnd}
      onRemoveImage={onRemoveImage}
    />
  );
};
