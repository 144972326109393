import { IconMap } from './IconMap';

interface SharedIconProps extends React.SVGProps<SVGSVGElement> {
  name: keyof typeof IconMap;
  size?: string | number;
}

export const SharedIcon: React.FC<SharedIconProps> = ({
  name,
  width = 24,
  height = 24,
  size,
  ...rest
}) => {
  const MatchIcon = IconMap[name];

  return <MatchIcon width={size ?? width} height={size ?? width} {...rest} />;
};
