import { ICustomer, IGetListResponse } from '@interfaces';
import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { getAllCustomersAction } from '../actions';
import { RootState } from '.';
import { formatUserFullname } from '@utils';

interface ICustomerState {
  customers?: ICustomer[];
  totalCustomers: number;
  limitPerPage: number;
}

const initialState: ICustomerState = {
  customers: undefined,
  totalCustomers: 0,
  limitPerPage: 10,
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    resetCustomer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllCustomersAction.fulfilled,
      (state, action: PayloadAction<IGetListResponse<ICustomer>>) => {
        state.customers = action.payload.items;
        state.totalCustomers = action.payload.total;
        state.limitPerPage = action.payload.limit;
      },
    );
  },
});

export const selectCustomer = createSelector(
  (state: RootState) => state.customer,
  (state) => state,
);

export const customersTableSelector = createSelector(
  (state: RootState) => state.customer,
  (state) =>
    state.customers
      ?.map((customer) => ({
        key: customer.id,
        name: formatUserFullname(customer.firstName, customer.lastName),
        email: customer.email,
        address: customer.deliveryAddress[0]?.address1,
        createdAt: customer.createdAt,
      }))
      .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)),
);

export const { resetCustomer } = customerSlice.actions;
export default customerSlice.reducer;
