import { Pagination, SharedTable, StyledBottomPagination, StyledSection } from '@components';
import { PATH_REGISTRATION_TAILOR } from '@configs';
import { type TailorDataType } from '@modules';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface IProps {
  columns: ColumnsType<TailorDataType>;
  dataSource?: TailorDataType[];
  currentPage: number;
  pagination: {
    totalItems: number;
    perPage: number;
  };
  onChangePageSize: (size: number) => void;
  onPageChange: (size: number) => void;
}

export const TailorManagementSection: React.FC<IProps> = ({
  columns,
  dataSource,
  currentPage,
  pagination,
  onChangePageSize,
  onPageChange,
}) => {
  return (
    <StyledSection>
      <SharedTable
        columns={columns}
        dataSource={dataSource}
        // scroll={{ scrollToFirstRowOnChange: true, y: 320 }}
      />
      <StyledBottomTable>
        <StyledMetadataPagination>
          <span className="total-count">
            Total count: <span>{pagination.totalItems}</span>
          </span>
          <StyledBottomPagination>
            <Pagination
              currentPage={currentPage}
              pageSize={pagination.perPage}
              totalCount={pagination.totalItems}
              onChangePageSize={onChangePageSize}
              onPageChange={onPageChange}
            />
          </StyledBottomPagination>
        </StyledMetadataPagination>
        <Link className="registration-link" to={PATH_REGISTRATION_TAILOR}>
          Waiting for Approval
        </Link>
      </StyledBottomTable>
    </StyledSection>
  );
};

const StyledBottomTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > .registration-link {
    font-size: 1.8rem;
    text-decoration: underline;
    text-underline-offset: 0.4rem;
  }
`;

const StyledMetadataPagination = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;
  & > .total-count {
    font-size: 1.6rem;
  }
`;
