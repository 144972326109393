import { StyledPage } from '@components';
import { BreadcrumbsModule, CustomerManagementModule } from '@modules';

export const CustomerManagementPage = () => {
  return (
    <StyledPage>
      <BreadcrumbsModule />
      <CustomerManagementModule />
    </StyledPage>
  );
};
