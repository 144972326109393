export const PATH_HOME = '/';
export const PATH_TAILOR = '/tailors';
export const PATH_CUSTOMER = '/customers';
export const PATH_PROMOTION = '/promotions';
export const PATH_INSPIRATION = '/inspirations';

//auth
export const PATH_LOGIN = '/login';
export const PATH_FORGOT_PASSWORD = '/forgot';
export const PATH_RESET_PASSWORD = '/reset';
// tailor
export const PATH_REGISTRATION_TAILOR = PATH_TAILOR + '/registration';
export const PATH_VIEW_APPLICATION_TAILOR = PATH_REGISTRATION_TAILOR + '/details/:id';
//customer

// inspiration
export const PATH_CREATE_INSPIRATION = PATH_INSPIRATION + '/create';
export const PATH_CREATE_INSPIRATION_BASIC = PATH_CREATE_INSPIRATION + '/basic';
export const PATH_CREATE_INSPIRATION_PERSONALIZE = PATH_CREATE_INSPIRATION + '/personalization';
export const PATH_VIEW_DETAILS_INSPIRATION = PATH_INSPIRATION + '/details/:id';
export const PATH_EDIT_INSPIRATION = PATH_INSPIRATION + '/update/:id';

// promotion
export const PATH_VIEW_PROMOTION = PATH_PROMOTION + '/details/:id';
export const PATH_ADD_PROMOTION = PATH_PROMOTION + '/create';
export const PATH_EDIT_PROMOTION = PATH_PROMOTION + '/update/:id';

//error
export const PATH_404 = '/*';
