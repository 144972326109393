import { Pagination, SharedTable, StyledBottomPagination, StyledSection } from '@components';
import { type TailorRegistrationDataType } from '@modules';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';

interface IProps {
  columns: ColumnsType<TailorRegistrationDataType>;
  dataSource?: TailorRegistrationDataType[];
  currentPage: number;
  pagination: {
    totalItems: number;
    perPage: number;
  };
  onChangePageSize: (size: number) => void;
  onPageChange: (size: number) => void;
}

export const TailorRegistrationSection: React.FC<IProps> = ({
  columns,
  dataSource,
  pagination,
  currentPage,
  onChangePageSize,
  onPageChange,
}) => {
  return (
    <StyledSection>
      <SharedTable columns={columns} dataSource={dataSource} />
      <StyledBottomTable>
        <span className="total-count">
          Total count: <span>{pagination.totalItems}</span>
        </span>
        <StyledBottomPagination>
          <Pagination
            pageSize={pagination.perPage}
            totalCount={pagination.totalItems}
            currentPage={currentPage}
            onChangePageSize={onChangePageSize}
            onPageChange={onPageChange}
          />
        </StyledBottomPagination>
      </StyledBottomTable>
    </StyledSection>
  );
};

const StyledBottomTable = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;
  & > .total-count {
    font-size: 1.6rem;
  }
`;
