import { BaseSyntheticEvent, memo } from 'react';
import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';

import { SharedButton, SharedCheckbox, ShareInput } from '@components';
import { ILoginFields } from '@interfaces';
import { AUTH_THEME_COLOR, PATH_LOGIN } from '@configs';
import { themes, useTheme } from '@theme';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface IProps {
  errors: Partial<FieldErrorsImpl<any>>;
  register: UseFormRegister<FieldValues>;
  handleForgotPassword: (
    e?: BaseSyntheticEvent<ILoginFields, any, any> | undefined
  ) => Promise<void>;
}

export const ForgotPasswordForm = memo((props: IProps) => {
  const { errors, register, handleForgotPassword } = props;

  const { theme } = useTheme();

  return (
    <StyledForgotPasswordForm className="forgot-password__section">
      {/* @ts-ignore */}
      <form onSubmit={handleForgotPassword} className="forgot-form">
        <ShareInput
          placeholder="E-mail"
          name="email"
          className="input"
          errors={errors['email']?.message}
          register={register}
        />
        <div className="actions">
          <SharedButton
            typeHtml="submit"
            text="Send"
            className="submit__btn"
            backgroundColor={AUTH_THEME_COLOR}
            btnStyle="rounded"
          />
          <span className="back-text">
            Back to{' '}
            <Link className="back-link" to={PATH_LOGIN}>
              Sign in
            </Link>
          </span>
        </div>
      </form>
    </StyledForgotPasswordForm>
  );
});

export const StyledForgotPasswordForm = styled.section`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  .heading {
    margin-bottom: 3.6rem;
    .heading__title {
      text-align: center;
      font-size: 3.2rem;
      font-weight: 700;
      margin-bottom: 0.8rem;
      color: ${(p) => p.theme.colors.text};
    }
    .desc {
      font-size: 1.6rem;
      font-weight: 400;
      color: ${(p) => p.theme.colors.subText};
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.4rem;
    & > .back-text {
      font-size: 1.6rem;
      & > .back-link {
        font-weight: 600;
      }
    }
  }

  .forgot-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .input {
      font-size: 1.6rem;
    }
    .shared-input {
      margin-bottom: 2rem;
      .inner-input {
        height: 4.6rem;
      }
    }
    .submit__btn {
      font-size: 1.6rem;
      width: 20rem;
      height: 5.2rem;
    }
    .forgot-password {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      .forgot-text {
        font-size: 1em;
        font-weight: 400;
        color: ${(p) => p.theme.colors.authText};
      }
    }

    .inner-input {
      width: 100%;
      height: 4.6rem;
    }

    .agree-policy__cb {
      margin-bottom: 1.8rem;
    }
  }
`;
