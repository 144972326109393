import React, { BaseSyntheticEvent } from 'react';
import { ShareInput, SharedButton } from '../shared';
import styled from 'styled-components';
import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';
import { MAIN_THEME_DATA } from '@configs';
import { themes, useTheme } from '@theme';
import { selectApp, setShowChangePasswordModal, useAppDispatch, useAppSelector } from '@redux';
import { Button } from 'antd';

interface ChangePasswordFields {
  oldPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
}

interface ChangePasswordFormProps {
  register: UseFormRegister<FieldValues>;
  errors: Partial<FieldErrorsImpl<any>>;
  handleChangePassword: (e?: BaseSyntheticEvent<ChangePasswordFields, any, any>) => Promise<void>;
}

export const ChangePasswordForm = (props: ChangePasswordFormProps): JSX.Element => {
  const { register, errors, handleChangePassword } = props;
  const { theme } = useTheme();

  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(selectApp);

  const handleCancelModal = (): void => {
    dispatch(setShowChangePasswordModal(false));
  };

  return (
    <>
      {/* @ts-ignore */}
      <ChangePasswordFormStyles onSubmit={handleChangePassword} autoComplete="off">
        <ShareInput
          name="oldPassword"
          label="Old password"
          placeholder="Enter old password"
          className="input"
          type="password"
          register={register}
          errors={errors['oldPassword']?.message}
          disabled={loading}
          required
          haveShowPassIcon
        />
        <ShareInput
          name="newPassword"
          label="New password"
          placeholder="Enter new password"
          className="input"
          type="password"
          register={register}
          errors={errors['newPassword']?.message}
          disabled={loading}
          haveShowPassIcon
          required
        />
        <ShareInput
          name="confirmPassword"
          label="Confirm new password"
          placeholder="Confirm new password"
          className="input"
          type="password"
          register={register}
          errors={errors['confirmPassword']?.message}
          disabled={loading}
          haveShowPassIcon
          required
        />
        <ActionsStyles>
          <Button
            htmlType="button"
            onClick={handleCancelModal}
            disabled={loading}
            size="large"
            shape="round"
          >
            Cancel
          </Button>
          <Button htmlType="submit" disabled={loading} size="large" shape="round" type="primary">
            Save
          </Button>
        </ActionsStyles>
      </ChangePasswordFormStyles>
    </>
  );
};

const ChangePasswordFormStyles = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  .input {
    font-size: 1.4rem;
  }
  label {
    font-size: 1.6rem;
  }
`;

const ActionsStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.4rem;
`;
