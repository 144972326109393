import { IMAGES } from '@assets';
import { LogApp, showFirebaseResource } from '@utils';
import { Skeleton, Image } from 'antd';
import classNames from 'classnames';
import { memo, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

interface IProps extends React.ComponentPropsWithRef<'img'> {
  src?: string;
  size?: number;
  width?: number;
  height?: number;
  className?: string;
  alt?: string;
  noImageLoadingIcon?: boolean;
  onClick?: any;
  canPreview?: boolean;
  fallbackImg?: string;
  containerClassName?: string;
  firebaseResourceKey?: string;
}

export const AppImage = memo((props: IProps) => {
  const {
    src,
    className,
    alt,
    size,
    width,
    height,
    noImageLoadingIcon = true,
    canPreview,
    onClick,
    fallbackImg = IMAGES.defaultImage,
    containerClassName,
    firebaseResourceKey,
    ...rest
  } = props;

  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [firebaseResouceImage, setFirebaseResourceImage] = useState<string | undefined>(undefined);

  const imageSrc = firebaseResourceKey ? firebaseResouceImage : src;

  useEffect(() => {
    if (!firebaseResourceKey) return;
    const handleGetFirebaseResourceImage = async (): Promise<void> => {
      try {
        const data = await showFirebaseResource({
          url: firebaseResourceKey,
        });
        setFirebaseResourceImage(data);
      } catch (error: any) {
        LogApp('AppImageError', error);
      }
    };

    void handleGetFirebaseResourceImage();
  }, [firebaseResourceKey]);

  return (
    <StyledImage
      className={classNames('image-contain', containerClassName)}
      $size={size}
      $width={width}
      $height={height}
    >
      {canPreview ? (
        <Image
          key={firebaseResourceKey || src}
          onClick={onClick}
          alt={alt}
          className={classNames(
            'smooth-image',
            imageLoaded ? 'dh-image' : 'image-hidden',
            className,
          )}
          src={imageSrc || fallbackImg}
          onLoad={() => setImageLoaded(true)}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = fallbackImg;
          }}
        />
      ) : (
        <img
          key={firebaseResourceKey || src}
          onClick={onClick}
          alt={alt}
          className={classNames(
            'smooth-image',
            imageLoaded ? 'dh-image' : 'image-hidden',
            className,
          )}
          src={imageSrc || fallbackImg}
          onLoad={() => setImageLoaded(true)}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = fallbackImg;
          }}
          {...rest}
        />
      )}

      {!imageLoaded && (
        <StyledPreLoader className="smooth-preloader" noImageLoadingIcon={noImageLoadingIcon}>
          <Skeleton.Image active />
        </StyledPreLoader>
      )}
    </StyledImage>
  );
});

const StyledImage = styled.div<{
  $size?: number;
  $width?: number;
  $height?: number;
}>`
  position: relative;
  width: 100%;
  height: 100%;
  ${({ $size, $height, $width }) =>
    ($size || ($height && $width)) &&
    css`
      width: ${$size || $width}px;
      height: ${$size || $height}px;
    `}
  .error-load {
    width: 100%;
    height: 100%;
  }
  .smooth-image {
    transition: opacity 1s;
  }
  .dh-image {
    opacity: 1;
  }
  .image-hidden {
    opacity: 0;
  }
`;

const StyledPreLoader = styled.div<{ noImageLoadingIcon?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-skeleton-element {
    width: 100%;
    height: 100%;
    .ant-skeleton-image {
      width: 100%;
      height: 100%;
    }
    .ant-skeleton-image-svg {
      ${(p) =>
        p.noImageLoadingIcon &&
        css`
          visibility: hidden;
        `};
      width: 45%;
      height: 45%;
    }
  }
`;
