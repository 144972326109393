import { BaseSyntheticEvent, memo } from 'react';
import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';

import { SharedButton, ShareInput } from '@components';
import { StyledLoginSection } from './loginStyle';
import { ILoginFields } from '@interfaces';
import { AUTH_THEME_COLOR, PATH_FORGOT_PASSWORD } from '@configs';
import { themes, useTheme } from '@theme';
import { Link } from 'react-router-dom';

interface IProps {
  register: UseFormRegister<FieldValues>;
  errors: Partial<FieldErrorsImpl<any>>;
  handleLogin: (e?: BaseSyntheticEvent<ILoginFields, any, any> | undefined) => Promise<void>;
}

export const LoginForm = memo((props: IProps) => {
  const { errors, register, handleLogin } = props;

  return (
    <StyledLoginSection className="login__section">
      {/* @ts-ignore */}
      <form onSubmit={handleLogin} className="login-form">
        <ShareInput
          name="email"
          placeholder="Email"
          className="input"
          errors={errors['email']?.message}
          register={register}
        />
        <ShareInput
          placeholder="Password"
          name="password"
          className="input"
          type="password"
          errors={errors['password']?.message}
          register={register}
          haveShowPassIcon
        />
        <Link to={PATH_FORGOT_PASSWORD} className="redirect-btn forgot-text">
          Forgot password?
        </Link>
        <div className="actions">
          <SharedButton
            typeHtml="submit"
            text="Sign In"
            className="submit__btn login-btn"
            backgroundColor={AUTH_THEME_COLOR}
            btnStyle="rounded"
          />
        </div>
      </form>
    </StyledLoginSection>
  );
});
