import { APP_COLORS } from '@theme';
import styled from 'styled-components';

export const SectionCreateBasicStyled = styled.div`
  min-height: 50vh;
  padding: 1.8rem;
  .ant-upload-list.ant-upload-list-picture-card {
    display: flex;
    gap: 2.4rem;
    .ant-upload-list-picture-card-container,
    .ant-upload.ant-upload-select.ant-upload-select-picture-card {
      width: 24rem;
      height: 36rem;
    }
    .ant-upload-list-item-image {
      object-fit: cover;
    }
  }
`;

export const ContainerStyled = styled.div`
  display: flex;
  gap: 6.4rem;
  width: 100%;
  .ant-radio-button-wrapper {
    & > .ant-radio-button + span {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .radio-btn-color {
    padding: 0;
    overflow: hidden;
    & > .ant-radio-button + span {
      height: 100%;
      & > span {
        padding: 0 1.5rem;
      }
      & .color {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .radio-color {
    & > .ant-radio + span {
      display: flex;
      min-width: 20rem;
      align-items: center;
      justify-content: space-between;
      gap: 0.6rem;
    }
  }
`;

export const SelectContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  & > h2 {
    font-family: 'Gotham', sans-serif;
    font-size: 1.8rem;
  }
`;

export const RadioContainer = styled.div`
  border: 0.1rem solid ${APP_COLORS.normal};
  border-radius: 0.6rem;
  padding: 1.6rem 1.2rem;
`;
