import { Pagination, SharedTable, StyledBottomPagination, StyledSection } from '@components';
import { InspirationDataType } from '@modules';
import { APP_COLORS } from '@theme';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';

interface IProps {
  columns: ColumnsType<InspirationDataType>;
  dataSource?: InspirationDataType[];
  currentPage: number;
  pagination: {
    totalItems: number;
    perPage: number;
  };
  onChangePageSize: (size: number) => void;
  onPageChange: (size: number) => void;
  handleNavigateCreateInspiration: () => void;
}

export const InspirationManagementSection: React.FC<IProps> = ({
  columns,
  dataSource,
  currentPage,
  pagination,
  onChangePageSize,
  onPageChange,
  handleNavigateCreateInspiration,
}) => {
  return (
    <StyledSection>
      <ContainerStyled>
        <button className="add-btn" onClick={handleNavigateCreateInspiration}>
          Add Inspiration
        </button>
        <SharedTable columns={columns} dataSource={dataSource} />
      </ContainerStyled>
      <StyledBottomTable>
        <span className="total-count">
          Total count: <span>{pagination.totalItems}</span>
        </span>
        <StyledBottomPagination>
          <Pagination
            pageSize={pagination.perPage}
            totalCount={pagination.totalItems}
            currentPage={currentPage}
            onChangePageSize={onChangePageSize}
            onPageChange={onPageChange}
          />
        </StyledBottomPagination>
      </StyledBottomTable>
    </StyledSection>
  );
};

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  & > .add-btn {
    align-self: flex-end;
    border: 0.1rem solid ${APP_COLORS.primary};
    font-size: 1.8rem;
    padding: 0.8rem 2.4rem;
    color: ${APP_COLORS.primary};
    border-radius: 100rem;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: ${APP_COLORS.primary};
      color: ${APP_COLORS.white};
    }
    &:active {
      transform: scale(0.98);
    }
  }
`;

const StyledBottomTable = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;
  & > .total-count {
    font-size: 1.6rem;
  }
`;
