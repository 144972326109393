import { Switch, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IMAGES } from '@assets';
import { AppImage, IconBtn, SharedIcon } from '@components';
import { PATH_CREATE_INSPIRATION_BASIC, PATH_INSPIRATION } from '@configs';
import { IGetListParams } from '@interfaces';
import { InspirationManagementSection } from '@pages';
import {
  getAllInspirationsAction,
  inspirationSelect,
  inspirationsTableSelector,
  resetCreateInspiration,
  setTableLoading,
  toggleInspirationHightlightAction,
  toggleInspirationStatusAction,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { APP_COLORS } from '@theme';

export interface InspirationDataType {
  key: number;
  title?: string | null;
  price?: number | null;
  image?: string | null;
  onHomepage?: boolean | null;
  enabled?: boolean | null;
}

export const InspirationManagementModule: React.FC = () => {
  const dispatch = useAppDispatch();
  const inspirationsTableData = useAppSelector(inspirationsTableSelector);
  const { limitPerPage, totalInspirations } = useAppSelector(inspirationSelect);

  const navigate = useNavigate();
  const [payload, setPayload] = useState<IGetListParams>({
    page: 1,
    limit: 10,
  });

  const [pagination, setPagination] = useState({
    totalItems: 0,
    perPage: 10,
  });

  const handleChangePage = (page: number) => {
    setPayload({ ...payload, page });
  };

  const handleChangePageSize = (size: number) => {
    setPayload({ ...payload, limit: size, page: 1 });
  };

  const handleNavigateCreateInspiration = (): void => {
    dispatch(resetCreateInspiration());
    navigate(PATH_CREATE_INSPIRATION_BASIC);
  };

  const handleNavigateViewInspiration = (id: number) => {
    navigate(`${PATH_INSPIRATION}/details/${id}`);
  };

  const handleNavigateUpdateInspiration = (id: number) => {
    navigate(`${PATH_INSPIRATION}/update/${id}`);
  };

  const columns = useMemo<ColumnsType<InspirationDataType>>(() => {
    return [
      {
        title: 'No',
        key: 'no',
        render: (_value, _record, index) => (payload.page - 1) * pagination.perPage + index + 1,
        width: 85,
      },
      {
        title: 'Image',
        key: 'image',
        dataIndex: 'image',
        render: (value) => {
          return (
            <AppImage
              className="object-cover w-full h-full rounded-lg shadow-md"
              firebaseResourceKey={value}
              size={150}
            />
          );
        },
      },
      {
        title: 'Title',
        key: 'title',
        dataIndex: 'title',
      },
      {
        title: 'Price',
        key: 'price',
        dataIndex: 'price',
        render: (value: number) => value + '€',
      },
      {
        title: 'Hightlight',
        key: 'hightlight',
        render: (_, record) => {
          const onInspirationHightlightChange = (checked: boolean): void => {
            if (!checked) {
              dispatch(toggleInspirationHightlightAction({ id: record.key, onHomepage: false }));
              return;
            }
            dispatch(toggleInspirationHightlightAction({ id: record.key, onHomepage: true }));
          };

          return (
            <div className="flex items-center justify-center">
              <Switch
                onChange={onInspirationHightlightChange}
                defaultChecked={!!record.onHomepage}
              />
            </div>
          );
        },
      },
      {
        title: 'Action',
        key: 'action',
        width: 225,
        render: (_value, record) => {
          const onInspirationStatusChange = (checked: boolean): void => {
            if (!checked) {
              dispatch(toggleInspirationStatusAction({ id: record.key, enabled: false }));
              return;
            }
            dispatch(toggleInspirationStatusAction({ id: record.key, enabled: true }));
          };

          return (
            <div className="flex items-center justify-center gap-4">
              <IconBtn
                onClick={() => handleNavigateViewInspiration(record.key)}
                $bordered
                $borderColor={APP_COLORS.green700}
              >
                <SharedIcon name="view" size={20} color={APP_COLORS.green700} />
              </IconBtn>
              <IconBtn
                onClick={() => handleNavigateUpdateInspiration(record.key)}
                $bordered
                $borderColor={APP_COLORS.info}
              >
                <SharedIcon name="pencil" size={18} color={APP_COLORS.info} />
              </IconBtn>
              <Tooltip title="Inactive/active">
                <Switch onChange={onInspirationStatusChange} defaultChecked={!!record.enabled} />
              </Tooltip>
            </div>
          );
        },
      },
    ];
  }, [payload.page, pagination.perPage]);

  useEffect(() => {
    dispatch(setTableLoading(true));
    const promise = dispatch(getAllInspirationsAction({ ...payload }));
    void promise.then(() => {
      setPagination({
        totalItems: totalInspirations,
        perPage: limitPerPage,
      });
      dispatch(setTableLoading(false));
    });
    return () => {
      promise.abort();
    };
  }, [payload.limit, payload.page, limitPerPage, totalInspirations]);

  return (
    <InspirationManagementSection
      currentPage={payload.page}
      columns={columns}
      dataSource={inspirationsTableData}
      pagination={pagination}
      onChangePageSize={handleChangePageSize}
      onPageChange={handleChangePage}
      handleNavigateCreateInspiration={handleNavigateCreateInspiration}
    />
  );
};
