import { APP_COLORS } from '@theme';
import styled from 'styled-components';

interface DetailsListItemProps extends React.ComponentPropsWithRef<'li'> {
  label: React.ReactNode;
  content?: React.ReactNode | null;
  placeholder?: string;
}

export const DetailsListItem: React.FC<DetailsListItemProps> = ({
  label,
  content,
  placeholder = 'No data',
  ...rest
}) => {
  return (
    <DetailsListItemStyled {...rest}>
      <LabelStyled>{label}</LabelStyled>
      {content && <ContentStyled>{content}</ContentStyled>}
      {(content === null || content === undefined || content === false) && (
        <PlaceHolderTextStyled>{placeholder}</PlaceHolderTextStyled>
      )}
    </DetailsListItemStyled>
  );
};

const DetailsListItemStyled = styled.li`
  width: 100%;
  display: grid;
  grid-template-columns: 18rem 1fr;
  column-gap: 6.4rem;
  justify-content: center;
  align-content: center;
  font-size: 1.6rem;
  color: ${(p: any) => p?.theme.colors?.text ?? APP_COLORS.gray900};
`;

const LabelStyled = styled.span`
  justify-self: end;
`;

const ContentStyled = styled.span`
  justify-self: start;
`;

const PlaceHolderTextStyled = styled.span`
  color: ${APP_COLORS.gray400};
`;
