import { StyledPage } from '@components';
import { BreadcrumbsModule, InspirationCreatePersonalizationModule } from '@modules';

export const InspirationCreatePersonalizationPage: React.FC = () => {
  return (
    <StyledPage>
      <BreadcrumbsModule />
      <InspirationCreatePersonalizationModule />
    </StyledPage>
  );
};
