import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ILoginResponse, IOwnerInfo } from '@interfaces';
import { RootState } from '.';
import { adminChangePasswordAction, adminLoginAction, adminLogoutAction } from '../actions/auth';

interface IAuth {
  accessToken?: string;
  accountInfo?: IOwnerInfo;
}

const initialState: IAuth = {
  accessToken: undefined,
  accountInfo: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    resetAuth: () => initialState,
    setAccountInfo: (state, action: PayloadAction<IOwnerInfo>) => {
      state.accountInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      adminLoginAction.fulfilled,
      (state, action: PayloadAction<ILoginResponse | undefined>) => {
        if (action.payload) {
          state.accessToken = action.payload.token.accessToken;
          state.accountInfo = {
            id: action.payload.id,
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            email: action.payload.email,
          };
        }
      },
    );
    builder.addCase(adminLogoutAction.fulfilled, () => initialState);
  },
});

export const { resetAuth, setAccountInfo } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
