import { IInspirationFields } from '@interfaces';
import { BaseSyntheticEvent } from 'react';
import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';
import { ShareAreaInput, ShareInput } from 'src/components/shared';
import styled from 'styled-components';

interface IProps {
  isViewPage: boolean;
  register: UseFormRegister<FieldValues>;
  errors: Partial<FieldErrorsImpl<any>>;
  handleSave: (e?: BaseSyntheticEvent<IInspirationFields, any, any> | undefined) => Promise<void>;
}

export const InspirationInfoForm: React.FC<IProps> = (props) => {
  const { isViewPage, errors, register, handleSave } = props;

  return (
    <>
      {/* @ts-ignore */}
      <FormStyled onSubmit={handleSave} id="inspiration-form">
        <ShareInput
          name="title"
          label="Title"
          required={!isViewPage}
          className="input"
          errors={errors['title']?.message}
          register={register}
          disabled={isViewPage}
        />
        <ShareInput
          name="price"
          label="Price"
          required={!isViewPage}
          className="input"
          errors={errors['price']?.message}
          register={register}
          disabled={isViewPage}
          suffix="€"
          inputDefaultStyle="postTab"
          type="number"
          onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
        />
        <ShareAreaInput
          name="description"
          label="Description"
          inputClassName="textarea"
          errors={errors['description']?.message}
          register={register}
          disabled={isViewPage}
          required={!isViewPage}
          maxLength={256}
        />
      </FormStyled>
    </>
  );
};

const FormStyled = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  .input__label {
    font-size: 1.6rem;
  }
  .input {
    font-size: 1.6rem;
  }
  .textarea {
    min-height: 12rem;
    max-height: 32rem;
    font-size: 1.6rem;
  }
`;
