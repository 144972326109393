import { APP_COLORS } from '@theme';
import classNames from 'classnames';
import React from 'react';
import { SharedIcon } from 'src/components/Icon';
import styled from 'styled-components';

interface IProps extends React.ComponentPropsWithRef<'input'> {
  children?: React.ReactNode;
}

export const PropertyCheckbox = React.forwardRef<HTMLInputElement, IProps>(
  ({ children, className, ...rest }, ref) => {
    return (
      <CheckboxWrapper>
        <input type="checkbox" ref={ref} {...rest} />
        <div className={classNames('property-checkbox', className)}>
          <span className="property-name">{children}</span>
          <span className="check-icon">
            <SharedIcon name="check" size={16} />
          </span>
        </div>
      </CheckboxWrapper>
    );
  }
);

PropertyCheckbox.displayName = 'PropertyCheckbox';

const CheckboxWrapper = styled.label`
  display: inline-block;
  cursor: pointer;
  input[type='checkbox'] {
    display: none;
    &:not(:checked) + .property-checkbox:hover {
      color: ${APP_COLORS.primary};
      border-color: ${APP_COLORS.primary};
    }
    &:checked + .property-checkbox:hover {
      opacity: 0.76;
      box-shadow: rgba(213, 101, 68, 0.2) 0.2rem 0.4rem 0.4rem;
    }
    &:not(:checked) + .property-checkbox > .check-icon {
      display: none;
    }
    &:checked + .property-checkbox {
      border: 0.1rem solid ${APP_COLORS.primary};
      background-color: ${APP_COLORS.primary};
      color: ${APP_COLORS.white};
    }
    &:disabled + .property-checkbox {
      cursor: not-allowed;
      background-color: ${APP_COLORS.backgroundDark};
      color: ${APP_COLORS.gray};
      &:hover {
        border-color: ${APP_COLORS.normal};
        color: ${APP_COLORS.gray};
      }
    }
  }
  .property-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    padding: 0.8rem 1.2rem;
    border: 0.1rem solid ${APP_COLORS.normal};
    border-radius: 100rem;
    transition: all 0.2s ease-in-out;
    & > .property-name {
      font-size: 1.6rem;
      text-transform: capitalize;
    }
  }
`;
