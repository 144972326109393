import styled from 'styled-components';
import { SectionCreateBasicStyled } from './styled';
import { PatternItemmRadio } from './widgets';
import { IPatternAPI } from '@interfaces';

interface IProps {
  patterns?: IPatternAPI[];
  selectedPattern?: number;
  onSelectedPatternChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InspirationPatternPrincipleSection: React.FC<IProps> = ({
  patterns,
  selectedPattern,
  onSelectedPatternChange,
}) => {
  return (
    <SectionCreateBasicStyled>
      <ContainerStyled>
        {patterns &&
          patterns.map((pattern) => (
            <PatternItemmRadio
              key={pattern.id}
              name="pattern-principle"
              value={pattern.id}
              imageSrc={pattern.imageUrl}
              onChange={onSelectedPatternChange}
              checked={pattern.id === selectedPattern}
            >
              {pattern.pattern}
            </PatternItemmRadio>
          ))}
      </ContainerStyled>
    </SectionCreateBasicStyled>
  );
};

const ContainerStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  row-gap: 2.4rem;
  column-gap: 1.6rem;
`;
