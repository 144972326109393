import { APP_COLORS } from '@theme';
import styled from 'styled-components';

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export const DetailsItemHeading: React.FC<HeadingProps> = ({ level = 'h1', children, ...rest }) => {
  const Heading = level as keyof JSX.IntrinsicElements;

  return (
    <DetailsItemHeadingStyled as={Heading} {...rest}>
      <DecoratedLine />
      <span>{children}</span>
    </DetailsItemHeadingStyled>
  );
};

const DetailsItemHeadingStyled = styled.div`
  font-size: 2rem;
  font-family: 'Gotham', sans-serif;
  display: flex;
  align-items: center;
  gap: 1.6rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${(p: any) => p?.theme.colors?.text ?? APP_COLORS.gray900};
`;

const DecoratedLine = styled.div`
  width: 3.6rem;
  height: 0.2rem;
  border-radius: 0.2rem;
  background-color: ${(p: any) => p?.theme.colors?.text ?? APP_COLORS.gray900};
`;
