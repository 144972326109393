import { StyledPage } from '@components';
import { BreadcrumbsModule, TailorRegistrationDetailsModule } from '@modules';

export const TailorRegistrationDetailsPage: React.FC = () => {
  return (
    <StyledPage>
      <BreadcrumbsModule />
      <TailorRegistrationDetailsModule />
    </StyledPage>
  );
};
