import styled from 'styled-components';
import { ColumnsType } from 'antd/lib/table';

import { Pagination, SharedTable, StyledBottomPagination, StyledSection } from '@components';
import { CustomerDataType } from '@modules';

interface IProps {
  columns: ColumnsType<CustomerDataType>;
  dataSource?: CustomerDataType[];
  currentPage: number;
  pagination: {
    totalItems: number;
    perPage: number;
  };
  onChangePageSize: (size: number) => void;
  onPageChange: (size: number) => void;
}

export const CustomerManagementSection = (props: IProps) => {
  const { currentPage, pagination, onPageChange, onChangePageSize, columns, dataSource } = props;

  return (
    <StyledSection>
      <SharedTable columns={columns} dataSource={dataSource} />
      <StyledBottomTable>
        <span className="total-count">
          Total count: <span>{pagination.totalItems}</span>
        </span>
        <StyledBottomPagination>
          <Pagination
            className="pt-2 pagination"
            currentPage={currentPage}
            totalCount={pagination.totalItems}
            pageSize={pagination.perPage}
            onChangePageSize={onChangePageSize}
            onPageChange={onPageChange}
          />
        </StyledBottomPagination>
      </StyledBottomTable>
    </StyledSection>
  );
};

const StyledBottomTable = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;
  & > .total-count {
    font-size: 1.6rem;
  }
`;
