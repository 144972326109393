import { MotifCustomization } from '@components';
import { IPersonalizationItem } from '@interfaces';
import {
  inspirationSelect,
  updatePersonalizationItem,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import React, { useState } from 'react';

interface IProps {
  personalizationItem: IPersonalizationItem;
}

export const MotifCustomizationModule: React.FC<IProps> = ({ personalizationItem }) => {
  const dispatch = useAppDispatch();
  const {
    listPatterns,
    createInspirationForm: { patternId },
  } = useAppSelector(inspirationSelect);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const onOpenModalClick = (): void => {
    setOpenModal(true);
  };

  const onModalCancel = (): void => {
    setOpenModal(false);
  };

  const onSelectedPatternRadioChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(updatePersonalizationItem({ ...personalizationItem, patternId: +e.target.value }));
    onModalCancel();
  };

  const handleResetMotif = (): void => {
    if (patternId === undefined) return;
    dispatch(updatePersonalizationItem({ ...personalizationItem, patternId }));
  };

  return (
    <MotifCustomization
      openModal={openModal}
      onOpenModalClick={onOpenModalClick}
      onModalCancel={onModalCancel}
      patterns={listPatterns}
      personalizationItem={personalizationItem}
      onSelectedPatternRadioChange={onSelectedPatternRadioChange}
      handleResetMotif={handleResetMotif}
    />
  );
};
