import './index.css';
import 'antd/dist/antd.min.css';
import 'antd/dist/antd.variable.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { setToLS } from './utils/storage';
import { ALL_THEMES } from './configs/constants';
import { themes } from '@theme';
import './ReactotronConfig';
import { ErrorBoundary } from './components/ErrorBoundary ';

const Index = () => {
  setToLS(ALL_THEMES, themes);
  return (
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
