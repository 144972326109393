import { PATH_CREATE_INSPIRATION_BASIC, PATH_INSPIRATION } from '@configs';
import { IUploadInspirationPicture, IUserImage } from '@interfaces';
import { InspirationCreatePersonalizationSection } from '@pages';
import {
  addInspirationAction,
  addPersonalizationAction,
  basicCreationAction,
  inspirationSelect,
  resetCreateInspiration,
  selectApp,
  setLoading,
  uploadToAmazonAction,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { LogApp, showAppToast } from '@utils';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const InspirationCreatePersonalizationModule: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openElementModal, setOpenElementModal] = useState<boolean>(false);
  const { loading } = useAppSelector(selectApp);
  const {
    createInspirationForm: {
      title,
      description,
      indicativePrice,
      genderId,
      categoryId,
      subcategoryId,
      fabricId,
      properties,
      patternId,
      onHomepage,
      rangeHexCode,
      tintHexCode,
      inspirationPictures,
      personalizationItems,
    },
  } = useAppSelector(inspirationSelect);

  const handleOpenElementModal = (): void => {
    setOpenElementModal(true);
  };

  const handleCloseElementModal = (): void => {
    setOpenElementModal(false);
  };

  const handleReturn = (): void => {
    navigate(PATH_CREATE_INSPIRATION_BASIC);
  };

  const handleCreateInspiration = async (): Promise<void> => {
    if (
      genderId === undefined ||
      categoryId === undefined ||
      subcategoryId === undefined ||
      fabricId === undefined ||
      patternId === undefined ||
      indicativePrice === undefined ||
      title === undefined ||
      description === undefined ||
      tintHexCode === undefined ||
      rangeHexCode === undefined
    )
      return;

    if (personalizationItems.length > 0) {
      const isAllInspirationPhotoUploaded = personalizationItems.every(
        (item) => item.selectedImage.length === 1,
      );
      const isAllInspirationHaveProperties = personalizationItems.every(
        (item) => item.properties.length > 0,
      );
      if (!isAllInspirationPhotoUploaded) {
        showAppToast(
          'Please upload personalization photo',
          'error',
          'light',
          'personalization-upload-image',
        );
        return;
      }
      if (!isAllInspirationHaveProperties) {
        showAppToast(
          'Please select at least 1 property',
          'error',
          'light',
          'personalization-properties',
        );
        return;
      }
    }
    try {
      dispatch(setLoading(true));
      const inspirationImages = inspirationPictures.filter(
        (picture) => picture.mediaId !== undefined,
      );
      const userImage: IUserImage[] = inspirationPictures
        .filter((img) => img.url !== undefined)
        .map((img) => ({
          imageUrl: img.url,
          imageType: 'clothes',
        }));
      const propertyIds = properties.map((p) => p.id);
      await dispatch(
        addInspirationAction({
          genderId,
          categoryId,
          subcategoryId,
          fabricId,
          patternId,
          propertyIds,
          inspirationPictures: inspirationImages,
          indicativePrice,
          rangeHexCode,
          tintHexCode,
          onHomepage,
          enabled: true,
          title,
          description,
        }),
      )
        .unwrap()
        .then(async ({ id }) => {
          await dispatch(
            basicCreationAction({
              userImage,
              genderId,
              categoryId,
              subCategoryId: subcategoryId,
              patternId,
              rangeHexCode,
              tintHexCode,
              fabricId,
              propertyId: propertyIds,
              inspirationId: id,
            }),
          )
            .unwrap()
            .then(async ({ id }) => {
              if (personalizationItems.length > 0) {
                for await (const personalizationItem of personalizationItems) {
                  const userImage: IUserImage[] = personalizationItem.selectedImage.map(
                    (image) => ({
                      imageUrl: image.imageUrl,
                      imageType: image.imageType,
                    }),
                  );
                  await dispatch(
                    addPersonalizationAction({
                      clothesId: id,
                      rangeHexCode: personalizationItem.rangeHexCode,
                      tintHexCode: personalizationItem.tintHexCode,
                      elementId: personalizationItem.elementId,
                      fabricId: personalizationItem.fabricId,
                      patternId: personalizationItem.patternId,
                      propertyId: personalizationItem.properties.map((p) => p.id),
                      userImage,
                    }),
                  );
                }
              }
            })
            .then(() => {
              showAppToast('Inspiration created successfully', 'success', 'light');
              dispatch(resetCreateInspiration());
              navigate(PATH_INSPIRATION);
            });
        });
      dispatch(setLoading(false));
    } catch (error: any) {
      LogApp('Create Inspiration Failed', error);
      dispatch(setLoading(false));
    }
  };

  return (
    <InspirationCreatePersonalizationSection
      personalizationItems={personalizationItems}
      openElementModal={openElementModal}
      handleOpenElementModal={handleOpenElementModal}
      handleCloseElementModal={handleCloseElementModal}
      handleReturn={handleReturn}
      handleCreateInspiration={handleCreateInspiration}
      loading={loading}
    />
  );
};
