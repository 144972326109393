import {
  IAddPersonalizationParams,
  IBasicCreationParams,
  IGetElementsParams,
  IGetUsersParams,
} from '@interfaces';
import axiosClient from './axiosClient';

export const customerAPI = {
  getAllCustomer: (params: IGetUsersParams) => {
    const url = '/api/admin/users';
    return axiosClient.get(url, { params });
  },
  deleteCustomer: (id: number) => {
    const url = `/api/admin/user/${id}`;
    return axiosClient.delete(url);
  },
  basicCreation: (params: IBasicCreationParams) => {
    const url = '/api/users/basic-creation';
    return axiosClient.post(url, params);
  },
  getElements: (params: IGetElementsParams) => {
    const url = '/api/users/element';
    return axiosClient.get(url, { params });
  },
  addPersonalization: (params: IAddPersonalizationParams) => {
    const url = '/api/users/personalization';
    return axiosClient.post(url, params);
  },
};
