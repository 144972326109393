import React, { useEffect, useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import { ColumnsType } from 'antd/lib/table';

import { IGetListParams } from '@interfaces';
import {
  customersTableSelector,
  deleteCustomerAction,
  getAllCustomersAction,
  selectCustomer,
  setTableLoading,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { CustomerManagementSection } from '@pages';
import { IconBtn, SharedIcon } from '@components';
import { APP_COLORS } from '@theme';
import { showAppToast } from '@utils';

export interface CustomerDataType {
  key: number;
  name: string;
  email: string | null;
  address: string | undefined;
}

export const CustomerManagementModule: React.FC = () => {
  const dispatch = useAppDispatch();
  const { limitPerPage, totalCustomers } = useAppSelector(selectCustomer);
  const customersTableData = useAppSelector(customersTableSelector);

  const [pagination, setPagination] = useState({
    totalItems: 0,
    perPage: 10,
  });

  const [payload, setPayload] = useState<IGetListParams>({
    page: 1,
    limit: 10,
  });

  const handleChangePage = (page: number) => {
    setPayload({ ...payload, page });
  };

  const handleChangePageSize = (size: number): void => {
    setPayload({ ...payload, limit: size, page: 1 });
  };

  const handleDeleteCustomer = (id: number): void => {
    Swal.fire({
      title: 'Delete customer',
      text: 'Do you want to delete that Customer?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: APP_COLORS.primary,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      scrollbarPadding: false,
    }).then((result) => {
      if (result.isConfirmed) {
        void dispatch(deleteCustomerAction({ id, ...payload }))
          .unwrap()
          .then(({ success }) => {
            if (success) {
              showAppToast('Deleted customer successfully', 'success', 'light');
            }
          });
      }
    });
  };

  const columns = useMemo<ColumnsType<CustomerDataType>>(() => {
    return [
      {
        title: 'No',
        key: 'no',
        render: (_value, _record, index) => (payload.page - 1) * pagination.perPage + index + 1,
        width: 85,
      },
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: 'Email',
        key: 'email',
        dataIndex: 'email',
      },
      {
        title: 'Address',
        key: 'address',
        dataIndex: 'address',
      },
      {
        title: 'Action',
        key: 'action',
        width: 125,
        render: (_value, record) => {
          return (
            <div className="flex items-center justify-center">
              <IconBtn
                $bordered
                $borderColor={APP_COLORS.red500}
                onClick={() => handleDeleteCustomer(record.key)}
              >
                <SharedIcon name="trash" size={18} color={APP_COLORS.red500} />
              </IconBtn>
            </div>
          );
        },
      },
    ];
  }, [payload.page, pagination.perPage]);

  useEffect(() => {
    dispatch(setTableLoading(true));
    const promise = dispatch(getAllCustomersAction({ ...payload }));
    void promise
      .then(() => {
        setPagination({
          totalItems: totalCustomers,
          perPage: limitPerPage,
        });
        dispatch(setTableLoading(false));
      })
      .catch(() => {
        dispatch(setTableLoading(false));
      });

    return () => {
      promise.abort();
    };
  }, [payload.limit, payload.page, totalCustomers, limitPerPage]);

  return (
    <CustomerManagementSection
      currentPage={payload.page}
      columns={columns}
      dataSource={customersTableData}
      pagination={pagination}
      onChangePageSize={handleChangePageSize}
      onPageChange={handleChangePage}
    />
  );
};
