import { StyledPage } from '@components';
import { BreadcrumbsModule, PromotionManagementModule } from '@modules';
import React from 'react';

export const PromotionManagementPage: React.FC = () => {
  return (
    <StyledPage>
      <BreadcrumbsModule />
      <PromotionManagementModule />
    </StyledPage>
  );
};
