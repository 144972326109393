import { IInspirationPicture } from '@interfaces';
import { APP_COLORS } from '@theme';
import { Skeleton } from 'antd';
import { AppImage } from 'src/components/shared';
import styled, { css } from 'styled-components';

interface IProps {
  images?: IInspirationPicture[] | null;
}

export const InspirationMasonary: React.FC<IProps> = ({ images }) => {
  if (!images || images.length < 1) {
    return (
      <ContainerStyled $imageCount={3}>
        {new Array(3).fill(0).map((_, index) => (
          <ImageFigure key={index} className={`figure-item-${index + 1}`}>
            <Skeleton.Image style={{ width: '100%', height: '100%' }} />
          </ImageFigure>
        ))}
      </ContainerStyled>
    );
  }

  return (
    <ContainerStyled $imageCount={images.length}>
      {images.map((item) => (
        <ImageFigure key={item.id} className={`figure-item-${item.imagePosition}`}>
          <AppImage className="inspiration-img" firebaseResourceKey={item.pictureUrl} />
        </ImageFigure>
      ))}
    </ContainerStyled>
  );
};

const ContainerStyled = styled.div<{ $imageCount: number }>`
  display: grid;
  ${(p) =>
    p.$imageCount === 3 &&
    css`
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 24rem);
      row-gap: 1.6rem;
      column-gap: 3.2rem;
      & > .figure-item {
        &-1 {
          grid-row: 1 / -1;
        }
      }
    `}
  ${(p) =>
    p.$imageCount === 2 &&
    css`
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 48rem;
      column-gap: 3.2rem;
    `}
${(p) =>
    p.$imageCount === 1 &&
    css`
      grid-template-columns: 1fr;
      grid-template-rows: 48rem;
    `}
`;

const ImageFigure = styled.figure`
  margin: 0;
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  & .inspiration-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
  }
  & > .ant-skeleton-element {
    width: 100%;
    height: 100%;
  }
`;
