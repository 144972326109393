import styled from 'styled-components';

interface TextStyleProps {
  fontSize?: string;
  textColor?: string;
  fontWeight?: number;
  pointer?: boolean;
}

export const StyledLoginSection = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  .heading {
    margin-bottom: 3.6rem;
    .heading__title {
      text-align: center;
      font-size: 3.2rem;
      font-weight: 700;
      margin-bottom: 0.8rem;
      color: ${(p) => p.theme.colors.text};
    }
    .desc {
      font-size: 1.6rem;
      font-weight: 400;
      color: ${(p) => p.theme.colors.subText};
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    .login-btn {
      width: 20rem;
      height: 5.2rem;
      font-size: 3.2rem;
    }
  }

  .login-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    .input {
      font-size: 1.6rem;
      font-family: 'Gotham', sans-serif;
    }
    .shared-input {
      margin-bottom: 0.5rem;
      .inner-input {
        height: 4.6rem;
      }
    }
    .submit__btn {
      font-size: 1.6rem;
    }
    .forgot-text {
      font-size: 1.6rem;
      font-weight: 400;
      align-self: flex-end;
    }
    .inner-input {
      width: 100%;
      height: 4.6rem;
    }
  }
`;
