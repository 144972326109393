import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { FieldData } from 'rc-field-form/es/interface';
import { Form, SelectProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';

import { DEBOUNCE_DELAY, PATH_PROMOTION } from '@configs';
import {
  IDiscountType,
  IGetListParams,
  IPromotionBody,
  IPromotionFormValues,
  IUpdatePromotionBody,
} from '@interfaces';
import { PromotionViewAddAndEditSection } from '@pages';
import {
  addPromotionAction,
  getAllCustomersAction,
  getPromotionAction,
  updatePromotionAction,
  useAppDispatch,
} from '@redux';
import { UserOptionLabel } from '@components';
import { LogApp } from '@utils';

const initialFormValues: FieldData[] = [
  { name: 'promoTitle', value: undefined },
  { name: 'discount', value: undefined },
  { name: 'expiryDate', value: undefined },
  { name: 'customer', value: null },
  { name: 'allCustomers', value: true },
];

export const PromotionViewAddAndEditModule: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const isViewPage = pathname.split('/').includes('details');
  const isCreatePage = pathname.split('/').includes('create');
  const isEditPage = pathname.split('/').includes('update');

  // Discount type
  const [discountType, setDiscountType] = useState<IDiscountType>('percentage');

  // Control user select component
  const [customersFetching, setCustomersFetching] = useState<boolean>(false);
  const [customerOptions, setCustomerOptions] = useState<SelectProps['options']>([]);
  const [loadMoreCustomers, setLoadMoreCustomers] = useState<boolean>(true);
  const [checkPromoTitle, setCheckPromoTitle] = useState<string | undefined>('');
  const [payload, setPayload] = useState<IGetListParams>({
    page: 1,
    limit: 10,
  });

  // Form field data
  const [form] = Form.useForm<IPromotionFormValues>();

  const onSelectDiscountTypeChange = (value: IDiscountType): void => {
    setDiscountType(value);
    if (form.getFieldValue('discount')) {
      form.validateFields(['discount']);
    }
  };

  const loadMoreCustomerOptions = async (): Promise<void> => {
    if (loadMoreCustomers) {
      setCustomersFetching(true);
      await dispatch(
        getAllCustomersAction({
          page: payload.page,
          search: payload.search,
          limit: 10,
        }),
      )
        .unwrap()
        .then(({ items }) => {
          if (payload.limit && items.length < payload.limit) {
            setLoadMoreCustomers(false);
          }
          const newOptions: SelectProps['options'] = items.map((item) => ({
            label: (
              <UserOptionLabel
                firstName={item.firstName}
                lastName={item.lastName}
                email={item.email}
              />
            ),
            value: item.id,
          }));
          setCustomerOptions((options) => {
            if (payload.search) {
              return newOptions;
            }
            return options
              ?.concat(newOptions)
              .filter(
                (option, index, originalOptions) =>
                  originalOptions.findIndex((opt) => opt.value === option.value) === index,
              );
          });
        });
      setCustomersFetching(false);
    }
  };

  const onUserSelectFocus = useCallback(() => {
    if (customerOptions?.length === 0) {
      setLoadMoreCustomers(true);
      setPayload({ ...payload, page: 1, search: undefined });
    }
  }, [customerOptions]);

  const onUserSearch = debounce((value: string): void => {
    const search = value.trim();
    setLoadMoreCustomers(true);
    if (search) {
      setPayload({ ...payload, page: 1, search });
      return;
    }
    setPayload({ ...payload, page: 1, search: undefined });
  }, DEBOUNCE_DELAY);

  const onCustomerPopupScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>): void => {
    const { currentTarget } = e;
    const isEndOfList =
      currentTarget.scrollHeight - currentTarget.scrollTop ===
      currentTarget.getBoundingClientRect().height;
    if (!customersFetching && isEndOfList) {
      setPayload({ ...payload, page: payload.page + 1, search: undefined });
    }
  };

  const onSelectCustomerChange = (): void => {
    form.setFieldValue('allCustomers', false);
  };

  const onSelectAllCustomerChange = (e: CheckboxChangeEvent): void => {
    if (e.target.checked) {
      form.setFieldValue('customer', { label: 'All', value: null });
    } else {
      form.setFieldValue('customer', undefined);
    }
    form.validateFields(['customer']);
  };

  const onClearCustomerSelected = (): void => {
    form.setFieldValue('customer', { label: 'All', value: null });
    form.setFieldValue('allCustomers', true);
    form.validateFields(['customer']);
  };

  const handleSubmit = async (values: IPromotionFormValues): Promise<void> => {
    try {
      if (isCreatePage) {
        const addPromotionBody: IPromotionBody = {
          title: values.promoTitle,
          value: +values.discount,
          valueType: discountType,
          expiryDate: moment(values.expiryDate).format('YYYY-MM-DD'),
          userId: values.allCustomers ? null : values.customer ? values.customer.value : null,
        };
        await dispatch(addPromotionAction(addPromotionBody))
          .unwrap()
          .then(({ success }) => {
            if (success) navigate(PATH_PROMOTION);
          });
      }
      if (isEditPage && id) {
        const updatePromotionBody: IUpdatePromotionBody = {
          id: +id,
          ...(values?.promoTitle !== checkPromoTitle && { title: values.promoTitle }),
          value: +values.discount,
          valueType: discountType,
          expiryDate: moment(values.expiryDate).format('YYYY-MM-DD'),
          userId: values.customer?.value || null,
          isGlobal: values.allCustomers,
        };
        await dispatch(updatePromotionAction(updatePromotionBody))
          .unwrap()
          .then(({ success }) => {
            if (success) navigate(PATH_PROMOTION);
          });
      }
    } catch (error: any) {
      LogApp('Add promotion error 💥', error);
    }
  };

  const handleCancel = (): void => {
    navigate(PATH_PROMOTION);
  };

  useEffect(() => {
    void loadMoreCustomerOptions();
  }, [payload.page, payload.search]);

  useEffect(() => {
    if (!isCreatePage && id) {
      void dispatch(getPromotionAction(+id))
        .unwrap()
        .then((promo) => {
          setDiscountType(promo.valueType);
          setCheckPromoTitle(promo.title);
          form.setFieldsValue({
            promoTitle: promo.title,
            discount: promo.value.toString(),
            expiryDate: moment(promo.expiryDate, 'YYYY-MM-DD'),
            allCustomers: promo.userId === null,
            customer:
              promo.userId !== null
                ? {
                    label: (
                      <UserOptionLabel
                        firstName={promo.user?.firstName}
                        lastName={promo.user?.lastName}
                        email={promo.user?.email}
                      />
                    ),
                    value: promo.userId,
                  }
                : {
                    label: 'All',
                    value: null,
                  },
          });
        });
    } else if (isCreatePage) {
      form.setFieldValue('customer', { label: 'All', value: null });
    }
  }, [isCreatePage, id]);

  return (
    <PromotionViewAddAndEditSection
      isViewPage={isViewPage}
      discountType={discountType}
      onSelectDiscountTypeChange={onSelectDiscountTypeChange}
      onUserSearch={onUserSearch}
      customersFetching={customersFetching}
      customerOptions={customerOptions}
      handleCancel={handleCancel}
      form={form}
      handleSubmit={handleSubmit}
      formFieldData={initialFormValues}
      onSelectCustomerChange={onSelectCustomerChange}
      onSelectAllCustomerChange={onSelectAllCustomerChange}
      onUserSelectFocus={onUserSelectFocus}
      onCustomerPopupScroll={onCustomerPopupScroll}
      onClearCustomerSelected={onClearCustomerSelected}
    />
  );
};
