export const APP_COLORS = {
  transparent: 'transparent',
  primary: '#D56544',
  primaryLight: '#f1faff',
  background: '#f8f8f8',
  backgroundDark: '#f0f0f0',
  dark: 'rgb(51,51,51)',
  info: '#1890ff',
  success: '#52c41a',
  green: '#05C755',
  green50: '#E8F5E9',
  green100: '#C8E6C9',
  green200: '#A5D6A7',
  green300: '#81C784',
  green400: '#66BB6A',
  green500: '#4CAF50',
  green600: '#43A047',
  green700: '#388E3C',
  green800: '#2E7D32',
  green900: '#1B5E20',
  lightGreen50: '#F1F8E9',
  lightGreen100: '#DCEDC8',
  lightGreen200: '#C5E1A5',
  lightGreen300: '#AED581',
  lightGreen400: '#9CCC65',
  lightGreen500: '#8BC34A',
  lightGreen600: '#7CB342',
  lightGreen700: '#689F38',
  lightGreen800: '#558B2F',
  lightGreen900: '#827717',
  blue: '#E3F2FD',
  blue50: '#E3F2FD',
  blue100: '#BBDEFB',
  blue200: '#90CAF9',
  blue300: '#64B5F6',
  blue400: '#42A5F5',
  blue500: '#2196F3',
  blue600: '#1E88E5',
  blue700: '#1976D2',
  blue800: '#1565C0',
  blue900: '#0D47A1',
  lightBlue50: '#E1F5FE',
  lightBlue100: '#B3E5FC',
  lightBlue200: '#81D4FA',
  lightBlue300: '#4FC3F7',
  lightBlue400: '#29B6F6',
  lightBlue500: '#03A9F4',
  lightBlue600: '#039BE5',
  lightBlue700: '#0288D1',
  lightBlue800: '#0277BD',
  lightBlue900: '#01579B',
  cyan50: '#E0F7FA',
  cyan100: '#B2EBF2',
  cyan200: '#80DEEA',
  cyan300: '#4DD0E1',
  cyan400: '#26C6DA',
  cyan500: '#03A9F4',
  cyan600: '#039BE5',
  cyan700: '#0097A7',
  cyan800: '#00838F',
  cyan900: '#006064',
  teal50: '#E0F2F1',
  teal100: '#B2DFDB',
  teal200: '#80CBC4',
  teal300: '#4DB6AC',
  teal400: '#26A69A',
  teal500: '#009688',
  teal600: '#00897B',
  teal700: '#00796B',
  teal800: '#00695C',
  teal900: '#004D40',
  yellow50: '#FFFDE7',
  yellow100: '#FFF9C4',
  yellow200: '#FFF59D',
  yellow300: '#FFF176',
  yellow400: '#FFEE58',
  yellow500: '#FFEB3B',
  yellow600: '#FDD835',
  yellow700: '#FBC02D',
  yellow800: '#F9A825',
  yellow900: '#F57F17',
  processing: '#1890ff',
  orange50: '#FFF3E0',
  orange100: '#FFE0B2',
  orange200: '#FFCC80',
  orange300: '#FFCC80',
  orange400: '#FFA726',
  orange500: '#FFA726',
  orange600: '#FB8C00',
  orange700: '#F57C00',
  orange800: '#EF6C00',
  orange900: '#E65100',
  orangeA100: '#FFD180',
  orangeA200: '#FFAB40',
  orangeA400: '#FF9100',
  orangeA700: '#FF6D00',
  red50: '#FFEBEE',
  red100: '#FFCDD2',
  red200: '#EF9A9A',
  red300: '#E57373',
  red400: '#EF5350',
  red500: '#F44336',
  red600: '#E53935',
  red700: '#D32F2F',
  red800: '#C62828',
  red900: '#B71C1C',
  redA100: '#FF8A80',
  redA200: '#FF5252',
  redA400: '#FF1744',
  redA700: '#D50000',
  error: '#f5222d',
  highlight: '#e09600',
  highlightOv: '#c28200',
  highlightRed: '#d0011b',
  warning: '#faad14',
  normal: '#d9d9d9',
  text: 'rgba(0, 0, 0, .65)',
  textSecondary: 'rgba(0,0,0,.45)',
  gray: '#808080',
  darkGray: '#C0C0C0',
  main: '#726FE7',
  red: '#F47477',
  cRed: '#D56544',
  cBlue: '#4099ff',
  white: '#fff',
  whiteD: '#fafafa',
  whiteM: '#f1f1f5',
  cyanL: '#f4fafd',
  cancel600: '#7e8299',
  textDanger: '#f1416c',
  gray100: '#F9F9F9',
  gray200: '#F4F4F4',
  gray250: '#eef0f3',
  gray300: '#E1E3EA',
  gray400: '#B5B5C3',
  gray500: '#A1A5B7',
  gray600: '#7E8299',
  gray700: '#5E6278',
  gray800: '#3F4254',
  gray900: '#181C32',
  cream: '#F7F0DE',
};
