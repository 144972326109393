import * as yup from 'yup';

export const InspirationSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .max(100, 'Title must not exceed 100 characters')
    .required('Title is required'),
  price: yup
    .string()
    .trim()
    .min(1, 'Price is required')
    .max(10, 'Price must not exceed 10 digits')
    .test(
      'Digit only',
      'Only integer number',
      (val) => typeof val === 'string' && /^\d+$/.test(val)
    ),
  description: yup
    .string()
    .trim()
    .max(255, 'Description must not exceed 255 characters')
    .required('Description is required'),
});
