import { Breadcrumbs } from '@components';
import { isNum } from '@utils';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

const breadcrumbNameMap: Record<string, string> = {
  '/tailors': 'Tailor Management',
  '/tailors/registration/details': 'View application',
  '/customers': 'Customers management',
  '/inspirations': 'Inspiration management',
  '/inspirations/details': 'View',
  '/inspirations/update': 'Edit',
  '/inspirations/create': 'Create inspiration',
  '/inspirations/create/basic': 'Basic creation',
  '/promotions': 'Promotion management',
  '/promotions/create': 'Add',
  '/promotions/details': 'View',
  '/promotions/update': 'Edit',
};

export const BreadcrumbsModule: React.FC = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const listPaths = currentPath
    .slice(1)
    .split('/')
    .filter((value) => !isNum(value)); //remove number in path

  const breadcrumbData = listPaths.reduce((prev: any, value: string, index: number) => {
    const path = prev[index - 1]?.path
      ? prev[index - 1].path.concat('/').concat(value)
      : '/' + value;
    return [...prev, { label: breadcrumbNameMap[path] ?? value, path: path }];
  }, []);
  return <Breadcrumbs data={breadcrumbData} />;
};
