import { PersonalizationItem } from '@components';
import { IPersonalizationItem } from '@interfaces';
import { removePersonalizationItem, updatePersonalizationItem, useAppDispatch } from '@redux';
import { showAppToast } from '@utils';
import { useState } from 'react';

interface IProps {
  item: IPersonalizationItem;
}

export const InspirationPersonalizationItemModule: React.FC<IProps> = ({ item }) => {
  const dispatch = useAppDispatch();
  const [openAdvance, setOpenAdvance] = useState<boolean>(false);

  const toggleOpenAdvanced = (): void => {
    setOpenAdvance((prev) => !prev);
  };

  const handleRemoveItem = (itemId: number): void => {
    dispatch(removePersonalizationItem(itemId));
  };

  const onUploadImageEnd = (data: any) => {
    dispatch(
      updatePersonalizationItem({
        ...item,
        selectedImage: [{ imageType: 'clothes', imageUrl: data?.url }],
      }),
    );
  };

  const onRemoveImage = (): void => {
    dispatch(updatePersonalizationItem({ ...item, selectedImage: [] }));
  };

  return (
    <PersonalizationItem
      personalizationItem={item}
      openAdvance={openAdvance}
      toggleOpenAdvance={toggleOpenAdvanced}
      handleRemoveItem={handleRemoveItem}
      onUploadEnd={onUploadImageEnd}
      onRemoveImage={onRemoveImage}
    />
  );
};
