import { Radio, RadioChangeEvent, Space } from 'antd';
import {
  ContainerStyled,
  RadioContainer,
  SectionCreateBasicStyled,
  SelectContainerStyled,
} from './styled';
import { IColorRangeAPI, ITintAPI } from '@interfaces';
import styled from 'styled-components';
import { SharedIcon } from 'src/components/Icon';
import { APP_COLORS } from '@theme';
import { ColorBox } from 'src/components/styles';

interface IProps {
  colors?: IColorRangeAPI[];
  tints?: ITintAPI[];
  onSelectedColorChange: (e: RadioChangeEvent) => Promise<void>;
  onSelectedTintChange: (e: RadioChangeEvent) => void;
  selectedColor?: string;
  selectedTint?: string;
}

export const InspirationColorPrincipleSection: React.FC<IProps> = ({
  colors,
  tints,
  onSelectedColorChange,
  onSelectedTintChange,
  selectedColor,
  selectedTint,
}) => {
  return (
    <SectionCreateBasicStyled>
      <ContainerStyled>
        <SelectContainerStyled>
          <h2>Color range</h2>
          {colors && (
            <RadioContainer>
              <Radio.Group
                buttonStyle="solid"
                size="large"
                value={selectedColor}
                onChange={onSelectedColorChange}
              >
                <Space direction="vertical">
                  {colors.map((color) => (
                    <Radio.Button
                      className="radio-btn-color"
                      style={radioBtnStyles}
                      key={color.range_hex_code}
                      value={color.range_hex_code}
                    >
                      <span>{color.colorRange}</span>
                      <span
                        className="color"
                        style={{
                          backgroundColor: color.range_hex_code,
                          ...(!!color &&
                            !!color?.linearGradientCode && {
                              backgroundImage: color.linearGradientCode,
                            }),
                        }}
                      >
                        <SharedIcon name="rightOutlined" size={18} />
                      </span>
                    </Radio.Button>
                  ))}
                </Space>
              </Radio.Group>
            </RadioContainer>
          )}
        </SelectContainerStyled>
        {colors && tints && (
          <SelectContainerStyled>
            <h2>Tint</h2>
            <RadioContainer>
              <Radio.Group
                buttonStyle="solid"
                size="large"
                value={selectedTint}
                onChange={onSelectedTintChange}
              >
                <Space direction="vertical" size="large">
                  {tints.map((tint) => (
                    <Radio
                      className="radio-color"
                      style={radioStyles}
                      key={tint.tintHexCode}
                      value={tint.tintHexCode}
                    >
                      <span>{tint.tint}</span>
                      <ColorBox
                        $color={tint.tintHexCode}
                        $linearGradient={tint?.linearGradientCode}
                      />
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </RadioContainer>
          </SelectContainerStyled>
        )}
      </ContainerStyled>
    </SectionCreateBasicStyled>
  );
};

const radioBtnStyles: React.CSSProperties = {
  minWidth: '24rem',
  borderRadius: '1.6rem',
  textTransform: 'capitalize',
};

const radioStyles: React.CSSProperties = {
  fontSize: '1.6rem',
  textTransform: 'capitalize',
};
