import React from 'react';
import { DebouncedFunc } from 'lodash';
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  SelectProps,
  Spin,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FieldData } from 'rc-field-form/es/interface';
import styled from 'styled-components';
import { FormInstance } from 'antd/es/form/Form';
import { Moment } from 'moment';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { StyledSection } from '@components';
import { IDiscountType, IPromotionFormValues } from '@interfaces';
import { notAllowedCharactersInNumber } from '@configs';

interface IProps {
  isViewPage: boolean;
  discountType: IDiscountType;
  onSelectDiscountTypeChange: (value: IDiscountType) => void;
  onUserSearch: DebouncedFunc<(value: string) => void>;
  customerOptions: SelectProps['options'];
  customersFetching: boolean;
  formFieldData: FieldData[];
  form: FormInstance;
  handleSubmit: (value: IPromotionFormValues) => Promise<void>;
  handleCancel: () => void;
  onSelectCustomerChange: () => void;
  onSelectAllCustomerChange: (e: CheckboxChangeEvent) => void;
  onUserSelectFocus: () => void;
  onCustomerPopupScroll: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  onClearCustomerSelected: () => void;
}

export const PromotionViewAddAndEditSection = React.memo<IProps>(
  ({
    isViewPage,
    discountType,
    onSelectDiscountTypeChange,
    onUserSearch,
    customerOptions,
    customersFetching,
    formFieldData,
    handleSubmit,
    handleCancel,
    form,
    onSelectCustomerChange,
    onSelectAllCustomerChange,
    onUserSelectFocus,
    onCustomerPopupScroll,
    onClearCustomerSelected,
  }) => {
    const customer = Form.useWatch('customer', form);
    return (
      <StyledSection>
        <WrapperStyled>
          <Form
            name="promotion"
            form={form}
            autoComplete="off"
            size="large"
            layout="vertical"
            disabled={isViewPage}
            fields={formFieldData}
            onFinish={handleSubmit}
          >
            <Form.Item
              label="Promo title"
              name="promoTitle"
              rules={[
                {
                  required: !isViewPage,
                  transform: (value) => value && value.trim(),
                  message: 'Please input promotion title',
                },
                { max: 100, message: 'Title must not exceed 100 characters' },
              ]}
              wrapperCol={{ span: 12 }}
            >
              <Input placeholder="Input promotion title" maxLength={101} />
            </Form.Item>
            <Form.Item
              label="Discount"
              name="discount"
              rules={[
                { required: !isViewPage, message: 'Please input discount value' },
                discountType === 'percentage'
                  ? {
                      validator: (_, value) => {
                        if (value && +value > 100) {
                          return Promise.reject();
                        }
                        return Promise.resolve();
                      },
                      message: 'Discount percentage must not exceed 100%',
                    }
                  : {
                      validator: (_, value) => {
                        if (value && value.toString().length > 10) {
                          return Promise.reject();
                        }
                        return Promise.resolve();
                      },
                      message: 'Discount value must not exceed 10 digits',
                    },
              ]}
              wrapperCol={{ span: 12 }}
            >
              <InputNumber
                addonBefore={
                  <Select
                    value={discountType}
                    onChange={onSelectDiscountTypeChange}
                    options={[
                      { value: 'percentage', label: 'Percentage' },
                      { value: 'amount', label: 'Amount' },
                    ]}
                  />
                }
                addonAfter={discountType === 'percentage' ? '%' : '€'}
                placeholder="Input discount"
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, '')}
                onKeyDown={(evt) =>
                  (notAllowedCharactersInNumber.split('').includes(evt.key) ||
                    ['Space'].includes(evt.code)) &&
                  evt.preventDefault()
                }
                precision={2}
                controls={false}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              label="Expire date"
              name="expiryDate"
              rules={[
                { required: !isViewPage, message: 'Please select expire date' },
                {
                  validator: (_, value: Moment) => {
                    if (value && value.isBefore()) {
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  },
                  message: 'Expiry date must be in the future',
                },
              ]}
            >
              <DatePicker
                style={{ width: 250 }}
                disabledDate={(currentDate) => currentDate.isBefore()}
              />
            </Form.Item>
            <Form.Item
              label="Select customer"
              name="customer"
              // rules={[
              //   ({ getFieldValue }) => ({
              //     validator: (_, value) => {
              //       const isCheckAll = getFieldValue('allCustomers');
              //       if (value || isCheckAll) {
              //         return Promise.resolve();
              //       }
              //       return Promise.reject();
              //     },
              //     required: !isViewPage,
              //     message: 'Please select 1 or all customers',
              //   }),
              // ]}
              wrapperCol={{ span: 12 }}
            >
              <Select
                allowClear
                labelInValue
                showSearch
                onChange={onSelectCustomerChange}
                onFocus={onUserSelectFocus}
                onSearch={onUserSearch}
                onPopupScroll={onCustomerPopupScroll}
                options={customerOptions}
                notFoundContent={null}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    {customersFetching ? (
                      <div className="flex items-center justify-center w-full">
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                      </div>
                    ) : null}
                  </>
                )}
                loading={customersFetching}
                filterOption={false}
                placeholder="Input customer name or email"
                className="user-select"
                // onClear={onClearCustomerSelected}
                onBlur={() => {
                  if (!customer) onClearCustomerSelected()
                }}
              />
            </Form.Item>
            <Form.Item name="allCustomers" valuePropName="checked" style={{ marginBottom: 0 }}>
              <Checkbox onChange={onSelectAllCustomerChange}>Select all customers</Checkbox>
            </Form.Item>
            <Form.Item style={{ marginTop: '4.8rem' }}>
              <ActionStyled>
                <Button disabled={false} size="large" shape="round" onClick={handleCancel}>
                  Cancel
                </Button>
                {!isViewPage && (
                  <Button size="large" shape="round" htmlType="submit" type="primary">
                    Save
                  </Button>
                )}
              </ActionStyled>
            </Form.Item>
          </Form>
        </WrapperStyled>
      </StyledSection>
    );
  },
);

const WrapperStyled = styled.div`
  width: 100%;
  padding: 2.4rem 4.8rem;

  label {
    &.ant-form-item-required {
      flex-direction: row-reverse;
      gap: 0.4rem;
    }
    font-size: 1.6rem;
  }

  .ant-input,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-picker {
    border-radius: 0.6rem;
  }

  .ant-input-group {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 0;
      border-top-left-radius: 0.6rem;
      border-bottom-left-radius: 0.6rem;
    }
    & > .ant-input-group-addon:first-child {
      border-top-left-radius: 0.6rem;
      border-bottom-left-radius: 0.6rem;
    }
    & > .ant-input-group-addon:last-child {
      border-top-right-radius: 0.6rem;
      border-bottom-right-radius: 0.6rem;
    }
  }

  .ant-input-number-group {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 0;
      border-top-left-radius: 0.6rem;
      border-bottom-left-radius: 0.6rem;
    }
    & > .ant-input-number-group-addon:first-child {
      border-top-left-radius: 0.6rem;
      border-bottom-left-radius: 0.6rem;
    }
    & > .ant-input-number-group-addon:last-child {
      border-top-right-radius: 0.6rem;
      border-bottom-right-radius: 0.6rem;
    }
  }
`;

const ActionStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.2rem;
`;
