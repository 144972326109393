import {
  IChangePasswordFields,
  ILoginFields,
  ILoginParams,
  ILogoutFields,
  IResetPasswordFields,
  ISendMailForgotFields,
  IVerifyEmailFields,
} from '@interfaces';
import axiosClient from './axiosClient';

export const authAPI = {
  login: (params: ILoginParams) => {
    const url = '/api/auth/sign-in';
    return axiosClient.post(
      url,
      {},
      {
        params,
        headers: {
          client: 'admin',
          method: 'email',
        },
      }
    );
  },
  forgotPassword: (values: ISendMailForgotFields) => {
    const url = '/api/auth/forgot-password';
    return axiosClient.post(url, values, {
      headers: {
        client: 'admin',
      },
    });
  },
  resetPassword: (values: IResetPasswordFields) => {
    const url = '/api/auth/reset-password';
    return axiosClient.post(url, values, {
      headers: {
        client: 'admin',
      },
    });
  },
  changePassword: (values: IChangePasswordFields) => {
    const url = '/api/auth/change-password';
    return axiosClient.post(url, values, {
      headers: {
        client: 'admin',
      },
    });
  },
  logout: () => {
    const url = '/api/auth/logout';
    return axiosClient.post(url);
  },
};
