import { PATH_REGISTRATION_TAILOR, enumTailorRegistrationStatus } from '@configs';
import { IGetListParams } from '@interfaces';
import { TailorRegistrationSection } from '@pages';
import {
  getAllTailorsAction,
  selectTailor,
  setTableLoading,
  tailorsRegistrationTableSelector,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export interface TailorRegistrationDataType {
  key: number;
  email: string;
  status: enumTailorRegistrationStatus;
}

const columns: ColumnsType<TailorRegistrationDataType> = [
  {
    title: 'No',
    key: 'no',
    render: (_value, _record, index) => index + 1,
    width: 85,
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email',
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (value: enumTailorRegistrationStatus) => {
      switch (value) {
        case enumTailorRegistrationStatus.APPROVED:
          return 'Approved';
        case enumTailorRegistrationStatus.REJECTED:
          return 'Rejected';
        default:
          return 'Waiting for approval';
      }
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (_value, record) => (
      <Link
        className="underline hover:underline underline-offset-4"
        to={`${PATH_REGISTRATION_TAILOR}/details/${record.key}`}
      >
        View application
      </Link>
    ),
  },
];

export const TailorRegistrationModule: React.FC = () => {
  const dispatch = useAppDispatch();

  const tailorsRegistrationTableData = useAppSelector(tailorsRegistrationTableSelector);
  const tailorSelect = useAppSelector(selectTailor);

  const [payload, setPayload] = useState<IGetListParams>({
    page: 1,
    limit: 10,
  });

  const [pagination, setPagination] = useState({
    totalItems: 0,
    perPage: 10,
  });

  const handleChangePage = (page: number) => {
    setPayload({ ...payload, page });
  };

  const handleChangePageSize = (size: number) => {
    setPayload({ ...payload, limit: size, page: 1 });
  };

  useEffect(() => {
    dispatch(setTableLoading(true));
    const promise = dispatch(getAllTailorsAction({ ...payload }));
    promise
      .then(() => {
        setPagination({
          totalItems: tailorSelect.totalTailors,
          perPage: tailorSelect.limitPerPage,
        });
        dispatch(setTableLoading(false));
      })
      .catch(() => {
        dispatch(setTableLoading(false));
      });

    return () => {
      promise.abort();
    };
  }, [payload.limit, payload.page, tailorSelect.totalTailors, tailorSelect.limitPerPage]);

  return (
    <TailorRegistrationSection
      currentPage={payload.page}
      columns={columns}
      dataSource={tailorsRegistrationTableData}
      pagination={pagination}
      onChangePageSize={handleChangePageSize}
      onPageChange={handleChangePage}
    />
  );
};
