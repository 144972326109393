import { MaterialCustomization } from '@components';
import { IPersonalizationItem } from '@interfaces';
import {
  inspirationSelect,
  updatePersonalizationItem,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import React, { useState } from 'react';

interface IProps {
  personalizationItem: IPersonalizationItem;
}

export const MaterialCustomizationModule: React.FC<IProps> = ({ personalizationItem }) => {
  const dispatch = useAppDispatch();
  const {
    listFabrics,
    createInspirationForm: { fabricId },
  } = useAppSelector(inspirationSelect);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const onOpenModalClick = (): void => {
    setOpenModal(true);
  };

  const onModalCancel = (): void => {
    setOpenModal(false);
  };

  const onSelectedMaterialRadioChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(updatePersonalizationItem({ ...personalizationItem, fabricId: +e.target.value }));
    onModalCancel();
  };

  const handleResetFabric = (): void => {
    if (fabricId === undefined) return;
    dispatch(updatePersonalizationItem({ ...personalizationItem, fabricId }));
  };

  return (
    <MaterialCustomization
      openModal={openModal}
      onOpenModalClick={onOpenModalClick}
      onModalCancel={onModalCancel}
      fabrics={listFabrics}
      personalizationItem={personalizationItem}
      onSelectedMaterialRadioChange={onSelectedMaterialRadioChange}
      handleResetFabric={handleResetFabric}
    />
  );
};
