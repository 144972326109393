import React, { useState } from 'react';
import _ from 'lodash';

import { PropertyCustomization } from '@components';
import {
  inspirationSelect,
  updatePersonalizationItem,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { IPersonalizationItem, IPropertyAPI } from '@interfaces';

interface IProps {
  personalizationItem: IPersonalizationItem;
}

export const PropertyCustomizationModule: React.FC<IProps> = ({ personalizationItem }) => {
  const dispatch = useAppDispatch();
  const {
    listProperties,
    createInspirationForm: { properties },
  } = useAppSelector(inspirationSelect);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedProperties, setSelectedProperties] =
    useState<Array<Omit<IPropertyAPI, 'family'>>>(properties);

  const groupedProperties = _.mapValues(_.groupBy(listProperties, 'family'), (properties) =>
    properties.map((property) => _.omit(property, ['family']))
  );

  const onOpenModalClick = (): void => {
    setOpenModal(true);
  };

  const onModalCancel = (): void => {
    setOpenModal(false);
    setSelectedProperties(personalizationItem.properties);
  };

  const handleResetProperties = (): void => {
    dispatch(updatePersonalizationItem({ ...personalizationItem, properties }));
    setSelectedProperties(properties);
  };

  const onSelectedPropertyChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    currentProperty: Omit<IPropertyAPI, 'family'>
  ): void => {
    if (e.target.checked) {
      setSelectedProperties([
        ...selectedProperties.filter((property) =>
          currentProperty.propertyCompatibility.includes(property.id)
        ),
        currentProperty,
      ]);
      return;
    }
    setSelectedProperties(
      selectedProperties.filter((property) => property.id !== currentProperty.id)
    );
  };

  const handleUpdateProperties = (): void => {
    dispatch(updatePersonalizationItem({ ...personalizationItem, properties: selectedProperties }));
    onModalCancel();
  };

  return (
    <PropertyCustomization
      openModal={openModal}
      onOpenModalClick={onOpenModalClick}
      onModalCancel={onModalCancel}
      groupedProperties={groupedProperties}
      personalizationItem={personalizationItem}
      handleResetProperties={handleResetProperties}
      selectedProperties={selectedProperties}
      onSelectedPropertyChange={onSelectedPropertyChange}
      handleUpdateProperties={handleUpdateProperties}
    />
  );
};
