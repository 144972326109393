import { StyledPage } from '@components';
import { BreadcrumbsModule, TailorManagementModule } from '@modules';

export const TailorManagementPage: React.FC = () => {
  return (
    <StyledPage>
      <BreadcrumbsModule />
      <TailorManagementModule />
    </StyledPage>
  );
};
