import { Result } from 'antd';
import React from 'react';

export class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }
  static getDerivedStateFromError(error: any) {
    // eslint-disable-next-line no-console
    console.log('App Check Error', error);
    return { hasError: true, error: error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // eslint-disable-next-line no-console
    console.log('App Error', error);
    // eslint-disable-next-line no-console
    console.log('App Error Info', errorInfo);
  }

  render() {
    if (this.state?.hasError) {
      return <Result status="500" title="8888" subTitle="Sorry, something went wrong." />;
    }
    return this.props.children;
  }
}
