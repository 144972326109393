import { APP_COLORS } from '@theme';
import React from 'react';
import { SharedIcon } from 'src/components/Icon';
import styled from 'styled-components';

interface IProps extends React.ComponentPropsWithRef<'input'> {
  children?: React.ReactNode;
}

export const MaterialRadio = React.forwardRef<HTMLInputElement, IProps>(
  ({ children, ...rest }, ref) => {
    return (
      <MaterialRadioStyled>
        <input type="radio" ref={ref} {...rest} />
        <div className="radio-wrapper">
          <span className="radio-content">{children}</span>
          <span className="check-icon">
            <SharedIcon name="check" size={18} />
          </span>
        </div>
      </MaterialRadioStyled>
    );
  }
);

const MaterialRadioStyled = styled.label`
  display: inline-block;
  cursor: pointer;
  input[type='radio'] {
    display: none;
    &:checked + .radio-wrapper {
      background-color: ${APP_COLORS.primary};
      color: ${APP_COLORS.white};
      & > .check-icon {
        display: inline-block;
      }
    }
  }
  width: 100%;
  .radio-wrapper {
    border: 0.1rem solid ${APP_COLORS.normal};
    padding: 0.8rem 1.6rem;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    border-radius: 100rem;
    transition: all 0.3s ease-in-out;
    &:hover {
      border-color: ${APP_COLORS.primary};
      color: ${APP_COLORS.primary};
    }
    & > .radio-content {
      text-transform: capitalize;
    }
    & > .check-icon {
      display: none;
    }
  }
`;
