import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

import { FAKE_WAITING_MS, PATH_LOGIN } from '@configs';
import { LogApp, showAppToast, useFakeLoading } from '@utils';
import { ResetPasswordForm } from '@components';
import { adminResetPasswordAction, setLoading, useAppDispatch } from '@redux';
import { ResetPasswordSchema } from '@validations';

export const ResetPasswordModule = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  useFakeLoading(FAKE_WAITING_MS);

  useEffect(() => {
    if (!searchParams.has('hash')) {
      showAppToast('You need to verify your email first', 'info', 'light');
      navigate(PATH_LOGIN);
    }
  }, [searchParams]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ResetPasswordSchema),
  });

  const handleResetPassword = handleSubmit(async (values, event) => {
    event?.preventDefault();
    if (!isValid || !searchParams.get('hash') || !searchParams.has('hash')) return;
    try {
      dispatch(setLoading(true));
      await dispatch(
        adminResetPasswordAction({
          password: values.password,
          confirmPassword: values.confirmPassword,
          hash: searchParams.get('hash') as string,
        })
      ).then(() => {
        reset({ password: '', confirmPassword: '' });
        navigate(PATH_LOGIN, { replace: true });
        dispatch(setLoading(false));
      });
    } catch (err: any) {
      LogApp('💥 Reset password error', err);
      dispatch(setLoading(false));
    }
  });

  return (
    <ResetPasswordForm
      register={register}
      errors={errors}
      handleResetPassword={handleResetPassword}
    />
  );
};
