import { uploadAPI } from '@api';
import { IUploadToFirebaseBody } from '@interfaces';

export const appUploadFileFirebase = async ({
  file,
  ...rest
}: Omit<IUploadToFirebaseBody, 'formData'> & { file?: any }) => {
  let data;
  let response;
  let error;
  let loading;
  if (file) {
    if (typeof file === 'string') return;
    try {
      loading = true;
      const body = new FormData();
      // body.append('file', file, renameFileToSlug(file?.name)) // for browser not support file
      body.append('file', file);
      const res = await uploadAPI.uploadToFirebase({ formData: body, ...rest });
      response = res;
      data = response?.data;
    } catch (err: any) {
      error = err;
    } finally {
      loading = false;
    }

    return { data, response, loading, error };
  }
};
