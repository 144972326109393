import { customerAPI } from '@api';
import {
  BaseResponseAPI,
  IAddPersonalizationParams,
  IBasicCreationParams,
  IColothesAPI,
  ICustomer,
  IElementAPI,
  IGetElementsParams,
  IGetListParams,
  IGetListResponse,
} from '@interfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { showAppToast } from '@utils';
import { setLoading } from '../Slices';
import { TRANSLATIONS_FR } from '@translations';

const prefix = 'customer';

export const getAllCustomersAction = createAsyncThunk<IGetListResponse<ICustomer>, IGetListParams>(
  `${prefix}/getAllCustomersAction`,
  async (params) => {
    try {
      const response = await customerAPI.getAllCustomer(params);
      return response.data;
    } catch (error: any) {
      showAppToast(error.response.data.message, 'error', 'light');
    }
  }
);

export const deleteCustomerAction = createAsyncThunk<
  BaseResponseAPI<string>,
  { id: number } & IGetListParams
>(`${prefix}/deleteCustomerAction`, async (params, { dispatch }) => {
  try {
    const { id, ...rest } = params;
    dispatch(setLoading(true));
    const response: any = await customerAPI.deleteCustomer(id);
    if (response.success) {
      await dispatch(getAllCustomersAction(rest));
      dispatch(setLoading(false));
    }
    return response;
  } catch (error: any) {
    dispatch(setLoading(false));
    if (Object.keys(TRANSLATIONS_FR).some((key) => error.response.data.message.includes(key))) {
      const firebaseErrorKey = error.response.data.message
        .split('Firebase error: ')
        .join('') as keyof typeof TRANSLATIONS_FR;
      showAppToast(TRANSLATIONS_FR[firebaseErrorKey], 'error', 'light');
      return;
    }
    showAppToast(error.response.data.message, 'error', 'light');
  }
});

export const getElementsAction = createAsyncThunk<IElementAPI[], IGetElementsParams>(
  `${prefix}/getElemetsAction`,
  async (params) => {
    try {
      const response = await customerAPI.getElements(params);
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
);

export const basicCreationAction = createAsyncThunk<IColothesAPI, IBasicCreationParams>(
  `${prefix}/basicCreationBasicAction`,
  async (params) => {
    try {
      const response = await customerAPI.basicCreation(params);
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
);

export const addPersonalizationAction = createAsyncThunk<void, IAddPersonalizationParams>(
  `${prefix}/addPersonalizationAction`,
  async (params) => {
    try {
      await customerAPI.addPersonalization(params);
    } catch (error: any) {
      showAppToast(error.response.data.message, 'error', 'light', 'add-personlization-error');
    }
  }
);
