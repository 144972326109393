export enum enumSizeIcon {
  DESKTOP = 58,
  TABLET = 40,
  DEFAULT = 78,
}

export enum enumBreakPoint {
  DESKTOP = 1280,
  TABLET = 1024,
  _2k = 2048,
  _3k = 38,
}

export enum enumPagination {
  _1 = 1,
  _2 = 2,
  _3 = 3,
  _4 = 4,
}

export const pagesIntroduction: enumPagination[] = [
  enumPagination._1,
  enumPagination._2,
  enumPagination._3,
  enumPagination._4,
];

export enum enumThemeMode {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum enumOrderDate {
  Y_M_D = 'year/month/day',
  D_M_Y = 'day/month/year',
  D_Y_M = 'day/year/month',
  M_D_Y = 'month/day/year',
  M_Y_D = 'month/year/day',
  Y_D_M = 'year/day/month',
}

export enum enumBtnStyle {
  BASIC = 'basic',
  ROUNDED = 'rounded',
  ICON = 'icon',
}

export enum enumRuleType {
  standard = 'standard',
  superior = 'superior',
  birthday = 'birthday',
}

export enum enumCashbackType {
  cost = 'cashback',
  percent = 'percentage',
}

export enum enumNavKey {
  TAILORS = 1,
  CUSTOMERS,
  PROMOTIONS,
  INSPIRATIONS,
}

export enum enumMainNavKey {
  TAILORS = enumNavKey.TAILORS,
  CUSTOMERS = enumNavKey.CUSTOMERS,
  PROMOTIONS = enumNavKey.PROMOTIONS,
  INSPIRATIONS = enumNavKey.INSPIRATIONS,
}

export enum enumStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum enumMemberType {
  ACTIVE = 'active',
  NEW_CUSTOMER = 'new',
}

export enum enumHeaderMenuItemKey {
  CHANGE_PASSWORD = 1,
  LOG_OUT,
}

export enum enumSettingMenuItemKey {
  COMPANY_INFO,
  CUSTOMER_HIERARCHY,
  CUSTOMER_CLASS,
  CUSTOMER_POINTS,
  ACCOUNT_MANAGEMENT,
  DEPARTMENT_MANAGEMENT,
  ACCESS_LOG,
  SALES_PROCESS,
  SYSTEM_THEMES,
}

export enum enumUploadType {
  BUTTON = 1,
  IMAGE,
}

export enum enumPageSize {
  LIMIT_10 = 10,
  LIMIT_20 = 20,
  LIMIT_50 = 50,
}

export enum enumBirthday {
  BIRTHDAY = 'birthday',
}

export enum enumSimulatorBy {
  BY_TOTAL_AMOUNT = 1,
  BY_PRODUCT,
}

export enum enumDashboardFilterTime {
  ALL_DAYS = 'all-days',
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST_7_DAYS = 7,
  LAST_14_DAYS = 14,
  LAST_21_DAYS = 21,
  LAST_28_DAYS = 28,
  LAST_60_DAYS = 60,
  CUSTOM = 'custom',
}

export enum enumOverviewCard {
  CUSTOMERS = 1,
  PROFIT,
  CAMPAIGNS,
  PRODUCT_SOLD,
}

export enum enumRole {
  STAFF = 'STAFF',
  S_MANAGER = 'S_MANAGER',
  B_MANAGER = 'B_MANAGER',
  ADMIN = 'ADMIN',
}

export enum enumRoleLabel {
  STAFF = 'Nhân viên',
  S_MANAGER = 'Quản trị hệ thống',
  B_MANAGER = 'Quản lý',
  ADMIN = 'Owner',
}
export enum enumOrderStatus {
  IS_NOT_ACCEPTED = 'IS_NOT_ACCEPTED',
  IS_NOT_DELIVERED = 'IS_NOT_DELIVERED',
  IS_DELIVERING = 'IS_DELIVERING',
  IS_DELIVERED = 'IS_DELIVERED', // đã giao, chưa thanh toán
  IS_CANCELED = 'IS_CANCELED', // huỷ khi chưa nhận chưa thanh toán
  IS_PAID = 'IS_PAID', // đã thanh toán
  IS_REFUND = 'IS_REFUND', // huỷ khi đã thanh toán
}

export enum enumCampaignStatus {
  DRAFT = 'DRAFT',
  NOT_ACTIVE = 'NOT_ACTIVE', //tạm dừng hoặc sắp diễn ra
  ACTIVE = 'ACTIVE',
  END = 'END',
  CANCELED = 'CANCELED',
  OVERDUE = 'OVERDUE',
}

export enum enumVoucherStatus {
  BLOCK = 'BLOCK',
  ACTIVE = 'ACTIVE',
}

export enum enumVoucherType {
  VOUCHER = 'VOUCHER', // giảm giá theo số tiền ghi trên voucher
  CODE = 'CODE', // giảm giá theo % khuyến mãi
}

// * Filter ago: 7 ngay qua/ 4 tuan qua/ 6 thang qua
export enum enumDashboardFilter {
  DAY = 1,
  WEEK,
  MONTH,
}

// * Filter last: trong tuan/ trong thang/ trong nam
export enum enumDashboardDataFilter {
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum enumUserStatus {
  INACTIVE = 0,
  ACTIVE,
}

export enum enumChanceStatus {
  IN_PROCESS = 'IN_PROCESS',
  FAILED_END = 'FAILED_END',
  SUCCESS_END = 'SUCCESS_END',
}

export enum enumChanceProcess {
  STEP_ONE = 0,
  STEP_TWO,
  STEP_THREE,
  STEP_FOUR,
  STEP_FIVE,
  STEP_SIX,
}

export enum enumTailorRegistrationStatus {
  APPROVED = 'approved',
  PENDING = 'pending',
  REJECTED = 'rejected',
}

export enum enumGender {
  WOMAN = 'woman',
  MAN = 'man',
  BOY = 'boy',
  GIRL = 'girl',
}

export enum enumImagePosition {
  FRONT = 1,
  PROFILE = 2,
  BACK = 3,
}

export enum ALLOWED_BUCKET_NAMES_OBJECT {
  PROJECT_CLOTHES_IMAGE = 'project-clothes-images',
  RECIPIENT_IMAGES = 'recipient-images',
  ELEMENT_FABRIC_PICTURES = 'element-fabric-pictures',
  ITEM_FABRIC_PICTURES = 'item-fabric-pictures',
  TAILOR_CREATIONS = 'tailor-creations',
  TAILOR_PROFILE_PICTURES = 'tailor-profile-pictures',
  ADMIN_INSPIRATION = 'admin-inspiration',
  MEASURE_INSTRUCTIONS = 'measure-instructions',
}

export enum enumClient {
  ADMIN = 'admin',
  CUSTOMER = 'customer',
  TAILOR = 'tailor',
}
