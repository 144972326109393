import { genderAPI } from '@api';
import { IGenderAPI } from '@interfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';

const prefix = 'gender';

export const getGendersAction = createAsyncThunk<IGenderAPI[], void>(
  `${prefix}/getGendersAction`,
  async () => {
    try {
      const response = await genderAPI.getGenders();
      return response.data;
    } catch (error: any) {
      throw new Error(error.message.response.data);
    }
  }
);
