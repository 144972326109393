import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { IBreadcrumbItem } from '@interfaces';
import { PATH_HOME } from '@configs';
import { LogApp } from '@utils';
import { HomeIcon } from './Icon';
import { APP_COLORS } from '@theme';

interface IProps {
  data?: IBreadcrumbItem[];
}

export const Breadcrumbs = memo((props: IProps) => {
  const { data } = props;
  const { t } = useTranslation();
  return (
    <StyledBreadcrumbs className="app-breadcrumb" separator="">
      {/* <span className="app-breadcrumb-link app-bread">
        <NavLink to={PATH_HOME}>
          <HomeIcon size={20} color="#252525" />
        </NavLink>
      </span> */}
      <ol>
        {data &&
          data.slice(0, data.length - 1).map((item: IBreadcrumbItem, index: number) => {
            return (
              <li className="app-breadcrumb-item" key={index}>
                {!!index && <span className="app-breadcrumb-separator">&#62;&#62;</span>}
                {/* <span className="app-breadcrumb-separator">&#62;</span> */}
                <span className="app-breadcrumb-link">
                  <NavLink to={item.path}>{t(`${item.label}`).replaceAll('-', ' ')}</NavLink>
                </span>
              </li>
            );
          })}
        {data && (
          <li className="app-breadcrumb-item">
            {data.length > 1 && <span className="app-breadcrumb-separator">&#62;&#62;</span>}
            <span className="app-breadcrumb-link">
              <span>{t(`${data[data.length - 1].label}`).replaceAll('-', ' ')}</span>
            </span>
          </li>
        )}
      </ol>
    </StyledBreadcrumbs>
  );
});

const StyledBreadcrumbs = styled((props) => <nav {...props} />)`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  ol {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: flex-end;
  }
  .app-bread {
    display: flex;
    align-items: center;
    a {
      color: ${(p: any) => p?.theme.colors?.header?.text};
    }
  }
  .app-breadcrumb a {
    color: rgba(0, 0, 0, 0.45);
    transition: color 0.3s;
    font-weight: 500;
  }
  .app-breadcrumb-separator {
    margin: 0 8px;
    font-size: 1.6rem;
    color: rgba(0, 0, 0, 0.45);
  }
  .app-breadcrumb-link {
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    text-transform: capitalize;
  }
  .app-breadcrumb-item {
    &:not(:last-child) {
      .app-breadcrumb-link a,
      .app-breadcrumb-link span {
        color: ${APP_COLORS.gray900};
      }
    }
    &:last-child {
      .app-breadcrumb-link a,
      .app-breadcrumb-link span {
        color: ${APP_COLORS.primary};
        cursor: default;
      }
    }
  }
`;
