import { ResetPasswordModule } from '@modules';
import { StyledAuth } from '.';
import logoUrl from './../../assets/images/logo-light.svg';
import { IMAGES } from '@assets';
import { AppImage } from '@components';

export const ResetPasswordPage: React.FC = () => {
  return (
    <StyledAuth className="login-page">
      <div className="flex items-center justify-center h-full">
        <AppImage
          src={IMAGES.authImage}
          className="object-cover w-full h-full"
          alt="Filyou Image"
        />
        <div className="flex flex-col w-full gap-12 px-28 auth-section">
          <div className="flex items-center -intro-x main-logo">
            <AppImage src={logoUrl} alt="Filyou logo" />
          </div>
          <div className="flex flex-col gap-4 px-5 py-12 bg-white rounded-md shadow-md">
            <h2 className="m-0 font-bold text-center intro-x heading">Reset password</h2>
            <div className="mb-8 text-base text-center intro-x text-slate-400 desc">
              Please enter your new password
            </div>
            <ResetPasswordModule />
          </div>
        </div>
      </div>
    </StyledAuth>
  );
};
