import { authAPI } from '@api';
import {
  IChangePasswordFields,
  ILoginParams,
  ILoginResponse,
  IResetPasswordFields,
  ISendMailForgotFields,
} from '@interfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { showAppToast } from '@utils';
import {
  resetApp,
  resetCustomer,
  resetInspiration,
  resetPromotion,
  resetTailor,
  setLoading,
  setShowChangePasswordModal,
} from '../Slices';

const prefix = 'auth';

export const adminLoginAction = createAsyncThunk<ILoginResponse, ILoginParams>(
  `${prefix}/adminLoginAction`,
  async (params) => {
    try {
      const response: any = await authAPI.login(params);
      if (response.success) {
        showAppToast('Login successfully', 'success', 'light');
      }
      return response.data;
    } catch (error: any) {
      showAppToast(error?.response?.data?.message, 'error', 'light');
    }
  },
);

export const adminLogoutAction = createAsyncThunk<void, void>(
  `${prefix}/adminLogoutAction`,
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res: any = await authAPI.logout();
      if (res?.success) {
        dispatch(resetApp());
        dispatch(resetTailor());
        dispatch(resetCustomer());
        dispatch(resetInspiration());
        dispatch(resetPromotion());
      } else {
        throw res?.data?.message;
      }
    } catch (error: any) {
      showAppToast(error?.response?.data?.message, 'error', 'light');
    } finally {
      dispatch(setLoading(false));
    }
  },
);

export const adminChangePasswordAction = createAsyncThunk<void, IChangePasswordFields>(
  `${prefix}/adminChangePasswordAction`,
  async (body, { dispatch }) => {
    try {
      const response: any = await authAPI.changePassword(body);
      dispatch(setShowChangePasswordModal(false));
      if (response.success) {
        showAppToast('Change password successfully', 'success', 'light');
      }
    } catch (error: any) {
      showAppToast(error?.response?.data?.message, 'error', 'light');
    }
  },
);

export const adminForgotPasswordAction = createAsyncThunk<void, ISendMailForgotFields>(
  `${prefix}/adminForgotPasswordAction`,
  async ({ email }) => {
    try {
      const response: any = await authAPI.forgotPassword({ email });
      if (response.success) {
        showAppToast(
          `An email has been sent to ${email} with further instructions`,
          'success',
          'light',
        );
      }
    } catch (error: any) {
      showAppToast(error?.response?.data?.message, 'error', 'light');
    }
  },
);

export const adminResetPasswordAction = createAsyncThunk<void, IResetPasswordFields>(
  `${prefix}/adminResetPasswordAction`,
  async (body) => {
    try {
      const response: any = await authAPI.resetPassword(body);
      if (response.success) {
        showAppToast('Your password has been updated', 'success', 'light');
      }
    } catch (error: any) {
      showAppToast(error?.response?.data?.message, 'error', 'light');
    }
  },
);
