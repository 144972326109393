import { type ThunkDispatch } from 'redux-thunk';
import { type AnyAction, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';

import reducer, { RootState } from './Slices';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'layout', 'gender', 'inspiration'],
  blacklist: ['app', 'toast', 'tailor', 'customer', 'promotion'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

type AppDispatch = typeof store.dispatch;
type TypedDispatch<T> = ThunkDispatch<T, any, AnyAction>;

export default store;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<TypedDispatch<AppDispatch>>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
