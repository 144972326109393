import { ITailorCreation } from '@interfaces';
import { APP_COLORS } from '@theme';
import { SharedIcon } from 'src/components/Icon';
import { AppImage } from 'src/components/shared';
import styled from 'styled-components';

interface IProps {
  achievements: ITailorCreation[];
}

export const DetailsAchievements: React.FC<IProps> = ({ achievements }) => {
  return (
    <ContainerStyled className="achievement-details-item">
      {achievements.slice(0, 4).map((item) => (
        <AchievementWrapper key={item.id} className="achievement-wrapper">
          <AppImage
            width={240}
            height={240}
            className="achievement-img"
            containerClassName="achievement-img-container"
            firebaseResourceKey={item.imageUrl}
          />
          <div className="achievement-metadata">
            <div className="achievement-production-time">
              <SharedIcon name="clock" />
              <div className="metadata-info">
                <span>Production time:</span>
                <span>{item.productionTime ? `${item.productionTime}d` : '--'}</span>
              </div>
            </div>
            <div className="achievement-price">
              <SharedIcon name="cash" />
              <div className="metadata-info">
                <span className="metadata-info">Price:</span>
                <span>{item.price ? `€${item.price}` : '--'}</span>
              </div>
            </div>
          </div>
        </AchievementWrapper>
      ))}
    </ContainerStyled>
  );
};

const ContainerStyled = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 6.4rem;
  row-gap: 3.2rem;
`;

const AchievementWrapper = styled.div`
  position: relative;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

  & > .achievement-img-container {
    border-radius: 0.5rem;
    overflow: hidden;

    .achievement-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.5rem;
    }
  }

  & > .achievement-metadata {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.8rem 1.2rem;
    background-color: rgba(0, 0, 0, 0.75);
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    color: ${APP_COLORS.white};
    font-size: 1.4rem;

    & > div {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 0.6rem;

      & > .metadata-info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
`;
