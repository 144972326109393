import { Spin, type SpinProps } from 'antd';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

import { MAIN_THEME_DATA } from '@configs';

const spinnerIcon = (
  <LoadingOutlined style={{ fontSize: 48, color: MAIN_THEME_DATA.primaryColor }} spin />
);

export const AppSpin: React.FC<SpinProps> = ({ children, ...props }) => {
  return (
    <Spin indicator={spinnerIcon} {...props}>
      {children}
    </Spin>
  );
};
