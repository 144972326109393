import {
  IGetAllTailorsParams,
  IToggleTailorStatus,
  IUpdateTailorApplicationStatusParams,
} from '@interfaces';
import axiosClient from './axiosClient';
import { enumGender } from '@configs';

export const tailorAPI = {
  getAllTailors: (params: IGetAllTailorsParams) => {
    const url = '/api/admin/tailors';
    return axiosClient.get(url, { params });
  },
  getTailorDetails: (id: number) => {
    const url = `/api/admin/tailor/${id}`;
    return axiosClient.get(url);
  },
  updateTailorApplicationStatus: (params: IUpdateTailorApplicationStatusParams) => {
    const { id, validationStatus } = params;
    const url = `/api/admin/tailor/${id}`;
    return axiosClient.patch(url, { validationStatus });
  },
  toggleTailorStatus: (params: IToggleTailorStatus) => {
    const { id, status } = params;
    const url = `/api/admin/user/${id}`;
    return axiosClient.patch(
      url,
      { status },
      {
        headers: {
          client: 'tailor',
        },
      }
    );
  },
  getSubcategoryByCategoryId: (categoryId: number) => {
    const url = `/api/tailors/skills/category/${categoryId}/subcategory`;
    return axiosClient.get(url);
  },
};
