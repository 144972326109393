import styled, { css } from 'styled-components';

export const AppText = styled.p<{
  $color?: string;
  $fontWeight?: number;
  $fontSize?: string | number;
  $mt?: string | number;
  $contentColor?: string;
}>`
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 0;
  strong {
    ${({ $contentColor }) =>
      $contentColor &&
      css`
        color: ${$contentColor};
      `}
  }
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}
  ${({ $fontWeight }) =>
    $fontWeight &&
    css`
      font-weight: ${$fontWeight};
    `}
  ${({ $fontSize }) =>
    $fontSize &&
    css`
      font-size: ${typeof $fontSize === 'number' ? `${$fontSize} px` : $fontSize};
    `}
    ${({ $mt }) =>
    $mt &&
    css`
      font-size: ${typeof $mt === 'number' ? `${$mt} px` : $mt};
    `}
`;

export const NormalText = styled.p<{
  $color?: string;
}>`
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 0;
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}
`;

export const SmallText = styled.p<{
  $color?: string;
}>`
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 0;
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}
`;
