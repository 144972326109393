import { APP_COLORS } from '@theme';
import React from 'react';
import { SharedIcon } from 'src/components/Icon';
import styled, { css } from 'styled-components';

interface TintBtnProps extends React.ComponentPropsWithRef<'button'> {
  color?: string;
  colorText?: React.ReactNode;
  checked?: boolean;
}

export const TintButton = React.forwardRef<HTMLButtonElement, TintBtnProps>(
  ({ color = '#fff', colorText = 'Color', checked = false, ...rest }, ref) => {
    return (
      <ButtonWrapper $checked={checked} $color={color} ref={ref} {...rest}>
        <span className="color-text">{colorText}</span>
        <span className="color-indicator">
          <span className="check-icon">
            <SharedIcon name="check" size={12} />
          </span>
        </span>
      </ButtonWrapper>
    );
  }
);

TintButton.displayName = 'TintButton';

const ButtonWrapper = styled.button<{ $color: string; $checked: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 5.2rem;
  border: 0.2rem solid ${(props) => props.$color};
  text-transform: capitalize;
  font-size: 1.6rem;
  border-radius: 0.4rem;
  transition: all 0.3s ease-in-out;
  & > .color-text {
    padding: 0 1.6rem;
  }
  & > .color-indicator {
    height: 100%;
    width: 5.2rem;
    background-color: ${(props) => props.$color};
    display: flex;
    align-items: center;
    justify-content: center;
    & > .check-icon {
      width: 2.4rem;
      height: 2.4rem;
      border: 0.2rem solid ${APP_COLORS.white};
      border-radius: 100%;
      color: ${APP_COLORS.white};
      display: flex;
      align-items: center;
      justify-content: center;
      & > svg {
        opacity: 0;
        visibility: hidden;
        ${(props) =>
          props.$checked &&
          css`
            opacity: 1;
            visibility: visible;
          `};
      }
    }
  }
`;
