import { APP_COLORS } from '@theme';
import React from 'react';
import styled from 'styled-components';

interface IProps {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
}

export const UserOptionLabel: React.FC<IProps> = ({ firstName, lastName, email }) => {
  const fullname = [firstName, lastName].join(' ').trim();

  return (
    <WrapperStyled className="customer-option-label">
      <span className="customer-fullname">{fullname}</span>
      <span className="customer-email">{email && `(${email})`}</span>
    </WrapperStyled>
  );
};

const WrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  & > .customer-fullname {
    font-size: 1.6rem;
  }
  & > .customer-email {
    font-size: 1.4rem;
    color: ${APP_COLORS.darkGray};
  }
`;
