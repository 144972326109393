import { ColorBox, InspirationMasonary, PersonalizationItemInfo, StyledSection } from '@components';
import { InspirationInfoModule } from '@modules';
import { Badge, Button, Collapse, Descriptions, Empty } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import _ from 'lodash';
import { inspirationDetailsSelector, useAppSelector } from '@redux';
import { IMAGES } from '@assets';
import { v4 } from 'uuid';

interface IProps {
  isViewPage: boolean;
  handleCancle: () => void;
}

export const InspirationViewAndEditSection: React.FC<IProps> = ({ isViewPage, handleCancle }) => {
  const {
    pictures,
    gender,
    category,
    subcategory,
    color,
    material,
    pattern,
    properties,
    personalizations,
  } = useAppSelector(inspirationDetailsSelector);

  const groupedProperties = _.mapValues(_.groupBy(properties, 'family'), (properties) =>
    properties.map((property) => property.property),
  );

  return (
    <StyledSection>
      <WrapperStyled>
        <ContainerStyled>
          <InspirationMasonary images={pictures} />
          <InspirationInfoModule isViewPage={isViewPage} />
        </ContainerStyled>
        {isViewPage && (
          <CollapseStyled
            defaultActiveKey={['details', 'personalizations']}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined style={{ fontSize: '1.6rem' }} rotate={isActive ? 90 : 0} />
            )}
          >
            <Collapse.Panel header="More details" key="details">
              <Descriptions title="Inspiration details" bordered column={1}>
                <Descriptions.Item label="Gender">{gender}</Descriptions.Item>
                <Descriptions.Item label="Category">{category}</Descriptions.Item>
                <Descriptions.Item label="Sub-category">{subcategory}</Descriptions.Item>
                <Descriptions.Item label="Color range">
                  {color && (
                    <>
                      <span>{color.colorRange}</span>
                      <ColorBox $color={color.rangeHexCode} />
                    </>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Tint">
                  <span>{color?.tint}</span>
                  <ColorBox $color={color?.tintHexCode} />
                </Descriptions.Item>
                <Descriptions.Item label="Material">{material}</Descriptions.Item>
                <Descriptions.Item label="Properties">
                  <ul className="flex flex-col gap-2 m-0">
                    {Object.entries(groupedProperties).map(([family, properties]) => (
                      <li key={family} className="flex items-center gap-3">
                        <Badge status="processing" />
                        <span className="font-medium">{family}:</span>
                        <span>{properties.join(', ')}</span>
                      </li>
                    ))}
                  </ul>
                </Descriptions.Item>
                <Descriptions.Item label="Pattern">
                  <span>{pattern?.pattern}</span>
                  {pattern && (
                    <figure className="flex flex-col items-center w-32 h-32 gap-2 m-0 overflow-hidden border border-gray-200 rounded-lg shadow-md">
                      <img
                        className="object-cover w-full h-full rounded-lg"
                        src={pattern.imageUrl}
                        alt={pattern.pattern}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = IMAGES.defaultImage;
                        }}
                      />
                    </figure>
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Collapse.Panel>
            <Collapse.Panel header="Personalizations" key="personalizations">
              <PersonalizationContainerStyled>
                <div className="description-title">Personaliztions</div>
                <div className="personalizations-list">
                  {personalizations &&
                    personalizations.length > 0 &&
                    personalizations.map((item, index) => (
                      <PersonalizationItemInfo key={v4()} personlizationItem={item} />
                    ))}
                  {(!personalizations || (personalizations && personalizations.length < 1)) && (
                    <Empty />
                  )}
                </div>
              </PersonalizationContainerStyled>
            </Collapse.Panel>
          </CollapseStyled>
        )}
        <ActionStyled>
          <Button size="large" shape="round" onClick={handleCancle}>
            Cancel
          </Button>
          {!isViewPage && (
            <Button
              shape="round"
              size="large"
              htmlType="submit"
              form="inspiration-form"
              type="primary"
            >
              Save
            </Button>
          )}
        </ActionStyled>
      </WrapperStyled>
    </StyledSection>
  );
};

const WrapperStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4.8rem;
  padding: 2.4rem 4.8rem;
`;

const ContainerStyled = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.6fr;
  gap: 6.4rem;
`;

const CollapseStyled = styled(Collapse)`
  width: 100%;
  .ant-collapse-header {
    display: flex;
    align-items: center !important;
    justify-content: center;
    & > .ant-collapse-expand-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & > .ant-collapse-header-text {
      font-size: 2.4rem;
    }
  }
  .ant-descriptions {
    .ant-descriptions-title {
      font-size: 1.8rem;
    }
    .ant-descriptions-row {
      & > .ant-descriptions-item-label {
        font-size: 1.6rem;
      }
      & > .ant-descriptions-item-content {
        text-transform: capitalize;
        font-size: 1.6rem;
        & > span {
          display: flex;
          align-items: center;
          gap: 1.2rem;
        }
      }
    }
  }
`;

const PersonalizationContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  & > .description-title {
    overflow: hidden;
    white-space: nowrap;
    font-size: 1.8rem;
    font-weight: 700;
  }
  & > .personalizations-list {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
`;

const ActionStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.2rem;
`;
