import { emailValidationRegex, strongPasswordValidationRegex } from '@configs';
import * as yup from 'yup';

export const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .email('Email must be a valid email')
    .matches(emailValidationRegex, 'Email must be a valid email'),
  password: yup.string().required('Password is required'),
});

export const ForgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .email('Email must be a valid email')
    .matches(emailValidationRegex, 'Email must be a valid email'),
});

export const ResetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .trim()
    .required('Password is required')
    .min(8, 'Password must have 8-32 characters')
    .max(32, 'Password must have 8-32 characters')
    .matches(
      strongPasswordValidationRegex,
      'Password must include numbers, uppercase, lowercase letters and at least one special character'
    ),
  confirmPassword: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('password')], 'Confirm password not match'),
});

export const ChangePasswordSchema = yup.object().shape({
  oldPassword: yup.string().trim().required('Password is required'),
  newPassword: yup
    .string()
    .trim()
    .required('Password is required')
    .min(8, 'Password must have 8-32 characters')
    .max(32, 'Password must have 8-32 characters')
    .matches(
      strongPasswordValidationRegex,
      'Password must include numbers, uppercase, lowercase letters and at least one special character'
    ),
  confirmPassword: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('newPassword')], 'Confirm password not match'),
});
