import { ReactComponent as facebook } from './../../../assets/icons/facebook.svg';
import { ReactComponent as instagram } from './../../../assets/icons/instagram.svg';
import { ReactComponent as trash } from './../../../assets/icons/trash.svg';
import { ReactComponent as pencil } from './../../../assets/icons/pencil.svg';
import { ReactComponent as eyeOpen } from './../../../assets/icons/eye-open.svg';
import { ReactComponent as view } from './../../../assets/icons/view.svg';
import { ReactComponent as rightOutlined } from './../../../assets/icons/right-outlined.svg';
import { ReactComponent as check } from './../../../assets/icons/check.svg';
import { ReactComponent as plus } from './../../../assets/icons/plus.svg';
import { ReactComponent as downOutlined } from './../../../assets/icons/down-outlined.svg';
import { ReactComponent as upOutlined } from './../../../assets/icons/up-outlined.svg';
import { ReactComponent as upload } from './../../../assets/icons/upload.svg';
import { ReactComponent as close } from './../../../assets/icons/close.svg';
import { ReactComponent as clock } from './../../../assets/icons/clock.svg';
import { ReactComponent as cash } from './../../../assets/icons/cash.svg';

export const IconMap = {
  facebook,
  instagram,
  trash,
  pencil,
  eyeOpen,
  rightOutlined,
  check,
  plus,
  downOutlined,
  upOutlined,
  upload,
  close,
  view,
  clock,
  cash,
};
