import { AppLayout, LayoutError } from '@layouts';
import { IRoute } from '@interfaces';
import {
  PageError404,
  CustomerManagementPage,
  TailorManagementPage,
  HomePage,
  LoginPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  TailorRegistrationPage,
  TailorRegistrationDetailsPage,
  InspirationManagementPage,
  InspirationViewAndEditPage,
  InspirationCreateBasicPage,
  InspirationCreatePage,
  InspirationCreatePersonalizationPage,
  PromotionManagementPage,
  PromotionViewAddAndEditPage,
} from '@pages';
import {
  PATH_404,
  PATH_TAILOR,
  PATH_LOGIN,
  PATH_FORGOT_PASSWORD,
  PATH_RESET_PASSWORD,
  PATH_CUSTOMER,
  PATH_HOME,
  PATH_REGISTRATION_TAILOR,
  PATH_VIEW_APPLICATION_TAILOR,
  PATH_INSPIRATION,
  PATH_VIEW_DETAILS_INSPIRATION,
  PATH_EDIT_INSPIRATION,
  PATH_CREATE_INSPIRATION_BASIC,
  PATH_CREATE_INSPIRATION,
  PATH_CREATE_INSPIRATION_PERSONALIZE,
  PATH_PROMOTION,
  PATH_ADD_PROMOTION,
  PATH_VIEW_PROMOTION,
  PATH_EDIT_PROMOTION,
} from './navigation';

// const LoginPage = lazy(
//   async () =>
//     await import('./../pages').then(async (module) => {
//       await new Promise((resolve) => setTimeout(resolve, FAKE_WAITING));
//       return { default: module.LoginPage };
//     })
// );
// const ForgotPasswordPage = lazy(
//   async () =>
//     await import('./../pages').then(async (module) => {
//       await new Promise((resolve) => setTimeout(resolve, FAKE_WAITING));
//       return { default: module.ForgotPasswordPage };
//     })
// );
// const ResetPasswordPage = lazy(
//   async () =>
//     await import('./../pages').then(async (module) => {
//       await new Promise((resolve) => setTimeout(resolve, FAKE_WAITING));
//       return { default: module.ResetPasswordPage };
//     })
// );

export const routes: Array<IRoute> = [
  // tailor
  { path: PATH_HOME, page: HomePage, layout: AppLayout },
  { path: PATH_TAILOR, page: TailorManagementPage, layout: AppLayout },
  { path: PATH_REGISTRATION_TAILOR, page: TailorRegistrationPage, layout: AppLayout },
  { path: PATH_VIEW_APPLICATION_TAILOR, page: TailorRegistrationDetailsPage, layout: AppLayout },
  // customer
  { path: PATH_CUSTOMER, page: CustomerManagementPage, layout: AppLayout },
  // inspiration
  { path: PATH_INSPIRATION, page: InspirationManagementPage, layout: AppLayout },
  { path: PATH_VIEW_DETAILS_INSPIRATION, page: InspirationViewAndEditPage, layout: AppLayout },
  { path: PATH_CREATE_INSPIRATION, page: InspirationCreatePage, layout: AppLayout },
  { path: PATH_CREATE_INSPIRATION_BASIC, page: InspirationCreateBasicPage, layout: AppLayout },
  {
    path: PATH_CREATE_INSPIRATION_PERSONALIZE,
    page: InspirationCreatePersonalizationPage,
    layout: AppLayout,
  },
  { path: PATH_EDIT_INSPIRATION, page: InspirationViewAndEditPage, layout: AppLayout },
  // promotion
  { path: PATH_PROMOTION, page: PromotionManagementPage, layout: AppLayout },
  { path: PATH_VIEW_PROMOTION, page: PromotionViewAddAndEditPage, layout: AppLayout },
  { path: PATH_ADD_PROMOTION, page: PromotionViewAddAndEditPage, layout: AppLayout },
  { path: PATH_EDIT_PROMOTION, page: PromotionViewAddAndEditPage, layout: AppLayout },
  // 404
  { path: PATH_404, page: PageError404, layout: LayoutError },
  { page: PageError404, layout: LayoutError },
];

export const authRoutes: Array<IRoute> = [
  //auth
  { path: PATH_LOGIN, page: LoginPage, auth: true },
  { path: PATH_FORGOT_PASSWORD, page: ForgotPasswordPage, auth: true },
  { path: PATH_RESET_PASSWORD, page: ResetPasswordPage, auth: true },
  { page: PageError404, layout: LayoutError },
];
