import { IGetFirebaseResourceBody, IUploadToFirebaseBody } from '@interfaces';
import axiosClient from './axiosClient';
import { enumClient } from '@configs';

export const uploadAPI = {
  uploadToAmazon: (formData: FormData) => {
    const url = '/api/upload/amazon';
    return axiosClient.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        client: enumClient.ADMIN,
      },
    });
  },
  uploadToFirebase: (body: IUploadToFirebaseBody) => {
    const { formData, ...params } = body;
    const url = '/api/upload/firebase';
    return axiosClient.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        client: enumClient.ADMIN,
      },
      params,
    });
  },
  getFirebaseResource: (body: IGetFirebaseResourceBody) => {
    const { key, bucketName, lang } = body;
    const url = '/api/upload/firebase/get-firebase-resource';
    return axiosClient.post(
      url,
      {
        key,
        bucketName,
      },
      {
        headers: {
          client: enumClient.ADMIN,
        },
        params: {
          client: enumClient.ADMIN,
          lang,
        },
      },
    );
  },
};
