import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { Switch } from 'antd';

import { IDiscountType, IGetListParams } from '@interfaces';
import { PromotionManagementSection } from '@pages';
import {
  getListPromotionsAction,
  promotionsTableSelector,
  selectPromotion,
  setTableLoading,
  togglePromotionAction,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { IconBtn, SharedIcon } from '@components';
import { APP_COLORS } from '@theme';
import { PATH_ADD_PROMOTION, PATH_PROMOTION } from '@configs';

export interface PromotionDataType {
  key: number;
  promoTitle: string;
  discount: number;
  discountType: IDiscountType;
  createdAt: string;
  expiryDate: string;
  isEnabled: boolean;
}

export const PromotionManagementModule: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const promotionTableData = useAppSelector(promotionsTableSelector);
  const { limitPerPage, totalPromotions } = useAppSelector(selectPromotion);

  const [pagination, setPagination] = useState({
    totalItems: 0,
    perPage: 10,
  });

  const [payload, setPayload] = useState<IGetListParams>({
    page: 1,
    limit: 10,
  });

  const handleChangePage = (page: number) => {
    setPayload({ ...payload, page });
  };

  const handleChangePageSize = (size: number): void => {
    setPayload({ ...payload, limit: size, page: 1 });
  };

  const handleNavigateAddPromotion = useCallback((): void => {
    navigate(PATH_ADD_PROMOTION);
  }, []);

  const handleNavigateViewPromotion = useCallback((promoID: number): void => {
    navigate(`${PATH_PROMOTION}/details/${promoID}`);
  }, []);

  const handleNavigateEditPromotion = useCallback((promoID: number): void => {
    navigate(`${PATH_PROMOTION}/update/${promoID}`);
  }, []);

  const columns = useMemo<ColumnsType<PromotionDataType>>(() => {
    return [
      {
        title: 'Promo ID',
        key: 'promoID',
        dataIndex: 'key',
      },
      {
        title: 'Promo title',
        key: 'promoTitle',
        dataIndex: 'promoTitle',
      },
      {
        title: 'Discount',
        key: 'discount',
        dataIndex: 'discount',
        render: (value, record) => {
          if (record.discountType === 'amount') {
            const currencyFormat = new Intl.NumberFormat();
            return '€ ' + currencyFormat.format(value);
          }
          return value + ' %';
        },
      },
      {
        title: 'Create date',
        key: 'createdAt',
        dataIndex: 'createdAt',
      },
      {
        title: 'Expire date',
        key: 'expiryDate',
        dataIndex: 'expiryDate',
      },
      {
        title: 'Discount status',
        key: 'discountStatus',
        render: (_value, record) => {
          const onPromotionStatusChange = (checked: boolean): void => {
            if (!checked) {
              dispatch(togglePromotionAction({ id: record.key, isEnabled: false }));
              return;
            }
            dispatch(togglePromotionAction({ id: record.key, isEnabled: true }));
          };

          return (
            <div className="flex items-center justify-center">
              <Switch
                defaultChecked={record.isEnabled === true}
                onChange={onPromotionStatusChange}
              />
            </div>
          );
        },
      },
      {
        title: 'Action',
        key: 'action',
        render: (_value, record) => {
          return (
            <div className="flex items-center justify-center gap-4">
              <IconBtn
                $bordered
                $borderColor={APP_COLORS.green700}
                onClick={() => handleNavigateViewPromotion(record.key)}
              >
                <SharedIcon name="view" size={20} color={APP_COLORS.green700} />
              </IconBtn>
              <IconBtn
                $bordered
                $borderColor={APP_COLORS.info}
                onClick={() => handleNavigateEditPromotion(record.key)}
              >
                <SharedIcon name="pencil" size={18} color={APP_COLORS.info} />
              </IconBtn>
            </div>
          );
        },
      },
    ];
  }, [payload.page, pagination.perPage]);

  useEffect(() => {
    dispatch(setTableLoading(true));
    const promise = dispatch(getListPromotionsAction({ ...payload }));
    void promise.then(() => {
      setPagination({
        totalItems: totalPromotions,
        perPage: limitPerPage,
      });
      dispatch(setTableLoading(false));
    });
    return () => {
      promise.abort();
    };
  }, [payload.limit, payload.page, limitPerPage, totalPromotions]);

  return (
    <PromotionManagementSection
      currentPage={payload.page}
      columns={columns}
      dataSource={promotionTableData}
      pagination={pagination}
      onChangePageSize={handleChangePageSize}
      onPageChange={handleChangePage}
      handleNavigateAddPromotion={handleNavigateAddPromotion}
    />
  );
};
