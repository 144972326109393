import { BaseSyntheticEvent, memo } from 'react';
import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';

import { SharedButton, ShareInput } from '@components';
import { ILoginFields } from '@interfaces';
import { AUTH_THEME_COLOR } from '@configs';
import styled from 'styled-components';

interface IProps {
  errors: Partial<FieldErrorsImpl<any>>;
  register: UseFormRegister<FieldValues>;
  handleResetPassword: (
    e?: BaseSyntheticEvent<ILoginFields, any, any> | undefined
  ) => Promise<void>;
}

export const ResetPasswordForm = memo((props: IProps) => {
  const { errors, register, handleResetPassword } = props;

  return (
    <StyledResetPasswordForm className="reset-password__section">
      {/* @ts-ignore */}
      <form onSubmit={handleResetPassword} className="reset-form">
        <ShareInput
          placeholder="Password"
          name="password"
          className="input"
          type="password"
          errors={errors['password']?.message}
          register={register}
          haveShowPassIcon
        />
        <ShareInput
          placeholder="Re-type password"
          name="confirmPassword"
          className="input"
          type="password"
          errors={errors['confirmPassword']?.message}
          register={register}
          haveShowPassIcon
        />
        <div className="actions">
          <SharedButton
            typeHtml="submit"
            text="Reset"
            className="submit__btn reset-btn"
            backgroundColor={AUTH_THEME_COLOR}
            btnStyle="rounded"
          />
        </div>
      </form>
    </StyledResetPasswordForm>
  );
});

export const StyledResetPasswordForm = styled.section`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  .heading {
    margin-bottom: 3.6rem;
    .heading__title {
      text-align: center;
      font-size: 3.2rem;
      font-weight: 700;
      margin-bottom: 0.8rem;
      color: ${(p) => p.theme.colors.text};
    }
    .desc {
      font-size: 1.6rem;
      font-weight: 400;
      color: ${(p) => p.theme.colors.subText};
    }
  }

  .actions {
    .reset-btn {
      font-size: 1.6rem;
    }
  }

  .reset-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .input {
      font-size: 1.6rem;
    }
    .shared-input {
      margin-bottom: 2rem;
      .inner-input {
        height: 4.6rem;
      }
    }
    .submit__btn {
      width: 20rem;
      height: 5.2rem;
    }

    .inner-input {
      width: 100%;
      height: 4.6rem;
    }

    .agree-policy__cb {
      margin-bottom: 1.8rem;
    }
  }
`;
