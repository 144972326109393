import storage from 'redux-persist/lib/storage';

export const inspirationPersistConfig = {
  key: 'inspiration',
  storage,
  blacklist: ['inspirations', 'totalInspirations', 'limitPerPage', 'currentInspiration'],
  whitelist: [
    'listGenders',
    'listCategories',
    'listSubcategories',
    'listColors',
    'listTints',
    'listFabrics',
    'listProperties',
    'listPatterns',
    'listElements',
    'createInspirationForm',
  ],
};
