import {
  IPromotionBody,
  IGetListParams,
  IUpdatePromotionBody,
  ITogglePromotionBody,
} from '@interfaces';
import axiosClient from './axiosClient';

export const promotionAPI = {
  getListPromotions: (params: IGetListParams) => {
    const url = '/api/promotions';
    return axiosClient.get(url, { params });
  },
  getPromotion: (id: number) => {
    const url = `/api/promotions/${id}`;
    return axiosClient.get(url);
  },
  addPromotion: (body: IPromotionBody) => {
    const url = '/api/promotions';
    return axiosClient.post(url, body);
  },
  updatePromotion: ({ id, ...body }: IUpdatePromotionBody) => {
    const url = `/api/promotions/${id}`;
    return axiosClient.put(url, body);
  },
  togglePromotion: ({ id, isEnabled }: ITogglePromotionBody) => {
    const url = `/api/promotions/${id}`;
    return axiosClient.put(url, { isEnabled });
  },
};
