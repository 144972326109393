import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { IDiscountType, IGetListResponse, IPromotionAPI } from '@interfaces';
import { RootState } from '.';
import { getListPromotionsAction } from '../actions/promotion';

interface IPromotionState {
  promotions?: IPromotionAPI[];
  totalPromotions: number;
  limitPerPage: number;
}

const initialState: IPromotionState = {
  promotions: undefined,
  totalPromotions: 0,
  limitPerPage: 10,
};

const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    resetPromotion: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getListPromotionsAction.fulfilled,
      (state, action: PayloadAction<IGetListResponse<IPromotionAPI>>) => {
        state.promotions = action.payload.items;
        state.totalPromotions = action.payload.total;
        state.limitPerPage = action.payload.limit;
      },
    );
  },
});

export const selectPromotion = createSelector(
  (state: RootState) => state.promotion,
  (state) => state,
);

export const promotionsTableSelector = createSelector(
  (state: RootState) => state.promotion,
  (state) =>
    state.promotions
      ?.map((promotion) => ({
        key: promotion.id,
        promoTitle: promotion.title,
        discount: promotion.value,
        discountType: promotion.valueType,
        createdAt: moment(promotion.createdAt).format('YYYY/MM/DD'),
        expiryDate: moment(promotion.expiryDate).format('YYYY/MM/DD'),
        isEnabled: promotion.isEnabled,
      }))
      .sort((a, b) => moment(b.createdAt, 'YYYY/MM/DD').diff(moment(a.createdAt, 'YYYY/MM/DD'))),
);

export const { resetPromotion } = promotionSlice.actions;
export default promotionSlice.reducer;
