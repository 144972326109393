import { Radio, type RadioChangeEvent, Space } from 'antd';
import {
  ContainerStyled,
  RadioContainer,
  SectionCreateBasicStyled,
  SelectContainerStyled,
} from './styled';
import { SharedIcon } from 'src/components/Icon';
import { IGenderAPI, ICategoryAPI, ISubcategoryAPI } from '@interfaces';

interface IProps {
  genders?: IGenderAPI[];
  categories?: ICategoryAPI[];
  onSelectedGenderChange: (e: RadioChangeEvent) => Promise<void>;
  onSelectedCategoryChange: (e: RadioChangeEvent) => Promise<void>;
  subCategories?: ISubcategoryAPI[];
  onSelectedSubcategoryChange: (e: RadioChangeEvent) => void;
  selectedGender?: number;
  selectedCategory?: number;
  selectedSubcategory?: number;
}

export const InspirationClothCategorySection: React.FC<IProps> = ({
  genders,
  categories,
  onSelectedGenderChange,
  onSelectedCategoryChange,
  subCategories,
  onSelectedSubcategoryChange,
  selectedGender,
  selectedCategory,
  selectedSubcategory,
}) => {
  return (
    <SectionCreateBasicStyled>
      <ContainerStyled>
        <SelectContainerStyled>
          <h2>Gender</h2>
          {genders && (
            <RadioContainer>
              <Radio.Group
                buttonStyle="solid"
                size="large"
                value={selectedGender}
                onChange={onSelectedGenderChange}
              >
                <Space direction="vertical">
                  {genders.map((gender) => (
                    <Radio.Button style={radioBtnStyles} key={gender.id} value={gender.id}>
                      <span>{gender.gender}</span>
                      <SharedIcon name="rightOutlined" size={18} />
                    </Radio.Button>
                  ))}
                </Space>
              </Radio.Group>
            </RadioContainer>
          )}
        </SelectContainerStyled>
        {categories && (
          <SelectContainerStyled>
            <h2>Category</h2>
            <RadioContainer>
              <Radio.Group
                buttonStyle="solid"
                size="large"
                value={selectedCategory}
                onChange={onSelectedCategoryChange}
              >
                <Space direction="vertical">
                  {categories.map((category) => (
                    <Radio.Button style={radioBtnStyles} key={category.id} value={category.id}>
                      <span className="capitalize">{category.category}</span>
                      <SharedIcon name="rightOutlined" size={18} />
                    </Radio.Button>
                  ))}
                </Space>
              </Radio.Group>
            </RadioContainer>
          </SelectContainerStyled>
        )}
        {categories && subCategories && (
          <SelectContainerStyled>
            <h2>Sub-category</h2>
            <RadioContainer>
              <Radio.Group
                buttonStyle="solid"
                size="large"
                value={selectedSubcategory}
                onChange={onSelectedSubcategoryChange}
              >
                <Space direction="vertical" size="large">
                  {subCategories.map((subCategorie) => (
                    <Radio
                      style={{ fontSize: '1.6rem', textTransform: 'capitalize' }}
                      key={subCategorie.id}
                      value={subCategorie.id}
                    >
                      {subCategorie.subcategory}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </RadioContainer>
          </SelectContainerStyled>
        )}
      </ContainerStyled>
    </SectionCreateBasicStyled>
  );
};

const radioBtnStyles: React.CSSProperties = {
  minWidth: '24rem',
  borderRadius: '1.6rem',
  textTransform: 'capitalize',
};
