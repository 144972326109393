/* eslint-disable eqeqeq */
import classNames from 'classnames';
import React, { Fragment, useCallback, useRef, useState } from 'react';
import { v4 } from 'uuid';
import styled, { css } from 'styled-components';
import Dropzone, { Accept } from 'react-dropzone';

import { ALLOWED_BUCKET_NAMES_OBJECT, FILE_LIMIT_SIZE } from '@configs';
import { APP_COLORS } from '@theme';
import { AppSpin } from 'src/components/AppSpin';
import { SharedIcon } from 'src/components/Icon';
import { AppImage } from '../image';
import { LogApp, appUploadFileFirebase, getFilenNameExtension, useDeviceDetect } from '@utils';

interface IProps extends React.ComponentPropsWithoutRef<'input'> {
  width?: number;
  height?: number;
  size?: number;
  textUpload?: React.ReactNode;
  icon?: React.ReactNode | null;
  previewUrl?: string;
  onUploadStart?: () => void;
  onUploadEnd?: (data: any) => void;
  onRemoveImage?: () => void;
  limit?: number;
  bucketName: ALLOWED_BUCKET_NAMES_OBJECT;
}

const acceptFile = {
  'image/png': ['.png'],
  'image/svg': ['.svg'],
  'image/jpg': ['.jpg'],
  'image/gif': ['.gif'],
  'image/jpeg': ['.jpeg'],
  'image/webp': ['.webp'],
  'image/avif': ['.avif'],
} as Accept;

const renameUploadFile = (originalFile: File, fileNameExt: string): File => {
  return new File([originalFile], [v4(), fileNameExt].join('.'), {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
};

export const UploadImageCard = React.memo<IProps>(
  ({
    previewUrl,
    textUpload = 'Upload',
    icon = <SharedIcon name="upload" size={24} />,
    className,
    width = 200,
    height = 200,
    size,
    onUploadStart,
    onUploadEnd,
    onRemoveImage,
    limit = FILE_LIMIT_SIZE,
    bucketName,
    ...rest
  }) => {
    const [imageUrl, setImageUrl] = useState<string | undefined>(previewUrl);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const dropzoneInputRef = useRef<any>(null);
    const currentDevice = useDeviceDetect();
    const mimeTypeAccept = {
      ...acceptFile,
      ...(currentDevice?.isMobile() && {
        'image/heic': ['.heic'],
        'image/heif': ['.heif'],
      }),
    };

    const handleUpload = async (file: File) => {
      if (!bucketName) return;
      try {
        onUploadStart && onUploadStart();
        setIsLoading(true);
        const res = await appUploadFileFirebase({ file, bucketName });
        onUploadEnd && onUploadEnd(res?.data);
        setImageUrl(res?.data?.url);
      } catch (error: any) {
        LogApp('Upload error', error);
      } finally {
        setIsLoading(false);
      }
    };

    const onDrop = useCallback((files: File[]): void => {
      setError(undefined);
      if (files != null && files.length > 0) {
        if (limit && files[0].size > limit) {
          setError(`Maximum upload file size: ${Math.floor(limit / Math.pow(1024, 2))}MB`);
          return;
        }
        const fileNameExt = getFilenNameExtension(files[0]);
        const renamedFile = renameUploadFile(files[0], fileNameExt);

        const formData = new FormData();
        formData.append('file', renamedFile);

        try {
          onUploadEnd && handleUpload(renamedFile);
        } catch (error: any) {
          setError(error || 'Upload image failed');
        }
      }
    }, []);

    const dragOver = (e: any) => {
      e.preventDefault();
    };

    const dragEnter = (e: any) => {
      e.preventDefault();
    };

    const dragLeave = (e: any) => {
      e.preventDefault();
    };

    const handleDeleteFile = (
      e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    ): void => {
      e.stopPropagation();
      e.preventDefault();
      e.nativeEvent.preventDefault();
      setImageUrl(undefined);
      onRemoveImage && onRemoveImage();
    };

    return (
      <AppSpin spinning={isLoading}>
        <Dropzone
          onDrop={onDrop}
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          accept={mimeTypeAccept}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <Wrapper
              $height={height}
              $width={width}
              $size={size}
              $error={error}
              {...getRootProps({ className: 'dropzone' })}
            >
              <input ref={dropzoneInputRef} {...rest} {...getInputProps()} />
              <span className={classNames('shared-upload-wrapper', className)} role="button">
                {((!imageUrl && !previewUrl) || error) && (
                  <Fragment>
                    <span className="shared-upload-text">{textUpload}</span>
                    {icon && <span className="shared-upload-icon">{icon}</span>}
                    {error && <span className="shared-upload-error">{error}</span>}
                  </Fragment>
                )}
                {(imageUrl || previewUrl) && !error && (
                  <figure className="shared-upload-preview">
                    <AppImage
                      containerClassName="shared-upload-preview-container"
                      className="shared-upload-preview-img"
                      firebaseResourceKey={imageUrl}
                      draggable={false}
                    />
                    <div className="shared-upload-preview-action">
                      <button className="action-btn" onClick={handleDeleteFile}>
                        <SharedIcon name="close" size={18} />
                      </button>
                    </div>
                  </figure>
                )}
              </span>
            </Wrapper>
          )}
        </Dropzone>
      </AppSpin>
    );
  },
);

UploadImageCard.displayName = 'UploadImageCard';

interface WrapperProps {
  $width: number;
  $height: number;
  $size?: number;
  $error?: string;
}

const Wrapper = styled.div<WrapperProps>`
  display: inline-block;
  cursor: pointer;
  input[type='file'] {
    display: none;
  }
  .shared-upload-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    border-radius: 0.6rem;
    border: 0.1rem dashed ${APP_COLORS.normal};
    ${(props) =>
      props.$error &&
      css`
        border: 0.1rem solid ${APP_COLORS.red500};
      `};
    width: ${(props) => props.$size || props.$width}px;
    height: ${(props) => props.$size || props.$height}px;
    padding: 0.6rem;
    background-color: ${APP_COLORS.gray200};
    text-align: center;
    transition: all 0.3s ease-in-out;
    &:hover {
      border-color: ${APP_COLORS.primary};
    }
    & > .shared-upload-text {
      font-size: 1.6rem;
      color: ${APP_COLORS.text};
    }
    & > .shared-upload-icon {
      color: ${APP_COLORS.primary};
    }
    & > .shared-upload-error {
      font-size: 1.4rem;
      color: ${APP_COLORS.red500};
      width: 90%;
    }
    & > .shared-upload-preview {
      margin: 0;
      width: 100%;
      height: 100%;
      border-radius: 0.6rem;
      overflow: hidden;
      position: relative;
      & > .shared-upload-preview-container {
        & > .shared-upload-preview-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      & > .shared-upload-preview-action {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1.2rem 0.8rem;
        background-image: linear-gradient(
          transparent,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.4),
          rgba(0, 0, 0, 0.6)
        );
        position: absolute;
        left: 0;
        bottom: 0;
        & > .action-btn {
          border: 0.1rem solid ${APP_COLORS.white};
          color: ${APP_COLORS.white};
          padding: 0.6rem;
          border-radius: 0.4rem;
          transition: all 0.3s ease-in-out;
          &:hover {
            background-color: ${APP_COLORS.white};
            color: ${APP_COLORS.dark};
          }
          &:active {
            transform: scale(1.05);
          }
        }
      }
    }
  }
`;
