import { memo, useEffect, useState } from 'react';
import { MenuProps, Tooltip } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { NavMenuIcon, Sidebar } from '@components';
import {
  selectApp,
  selectLayout,
  setCurrentPage,
  setNavCurrentIsOpenKey,
  setNavCurrentKey,
  setSidebarCollapsed,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import {
  enumMainNavKey,
  enumNavKey,
  INITIAL_PAGE,
  MAT_SM_SCREEN_WIDTH,
  PATH_CUSTOMER,
  SIDEBAR_DATA,
  PATH_TAILOR,
  PATH_PROMOTION,
  PATH_INSPIRATION,
} from '@configs';
import { LogApp, useForceUpdate, useMediaQuery } from '@utils';
import { Link } from 'react-router-dom';

export const SidebarModule = memo(() => {
  const { themeMode } = useAppSelector(selectApp);
  const { sidebarCollapsed } = useAppSelector(selectLayout);

  // const sidebarData =
  //   userRole === enumRole.ADMIN || userRole === enumRole.S_MANAGER
  //     ? SIDEBAR_DATA
  //     : STAFF_SIDEBAR_DATA;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const forceUpdate = useForceUpdate();

  const isSMScreen = useMediaQuery(`(max-width:${MAT_SM_SCREEN_WIDTH})`);

  const [openKeys, setOpenKeys] = useState<Array<string>>([]);

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((item) => openKeys.indexOf(item) === -1);
    // if (rootSubmenuKeys?.indexOf(latestOpenKey!) === -1) {
    // setOpenKeys(keys);
    // } else {
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    // }
  };

  const handleChangeCollapsed = (value: boolean) => {
    dispatch(setSidebarCollapsed(value));
  };

  const navbarItems: MenuProps['items'] = SIDEBAR_DATA.map((item, _) => {
    // // Remove later
    // if (item.pathKey === enumNavKey.PROMOTIONS) {
    //   return {
    //     key: item.pathKey,
    //     icon: (
    //       <Tooltip placement="right" title={item.label}>
    //         <NavMenuIcon pathKey={item.pathKey} path={item.path} className="menu__icon" />
    //       </Tooltip>
    //     ),
    //     label: <span className="cursor-auto app-menu-title">{item.label}</span>,
    //   };
    // }

    return {
      key: item.pathKey,
      icon: (
        <Tooltip placement="right" title={item.label}>
          <NavMenuIcon pathKey={item.pathKey} path={item.path} className="menu__icon" />
        </Tooltip>
      ),
      label: (
        <Link to={item.path} className="app-menu-title">
          {item.label}
        </Link>
        // <span className="app-menu-title">{item.label}</span>
      ),
    };
  });

  const handleSelectMenuItem: MenuProps['onClick'] = (e) => {
    const currentKey = Number(e.key);
    switch (currentKey) {
      case enumNavKey.TAILORS:
        dispatch(setNavCurrentIsOpenKey(enumMainNavKey.TAILORS));
        dispatch(setCurrentPage(INITIAL_PAGE));
        break;
      case enumNavKey.CUSTOMERS:
        dispatch(setNavCurrentIsOpenKey(enumMainNavKey.CUSTOMERS));
        dispatch(setCurrentPage(INITIAL_PAGE));
        break;
      case enumNavKey.PROMOTIONS:
        dispatch(setNavCurrentIsOpenKey(enumMainNavKey.PROMOTIONS));
        dispatch(setCurrentPage(INITIAL_PAGE));
        break;
      case enumNavKey.INSPIRATIONS:
        dispatch(setNavCurrentIsOpenKey(enumMainNavKey.INSPIRATIONS));
        dispatch(setCurrentPage(INITIAL_PAGE));
        break;
      default:
        return;
    }
    // dispatch(setNavCurrentKey(currentKey));
  };

  useEffect(() => {
    switch (true) {
      case pathname?.includes(PATH_TAILOR):
        dispatch(setNavCurrentKey(enumNavKey.TAILORS));
        break;
      case pathname?.includes(PATH_CUSTOMER):
        dispatch(setNavCurrentKey(enumNavKey.CUSTOMERS));
        break;
      case pathname?.includes(PATH_PROMOTION):
        dispatch(setNavCurrentKey(enumNavKey.PROMOTIONS));
        break;
      case pathname?.includes(PATH_INSPIRATION):
        dispatch(setNavCurrentKey(enumNavKey.INSPIRATIONS));
        break;
      default:
        dispatch(setNavCurrentKey(undefined));
        dispatch(setNavCurrentIsOpenKey(undefined));
        return;
    }
  }, [pathname]);

  return (
    <Sidebar
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      isSMScreen={isSMScreen}
      collapsed={sidebarCollapsed}
      onChangeCollapsed={handleChangeCollapsed}
      themeMode={themeMode}
      navbarItems={navbarItems}
      onSelectMenuItem={handleSelectMenuItem}
    />
  );
});
