import { categoryAPI } from '@api';
import { ICategoryAPI } from '@interfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';

const prefix = 'category';

export const getCategoryByGenderIdAction = createAsyncThunk<ICategoryAPI[], number>(
  `${prefix}/getCategoryByGenderIdAction`,
  async (genderId) => {
    try {
      const response = await categoryAPI.getCategoryByGender(genderId);
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
);
