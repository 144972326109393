import {
  enumCampaignStatus,
  enumChanceProcess,
  enumChanceStatus,
  enumDashboardFilter,
  enumDashboardDataFilter,
  enumGender,
  enumOrderStatus,
  enumRole,
  enumUserStatus,
  enumVoucherStatus,
  enumVoucherType,
} from './enum';

export const DASHBOARD_FILTER_OPTIONS = [
  {
    value: enumDashboardFilter.DAY,
    label: '7 ngày qua',
  },
  {
    value: enumDashboardFilter.WEEK,
    label: 'Theo tuần',
  },
  {
    value: enumDashboardFilter.MONTH,
    label: 'Theo tháng',
  },
];

export const DASHBOARD_FILTER_LAST_OPTIONS = [
  {
    value: enumDashboardDataFilter.WEEK,
    label: 'Trong tuần',
  },
  {
    value: enumDashboardDataFilter.MONTH,
    label: 'Trong tháng',
  },
  {
    value: enumDashboardDataFilter.YEAR,
    label: 'Trong năm',
  },
];

export const ROLE_OPTIONS = [
  {
    value: enumRole.S_MANAGER,
    label: 'Quản trị hệ thống',
  },
  {
    value: enumRole.B_MANAGER,
    label: 'Quản lý',
  },
  {
    value: enumRole.STAFF,
    label: 'Nhân viên',
  },
];

export const ORDER_CHANGE_STATUS_OPTIONS = [
  {
    value: enumOrderStatus.IS_NOT_ACCEPTED,
    label: 'Đơn mới',
  },
  {
    value: enumOrderStatus.IS_NOT_DELIVERED,
    label: 'Đang xử lý',
  },
  {
    value: enumOrderStatus.IS_DELIVERING,
    label: 'Đang giao',
  },
  {
    value: enumOrderStatus.IS_DELIVERED,
    label: 'Đã giao',
  },
  {
    value: enumOrderStatus.IS_CANCELED,
    label: 'Huỷ bỏ',
  },
];

export const ORDER_STATUS_OPTIONS = [
  {
    value: '',
    label: 'Tất cả',
  },
  {
    value: enumOrderStatus.IS_NOT_ACCEPTED,
    label: 'Đơn mới',
  },
  {
    value: enumOrderStatus.IS_NOT_DELIVERED,
    label: 'Đang xử lý',
  },
  {
    value: enumOrderStatus.IS_DELIVERING,
    label: 'Đang giao',
  },
  {
    value: enumOrderStatus.IS_DELIVERED,
    label: 'Đã giao',
  },
  {
    value: enumOrderStatus.IS_CANCELED,
    label: 'Huỷ bỏ',
  },
];

export const INVOICE_STATUS_OPTIONS = [
  {
    value: '',
    label: 'Tất cả',
  },
  {
    value: enumOrderStatus.IS_DELIVERED,
    label: 'Chưa thanh toán',
  },
  {
    value: enumOrderStatus.IS_PAID,
    label: 'Đã thanh toán',
  },
  {
    value: enumOrderStatus.IS_REFUND,
    label: 'Hoàn tiền',
  },
  {
    value: enumOrderStatus.IS_CANCELED,
    label: 'Huỷ bỏ',
  },
];

export const CAMPAIGN_STATUS_OPTIONS = [
  {
    value: enumCampaignStatus.DRAFT,
    label: 'Nháp',
  },
  {
    value: enumCampaignStatus.NOT_ACTIVE,
    label: 'Tạm dừng/sắp diễn ra',
  },
  {
    value: enumCampaignStatus.ACTIVE,
    label: 'Đang diễn ra',
  },
  {
    value: enumCampaignStatus.END,
    label: 'Đã đóng',
  },
  {
    value: enumCampaignStatus.CANCELED,
    label: 'Đã hủy bỏ',
  },
];

export const CAMPAIGN_STATUS_FILTER_OPTIONS = [
  {
    value: '',
    label: 'Tất cả',
  },
  {
    value: enumCampaignStatus.DRAFT,
    label: 'Nháp',
  },
  {
    value: enumCampaignStatus.NOT_ACTIVE,
    label: 'Tạm dừng/sắp diễn ra',
  },
  {
    value: enumCampaignStatus.ACTIVE,
    label: 'Đang diễn ra',
  },
  {
    value: enumCampaignStatus.END,
    label: 'Đã đóng',
  },
  {
    value: enumCampaignStatus.CANCELED,
    label: 'Đã hủy bỏ',
  },
];

export const VOUCHER_STATUS_OPTIONS = [
  {
    value: enumVoucherStatus.ACTIVE,
    label: 'Hoạt động',
  },
  {
    value: enumVoucherStatus.BLOCK,
    label: 'Khoá',
  },
];

export const VOUCHER_TYPE_OPTIONS = [
  {
    value: enumVoucherType.VOUCHER,
    label: 'Voucher khuyến mãi',
  },
  {
    value: enumVoucherType.CODE,
    label: 'Mã khuyến mãi',
  },
];

export const USER_STATUS_OPTIONS = [
  {
    value: enumUserStatus.ACTIVE,
    label: 'Hoạt động',
  },
  {
    value: enumUserStatus.INACTIVE,
    label: 'Khóa',
  },
];

export const CHANCE_STEP_OPTIONS = [
  {
    value: enumChanceProcess.STEP_ONE,
    label: 'Mở đầu',
  },
  {
    value: enumChanceProcess.STEP_TWO,
    label: 'Customer quan tâm',
  },
  {
    value: enumChanceProcess.STEP_THREE,
    label: 'Tư vấn - giới thiệu sản phẩm',
  },
  {
    value: enumChanceProcess.STEP_FOUR,
    label: 'Báo giá',
  },
  {
    value: enumChanceProcess.STEP_FIVE,
    label: 'Thương thảo hợp đồng',
  },
  {
    value: enumChanceProcess.STEP_SIX,
    label: 'Kết thúc',
  },
];

export const CHANCE_STATUS_OPTIONS = [
  {
    value: enumChanceStatus.IN_PROCESS,
    label: 'Đang tiến hành',
  },
  {
    value: enumChanceStatus.FAILED_END,
    label: 'Kết thúc thất bại',
  },
  {
    value: enumChanceStatus.SUCCESS_END,
    label: 'Thành công',
  },
];

export const CHANCE_STATUS_FILTER_OPTIONS = [
  { value: '', label: 'Tất cả' },
  {
    value: enumChanceStatus.IN_PROCESS,
    label: 'Đang tiến hành',
  },
  {
    value: enumChanceStatus.FAILED_END,
    label: 'Kết thúc thất bại',
  },
  {
    value: enumChanceStatus.SUCCESS_END,
    label: 'Thành công',
  },
];
