import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllInspirationsAction, getInspirationDetailsAction } from '../actions/inspiration';
import {
  IColothesAPI,
  ICreateInspirationForm,
  IPersonalizationItem,
  IInspiration,
  IInspirationDetails,
  IGenderAPI,
  ICategoryAPI,
  ISubcategoryAPI,
  IColorRangeAPI,
  ITintAPI,
  IFabricAPI,
  IPropertyAPI,
  IPatternAPI,
  IElementAPI,
  IUploadInspirationPicture,
  IGetListResponse,
} from '@interfaces';
import { RootState } from '.';
import {
  basicCreationAction,
  getCategoryByGenderIdAction,
  getColorRangeAction,
  getElementsAction,
  getPropertiesAction,
  getSubcategoryByCategoryIdAction,
  getTintsByColorAction,
  getUserFabricsAction,
} from '../actions';

import { enumImagePosition } from '@configs';
import { getGendersAction } from '../actions/gender';
import { getPatternsAction } from '../actions/pattern';
import { isJSON } from '@utils';

interface IIspirationState {
  inspirations?: IInspiration[];
  totalInspirations: number;
  limitPerPage: number;
  currentInspiration?: IInspirationDetails;
  listGenders?: IGenderAPI[];
  listCategories?: ICategoryAPI[];
  listSubcategories?: ISubcategoryAPI[];
  listColors?: IColorRangeAPI[];
  listTints?: ITintAPI[];
  listFabrics?: IFabricAPI[];
  listProperties?: IPropertyAPI[];
  listPatterns?: IPatternAPI[];
  listElements?: IElementAPI[];
  createInspirationForm: ICreateInspirationForm;
}

const initialState: IIspirationState = {
  inspirations: undefined,
  totalInspirations: 0,
  limitPerPage: 10,
  currentInspiration: undefined,
  listGenders: undefined,
  listCategories: undefined,
  listSubcategories: undefined,
  listColors: undefined,
  listFabrics: undefined,
  listProperties: undefined,
  listPatterns: undefined,
  listElements: undefined,
  createInspirationForm: {
    genderId: undefined,
    categoryId: undefined,
    subcategoryId: undefined,
    fabricId: undefined,
    properties: [],
    patternId: undefined,
    onHomepage: true,
    title: undefined,
    description: undefined,
    indicativePrice: undefined,
    tintHexCode: undefined,
    rangeHexCode: undefined,
    inspirationPictures: [
      {
        mediaId: undefined,
        imagePosition: enumImagePosition.FRONT,
        url: undefined,
      },
      {
        mediaId: undefined,
        imagePosition: enumImagePosition.PROFILE,
        url: undefined,
      },
      {
        mediaId: undefined,
        imagePosition: enumImagePosition.BACK,
        url: undefined,
      },
    ],
    clothes: undefined,
    personalizationItems: [],
  },
};

const inspirationSlice = createSlice({
  name: 'inspiration',
  initialState,
  reducers: {
    resetInspiration: () => initialState,
    setCategories: (state, action: PayloadAction<ICategoryAPI[] | undefined>) => {
      state.listCategories = action.payload;
    },
    setSubcategories: (state, action: PayloadAction<ISubcategoryAPI[] | undefined>) => {
      state.listSubcategories = action.payload;
    },
    setTints: (state, action: PayloadAction<ITintAPI[] | undefined>) => {
      state.listTints = action.payload;
    },
    setInspirationPicture: (
      state,
      action: PayloadAction<{
        position: enumImagePosition;
        url?: string;
        mediaId?: number;
      }>,
    ) => {
      const { position, url, mediaId } = action.payload;
      const inspirationPictureIndex = state.createInspirationForm.inspirationPictures.findIndex(
        (p) => p.imagePosition === position,
      );
      if (typeof inspirationPictureIndex !== 'number' || inspirationPictureIndex === -1) return;
      state.createInspirationForm.inspirationPictures[inspirationPictureIndex].mediaId = mediaId;
      state.createInspirationForm.inspirationPictures[inspirationPictureIndex].url = url;
    },
    setSelectedGender: (state, action: PayloadAction<number | undefined>) => {
      state.createInspirationForm.genderId = action.payload;
    },
    setSelectedCategory: (state, action: PayloadAction<number | undefined>) => {
      state.createInspirationForm.categoryId = action.payload;
    },
    setSelectedSubcategory: (state, action: PayloadAction<number | undefined>) => {
      state.createInspirationForm.subcategoryId = action.payload;
    },
    setSelectedColor: (state, action: PayloadAction<string | undefined>) => {
      state.createInspirationForm.rangeHexCode = action.payload;
    },
    setSelectedTint: (state, action: PayloadAction<string | undefined>) => {
      state.createInspirationForm.tintHexCode = action.payload;
    },
    setSelectedFabric: (state, action: PayloadAction<number>) => {
      state.createInspirationForm.fabricId = action.payload;
    },
    setSelectedProperties: (state, action: PayloadAction<Array<Omit<IPropertyAPI, 'family'>>>) => {
      state.createInspirationForm.properties = action.payload;
    },
    setSelectedPattern: (state, action: PayloadAction<number>) => {
      state.createInspirationForm.patternId = action.payload;
    },
    toggleHighlightOnHomePage: (state, action: PayloadAction<boolean>) => {
      state.createInspirationForm.onHomepage = action.payload;
    },
    addPersonalizationItem: (state, action: PayloadAction<IPersonalizationItem>) => {
      if (
        !state.createInspirationForm.personalizationItems.some(
          (item) => item.elementId === action.payload.elementId,
        )
      ) {
        state.createInspirationForm.personalizationItems = [
          ...state.createInspirationForm.personalizationItems,
          action.payload,
        ];
      }
    },
    removePersonalizationItem: (state, action: PayloadAction<number>) => {
      state.createInspirationForm.personalizationItems =
        state.createInspirationForm.personalizationItems.filter(
          (item) => item.elementId !== action.payload,
        );
    },
    updatePersonalizationItem: (state, action: PayloadAction<IPersonalizationItem>) => {
      state.createInspirationForm.personalizationItems =
        state.createInspirationForm.personalizationItems.map((item) => {
          if (item.elementId === action.payload.elementId) {
            return action.payload;
          }
          return item;
        });
    },
    setInspirationInfo: (
      state,
      action: PayloadAction<{ title: string; description: string; indicativePrice: number }>,
    ) => {
      state.createInspirationForm.title = action.payload.title;
      state.createInspirationForm.description = action.payload.description;
      state.createInspirationForm.indicativePrice = action.payload.indicativePrice;
    },
    resetListPersonalizations: (state) => {
      state.createInspirationForm.personalizationItems =
        initialState.createInspirationForm.personalizationItems;
    },
    resetCreateInspiration: (state) => {
      state.createInspirationForm = initialState.createInspirationForm;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllInspirationsAction.fulfilled,
      (state, action: PayloadAction<IGetListResponse<IInspiration>>) => {
        state.inspirations = action.payload.items;
        state.totalInspirations = action.payload.total;
        state.limitPerPage = action.payload.limit;
      },
    );
    builder.addCase(
      getInspirationDetailsAction.fulfilled,
      (state, action: PayloadAction<IInspirationDetails>) => {
        state.currentInspiration = action.payload;
      },
    );
    builder.addCase(getGendersAction.fulfilled, (state, action: PayloadAction<IGenderAPI[]>) => {
      state.listGenders = action.payload;
    });
    builder.addCase(
      getCategoryByGenderIdAction.fulfilled,
      (state, action: PayloadAction<ICategoryAPI[]>) => {
        state.listCategories = action.payload;
      },
    );
    builder.addCase(
      getSubcategoryByCategoryIdAction.fulfilled,
      (state, action: PayloadAction<ISubcategoryAPI[]>) => {
        state.listSubcategories = action.payload;
      },
    );
    builder.addCase(
      getColorRangeAction.fulfilled,
      (state, action: PayloadAction<IColorRangeAPI[]>) => {
        state.listColors = action.payload.map((color) => {
          if (isJSON(color.range_hex_code)) {
            const rangeHexCodeObj = JSON.parse(color.range_hex_code);
            const linearColorHexCode = `linear-gradient(to right, ${Object.values(
              rangeHexCodeObj,
            ).join(', ')})`;

            return {
              ...color,
              linearGradientCode: linearColorHexCode,
            };
          }
          return color;
        });
      },
    );
    builder.addCase(getTintsByColorAction.fulfilled, (state, action: PayloadAction<ITintAPI[]>) => {
      state.listTints = action.payload.map((tint) => {
        if (isJSON(tint.tintHexCode)) {
          const tintHexCodeObj = JSON.parse(tint.tintHexCode);
          const linearColorHexCode = `linear-gradient(to right, ${Object.values(
            tintHexCodeObj,
          ).join(', ')})`;

          return {
            ...tint,
            linearGradientCode: linearColorHexCode,
          };
        }
        return tint;
      });
    });
    builder.addCase(
      getUserFabricsAction.fulfilled,
      (state, action: PayloadAction<IFabricAPI[]>) => {
        state.listFabrics = action.payload;
      },
    );
    builder.addCase(
      getPropertiesAction.fulfilled,
      (state, action: PayloadAction<IPropertyAPI[]>) => {
        state.listProperties = action.payload;
      },
    );
    builder.addCase(getPatternsAction.fulfilled, (state, action: PayloadAction<IPatternAPI[]>) => {
      state.listPatterns = action.payload;
    });
    builder.addCase(getElementsAction.fulfilled, (state, action: PayloadAction<IElementAPI[]>) => {
      state.listElements = action.payload;
    });
    builder.addCase(basicCreationAction.fulfilled, (state, action: PayloadAction<IColothesAPI>) => {
      state.createInspirationForm.clothes = action.payload;
    });
  },
});

export const inspirationSelect = createSelector(
  (state: RootState) => state.inspiration,
  (state) => state,
);

export const inspirationsTableSelector = createSelector(
  (state: RootState) => state.inspiration,
  (state) =>
    state.inspirations?.map((inspiration) => ({
      key: inspiration.id,
      image: inspiration?.pictures?.find((picture) => picture.pictureUrl)?.pictureUrl,
      title: inspiration?.title,
      price: inspiration?.indicativePrice,
      onHomepage: inspiration?.onHomepage,
      enabled: inspiration?.enabled,
      createdAt: inspiration?.createdAt,
    })),
);

export const inspirationDetailsSelector = createSelector(
  (state: RootState) => state.inspiration,
  (state) => {
    return {
      pictures: state.currentInspiration?.pictures,
      title: state.currentInspiration?.title,
      price: state.currentInspiration?.indicativePrice,
      description: state.currentInspiration?.description,
      gender: state.currentInspiration?.gender?.gender,
      category: state.currentInspiration?.category?.category,
      subcategory: state.currentInspiration?.subcategory?.subcategory,
      color: state.currentInspiration?.color,
      material: state.currentInspiration?.fabric?.fabric,
      pattern: state.currentInspiration?.pattern,
      properties: state.currentInspiration?.properties,
      personalizations: state.currentInspiration?.clothes.find(
        (clothes) => clothes.inspirationId === state.currentInspiration?.id,
      )?.clothesElement,
    };
  },
);

export const createInspirationFormSelector = createSelector(
  (state: RootState) => state.inspiration,
  (state) => state.createInspirationForm,
);

export const {
  resetInspiration,
  setInspirationPicture,
  setCategories,
  setSubcategories,
  setTints,
  setSelectedGender,
  setSelectedCategory,
  setSelectedSubcategory,
  setSelectedColor,
  setSelectedTint,
  setSelectedFabric,
  setSelectedProperties,
  setSelectedPattern,
  toggleHighlightOnHomePage,
  setInspirationInfo,
  addPersonalizationItem,
  removePersonalizationItem,
  updatePersonalizationItem,
  resetListPersonalizations,
  resetCreateInspiration,
} = inspirationSlice.actions;

export default inspirationSlice.reducer;
