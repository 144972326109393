import { KeyOutlined } from '@ant-design/icons';
import { ChangePasswordModule } from '@modules';
import { Modal, type ModalProps } from 'antd';
import styled from 'styled-components';

export const ChangePasswordModal = (props: ModalProps): JSX.Element => {
  const { open, onCancel } = props;

  return (
    <Modal open={open} onCancel={onCancel} title={<ModalTitle />} footer={null} zIndex={99999}>
      <ChangePasswordModule />
    </Modal>
  );
};

const ModalTitle: React.FC = () => {
  return (
    <ModalTitleWrapper>
      <KeyOutlined style={{ fontSize: '2.4rem' }} />
      <span>Change password</span>
    </ModalTitleWrapper>
  );
};

const ModalTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 3rem;
`;
