import { inspirationAPI, uploadAPI } from '@api';
import type {
  IAddInspirationParams,
  IAddInspirationResponse,
  IClothesElement,
  IGetListParams,
  IGetListResponse,
  IInspiration,
  IInspirationClothes,
  IInspirationDetails,
  IInspirationPicture,
  IToggleInspirationHightlightParams,
  IToggleInspirationStatusParams,
  IUpdateInspirationParams,
} from '@interfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFirebaseImageKey, showAppToast } from '@utils';
import { setLoading } from '../Slices';
import { ALLOWED_BUCKET_NAMES_OBJECT } from '@configs';

const prefix = 'inspiration';

export const getAllInspirationsAction = createAsyncThunk<
  IGetListResponse<IInspiration>,
  IGetListParams
>(`${prefix}/getAllInspirationsAction`, async (params) => {
  try {
    const response = await inspirationAPI.getAllInspirations(params);
    return response.data;
  } catch (error: any) {
    showAppToast(error.response.data.message, 'error', 'light');
  }
});

export const toggleInspirationHightlightAction = createAsyncThunk<
  void,
  IToggleInspirationHightlightParams
>(`${prefix}/toggleInspirationHightlightAction`, async (params) => {
  try {
    const response: any = await inspirationAPI.toggleInspirationHightlight(params);
    if (response.success) {
      showAppToast('Inspiration hightlight has been updated successfully', 'success', 'light');
    }
  } catch (error: any) {
    showAppToast(error.response.data.message, 'error', 'light');
  }
});

export const toggleInspirationStatusAction = createAsyncThunk<void, IToggleInspirationStatusParams>(
  `${prefix}/toggleInspirationStatusAction`,
  async (params) => {
    try {
      const response: any = await inspirationAPI.toggleInspirationStatus(params);
      if (response.success) {
        showAppToast('Inspiration status has been updated successfully', 'success', 'light');
      }
    } catch (error: any) {
      showAppToast(error.response.data.message, 'error', 'light');
    }
  },
);

export const getInspirationDetailsAction = createAsyncThunk<IInspirationDetails, number>(
  `${prefix}/getInspirationDetailsAction`,
  async (id, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response: any = await inspirationAPI.getInspirationDetails(id);
      if (!response.success) {
        showAppToast(response.data?.message, 'error', 'light');
      }
      return response.data;
    } catch (error: any) {
      showAppToast(error.response.data.message, 'error', 'light');
    } finally {
      dispatch(setLoading(false));
    }
  },
);

export const updateInspirationAction = createAsyncThunk<void, IUpdateInspirationParams>(
  `${prefix}/updateInspirationAction`,
  async (params) => {
    try {
      const response: any = await inspirationAPI.updateInspiration(params);
      if (response.success) {
        showAppToast('Updated inspiration successfully', 'success', 'light');
      }
    } catch (error: any) {
      showAppToast(error.response.data.message, 'error', 'light');
    }
  },
);

export const addInspirationAction = createAsyncThunk<
  IAddInspirationResponse,
  IAddInspirationParams
>(`${prefix}/addInspirationAction`, async (params) => {
  try {
    const response = await inspirationAPI.addInspiration(params);
    return response.data;
  } catch (error: any) {
    showAppToast(error.response.data.message, 'error', 'light');
  }
});
