import { StyledSection } from '@components';
import { Button, Collapse } from 'antd';
import styled from 'styled-components';
import { CaretRightOutlined } from '@ant-design/icons';
import {
  InspirationClothCategoryModule,
  InspirationColorPrincipleModule,
  InspirationInfoFormModule,
  InspirationMaterialPrincipleModule,
  InspirationPatternPrincipleModule,
  InspirationUploadPhotoModule,
} from '@modules';
import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';
import { BaseSyntheticEvent } from 'react';
import { IInspirationFields } from '@interfaces';

interface IProps {
  handleReturn: () => void;
  handleCreateBasic: (
    e?: BaseSyntheticEvent<IInspirationFields, any, any> | undefined
  ) => Promise<void>;
  register: UseFormRegister<FieldValues>;
  errors: Partial<FieldErrorsImpl<any>>;
}

export const InspirationCreateBasicSection: React.FC<IProps> = ({
  handleReturn,
  handleCreateBasic,
  register,
  errors,
}) => {
  return (
    <StyledSection>
      {/* @ts-ignore */}
      <FormContainerStyled onSubmit={handleCreateBasic}>
        <CollapseStyled
          defaultActiveKey={['1']}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined style={{ fontSize: '1.6rem' }} rotate={isActive ? 90 : 0} />
          )}
        >
          <Collapse.Panel header="Inspiration Info" key="1">
            <InspirationInfoFormModule register={register} errors={errors} />
          </Collapse.Panel>
          <Collapse.Panel header="Photo Inspiration" key="2">
            <InspirationUploadPhotoModule />
          </Collapse.Panel>
          <Collapse.Panel header="Cloth Category" key="3">
            <InspirationClothCategoryModule />
          </Collapse.Panel>
          <Collapse.Panel header="Color Principle" key="4">
            <InspirationColorPrincipleModule />
          </Collapse.Panel>
          <Collapse.Panel header="Material Principle" key="5">
            <InspirationMaterialPrincipleModule />
          </Collapse.Panel>
          <Collapse.Panel header="Pattern Principle" key="6">
            <InspirationPatternPrincipleModule />
          </Collapse.Panel>
        </CollapseStyled>
        <ActionStyled>
          <Button htmlType="button" size="large" shape="round" onClick={handleReturn}>
            Return
          </Button>
          <Button htmlType="submit" type="primary" size="large" shape="round">
            Next
          </Button>
        </ActionStyled>
      </FormContainerStyled>
    </StyledSection>
  );
};

const FormContainerStyled = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
`;

const CollapseStyled = styled(Collapse)`
  .ant-collapse-header {
    display: flex;
    align-items: center !important;
    justify-content: center;
    & > .ant-collapse-expand-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & > .ant-collapse-header-text {
      font-size: 2.4rem;
    }
  }
`;

const ActionStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.2rem;
`;
