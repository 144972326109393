import styled from 'styled-components';
import ReactLoading from 'react-loading';
import { MAIN_THEME_DATA } from '@configs';

export const LoadingScreen: React.FC = () => {
  return (
    <Container>
      <ReactLoading type="spin" color={MAIN_THEME_DATA.primaryColor} />
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fefefe;
`;
