import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';
import { InspirationInfoFormSection } from '@components';
import {
  createInspirationFormSelector,
  toggleHighlightOnHomePage,
  useAppDispatch,
  useAppSelector,
} from '@redux';

interface IProps {
  register: UseFormRegister<FieldValues>;
  errors: Partial<FieldErrorsImpl<any>>;
}

export const InspirationInfoFormModule: React.FC<IProps> = ({ register, errors }) => {
  const dispatch = useAppDispatch();
  const { onHomepage } = useAppSelector(createInspirationFormSelector);

  const onSwitchChange = (checked: boolean): void => {
    dispatch(toggleHighlightOnHomePage(checked));
  };

  return (
    <InspirationInfoFormSection
      register={register}
      errors={errors}
      onSwitchChange={onSwitchChange}
      isOnHomePage={onHomepage}
    />
  );
};
