import { colorAPI } from '@api';
import { IColorRangeAPI, ITintAPI } from '@interfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';

const prefix = '/color';

export const getColorRangeAction = createAsyncThunk<IColorRangeAPI[], void>(
  `${prefix}/getColorRangeAction`,
  async () => {
    try {
      const response = await colorAPI.getColorRange();
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getTintsByColorAction = createAsyncThunk<ITintAPI[], string>(
  `${prefix}/getTintsByColorAction`,
  async (colorCode) => {
    try {
      const response = await colorAPI.getTintsByColor(colorCode);
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
);
