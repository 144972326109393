import { Fragment, memo } from 'react';

import { Header } from '@components';
import {
  selectApp,
  selectLayout,
  setShowChangePasswordModal,
  setShowHeaderMenu,
  setSidebarCollapsed,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { ChangePasswordModal } from '@pages';

export const HeaderNodule = memo(() => {
  const dispatch = useAppDispatch();

  const { themeMode, showChangePasswordModal } = useAppSelector(selectApp);
  const { sidebarCollapsed, showHeaderMenu } = useAppSelector(selectLayout);

  const handleChangeCollapsed = (value: boolean) => {
    dispatch(setSidebarCollapsed(value));
  };

  const handleToggleDropdown = () => {
    dispatch(setShowHeaderMenu(!showHeaderMenu));
  };

  const handleCloseChangePasswordModal = (): void => {
    dispatch(setShowChangePasswordModal(false));
  };

  return (
    <Fragment>
      <Header
        collapsed={sidebarCollapsed}
        changeCollapsed={handleChangeCollapsed}
        themeMode={themeMode}
        showMenuDropdown={showHeaderMenu}
        handleToggleDropdown={handleToggleDropdown}
      />
      <ChangePasswordModal
        open={showChangePasswordModal}
        onCancel={handleCloseChangePasswordModal}
      />
    </Fragment>
  );
});
