import { IElementAPI } from '@interfaces';
import React from 'react';
import { AppModal } from 'src/components/AppModal';
import { ModalContainerStyled } from './styled';
import { Button, Divider, Empty, Space } from 'antd';
import styled from 'styled-components';

interface IProps {
  open: boolean;
  onClose: () => void;
  groupedElements?: {
    [x: string]: _.Omit<IElementAPI, 'elementFamily'>[];
  };
  handleAddPersonalizationItem: (elementId: number) => void;
}

export const SelectElementModal: React.FC<IProps> = ({
  open,
  onClose,
  groupedElements,
  handleAddPersonalizationItem,
}) => {
  return (
    <AppModal open={open} onClose={onClose}>
      <ContainerStyled>
        {groupedElements && (
          <Space direction="vertical" split={<Divider />}>
            {Object.entries(groupedElements).map(([family, elements]) => (
              <ElementWrapperStyled key={family}>
                <h3>{family}</h3>
                <ElementContainerStyled>
                  {elements.map((element) => (
                    <Button
                      key={element.id}
                      shape="round"
                      className="element-btn"
                      onClick={() => handleAddPersonalizationItem(element.id)}
                    >
                      {element.element}
                    </Button>
                  ))}
                </ElementContainerStyled>
              </ElementWrapperStyled>
            ))}
          </Space>
        )}
        {(!groupedElements || Object.keys(groupedElements).length < 1) && (
          <div className="empty-container">
            <Empty />
          </div>
        )}
      </ContainerStyled>
    </AppModal>
  );
};

const ContainerStyled = styled.div`
  padding: 3.2rem 6.4rem;
  width: 60vw;
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
  align-items: flex-start;
  & > .empty-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ElementWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  & > h3 {
    font-size: 2.4rem;
    text-transform: capitalize;
    font-family: 'Gotham', sans-serif;
  }
`;

const ElementContainerStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2.4rem;
  row-gap: 1.8rem;
  .element-btn {
    text-transform: capitalize;
    width: 100%;
    min-height: 5.2rem;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
