import { ColorCustomization } from '@components';
import { IColorRangeAPI, IPersonalizationItem } from '@interfaces';
import {
  getTintsByColorAction,
  inspirationSelect,
  updatePersonalizationItem,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import React, { useState } from 'react';

interface IProps {
  personalizationItem: IPersonalizationItem;
}

export const ColorCustomizationModule: React.FC<IProps> = ({ personalizationItem }) => {
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { listColors, listTints } = useAppSelector(inspirationSelect);
  const [colorTitle, setColorTitle] = useState<string>('');
  const [selectedColorCode, setSelectedColorCode] = useState<string>(
    personalizationItem.rangeHexCode
  );

  const onColorBtnClick = async (color: IColorRangeAPI): Promise<void> => {
    await dispatch(getTintsByColorAction(color.range_hex_code));
    setColorTitle(color.colorRange);
    setSelectedColorCode(color.range_hex_code);
    setOpenModal(true);
  };

  const onCloseModal = (): void => {
    setOpenModal(false);
  };

  const onTintBtnClick = (tintHexCode: string): void => {
    dispatch(
      updatePersonalizationItem({
        ...personalizationItem,
        rangeHexCode: selectedColorCode,
        tintHexCode,
      })
    );
    onCloseModal();
  };

  return (
    <ColorCustomization
      colors={listColors}
      tints={listTints}
      onColorBtnClick={onColorBtnClick}
      onCloseModal={onCloseModal}
      openModal={openModal}
      colorTitle={colorTitle}
      selectedColor={personalizationItem.rangeHexCode}
      selectedTint={personalizationItem.tintHexCode}
      onTintBtnClick={onTintBtnClick}
    />
  );
};
