import moment from 'moment';

import {
  DATE_FORMAT,
  dateFormat,
  enumGender,
  HOUR_FORMAT,
  NOT_HAVE,
  PREV_DATE_FORMAT,
  PREV_HOUR_FORMAT,
} from '@configs';
import _ from 'lodash';
import { LogApp } from './utilities';

export const formatAppDate = (date?: string | number, endFormat?: string) => {
  const dateValue = Number(date);
  if (date) return moment(dateValue).format(endFormat || dateFormat);
  return '---';
};

export const formatPrevAppDate = (
  date?: string | Date,
  prevFormat?: string,
  endFormat?: string,
) => {
  if (date) return moment(date, prevFormat || PREV_DATE_FORMAT).format(endFormat || DATE_FORMAT);
  return '---';
};

export const formatAppName = (firstName?: string, lastName?: string) => {
  if (lastName && firstName) {
    return firstName + ' ' + lastName;
  }
  if (!firstName) {
    if (lastName) return lastName;
  } else {
    return firstName;
  }
  return '';
};

export const formatAppAddress = (
  address?: string,
  flat?: string,
  floor?: string,
  buildingName?: string,
) => {
  return `${flat ? `${flat}, ` : ''}${floor ? `${floor}, ` : ''}${
    buildingName ? `${buildingName}, ` : ''
  }${address || ''}`;
};

export const formatAppNumberValue = (
  value: string | number,
  unit: string,
  isEnglish: boolean,
  unitSuf?: 's' | 'es', // s or es
) => {
  if (Number(value) !== 1 && isEnglish) {
    return `${value} ${unit.concat(unitSuf || 's')}`;
  }
  return `${value || ''} ${unit}`;
};

export const formatAppHour = (
  hour?: string,
  prevFormat?: string,
  endFormat?: string,
  timezone?: string,
) => {
  if (hour) return moment(hour, prevFormat || PREV_HOUR_FORMAT).format(endFormat || HOUR_FORMAT);
  return 'none';
};

export const formatSEDate = (date?: Date, endFormat?: string) => {
  if (date) return moment(date).format(endFormat || PREV_DATE_FORMAT);
  return '';
};

export function formatAppUnit(x: number | string, sign?: string) {
  if (!x) return 0;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sign || ',');
}

export function cleanPayload(payload: any) {
  return _.omitBy(payload, (v: any) => {
    if (typeof v !== 'number' && !v) return true;
  });
}

export function formatUserFullname(first: string | null, last: string | null): string {
  if (first !== null && last !== null) {
    return `${first} ${last}`;
  }
  if (first !== null && last === null) {
    return `${first}`;
  }
  if (first === null && last !== null) {
    return `${last}`;
  }
  return '';
}

export const formatPersonalElementName = (elementFamily?: string, elementName?: string): string => {
  if (!elementFamily && elementName) return elementName;
  if (!elementName && elementFamily) return elementFamily;
  if (!elementFamily && !elementName) return 'Element';
  return [elementFamily, elementName].join(' - ');
};
