import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { InspirationViewAndEditSection } from '@pages';
import { PATH_INSPIRATION } from '@configs';
import { useEffect } from 'react';
import { useAppDispatch } from '@redux';
import { getInspirationDetailsAction } from 'src/redux/actions/inspiration';

export const InspirationViewAndEditModule: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const isViewPage = pathname.split('/').includes('details');

  const handleCancle = (): void => {
    navigate(PATH_INSPIRATION);
  };

  useEffect(() => {
    if (id === undefined) {
      navigate(PATH_INSPIRATION);
      return;
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const promise = dispatch(getInspirationDetailsAction(+id));

    return () => {
      promise.abort();
    };
  }, []);

  return <InspirationViewAndEditSection isViewPage={isViewPage} handleCancle={handleCancle} />;
};
