import { PATH_CREATE_INSPIRATION_PERSONALIZE, PATH_INSPIRATION } from '@configs';
import { yupResolver } from '@hookform/resolvers/yup';
import { InspirationCreateBasicSection } from '@pages';
import {
  inspirationSelect,
  resetCreateInspiration,
  setInspirationInfo,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { showAppToast } from '@utils';
import { InspirationSchema } from '@validations';
import { useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const InspirationCreateBasicModule: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    createInspirationForm: {
      title,
      description,
      indicativePrice,
      genderId,
      categoryId,
      subcategoryId,
      fabricId,
      properties,
      patternId,
      rangeHexCode,
      tintHexCode,
      inspirationPictures,
    },
  } = useAppSelector(inspirationSelect);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(InspirationSchema),
    mode: 'onChange',
  });

  const handleReturn = (): void => {
    navigate(PATH_INSPIRATION);
    dispatch(resetCreateInspiration());
  };

  const handleCreateBasic = handleSubmit((values, event) => {
    event?.preventDefault();
    if (!isValid) return;
    setValue('title', values.title.trim(), { shouldValidate: true });
    setValue('description', values.description.trim(), { shouldValidate: true });
    if (inspirationPictures.every((image) => image.mediaId === undefined)) {
      showAppToast('Please upload at least 1 inspiration photo', 'error', 'light');
      return;
    }
    if (!genderId) {
      showAppToast('Please select gender', 'error', 'light');
      return;
    }
    if (!categoryId) {
      showAppToast('Please select category', 'error', 'light');
      return;
    }
    if (!subcategoryId) {
      showAppToast('Please select sub-category', 'error', 'light');
      return;
    }
    if (!rangeHexCode) {
      showAppToast('Please select color range', 'error', 'light');
      return;
    }
    if (!tintHexCode) {
      showAppToast('Please select tint color', 'error', 'light');
      return;
    }
    if (!fabricId) {
      showAppToast('Please select material', 'error', 'light');
      return;
    }
    if (!properties || properties.length < 1) {
      showAppToast('Please select at least 1 property', 'error', 'light');
      return;
    }
    if (!patternId) {
      showAppToast('Please select pattern', 'error', 'light');
      return;
    }
    const { title, price, description } = values;

    dispatch(setInspirationInfo({ title, description, indicativePrice: +price }));
    navigate(PATH_CREATE_INSPIRATION_PERSONALIZE);
  });

  useLayoutEffect(() => {
    reset({
      title,
      price: indicativePrice,
      description,
    });
  }, [title, description, indicativePrice]);

  return (
    <InspirationCreateBasicSection
      handleReturn={handleReturn}
      handleCreateBasic={handleCreateBasic}
      register={register}
      errors={errors}
    />
  );
};
