import {
  IAddInspirationParams,
  IGetListParams,
  IToggleInspirationHightlightParams,
  IToggleInspirationStatusParams,
  IUpdateInspirationParams,
} from '@interfaces';
import axiosClient from './axiosClient';

export const inspirationAPI = {
  getAllInspirations: (params: IGetListParams) => {
    const url = '/api/inspiration';
    return axiosClient.get(url, { params });
  },
  toggleInspirationStatus: (params: IToggleInspirationStatusParams) => {
    const { id, enabled } = params;
    const url = `/api/inspiration/${id}`;
    return axiosClient.put(url, { enabled });
  },
  toggleInspirationHightlight: ({ id, onHomepage }: IToggleInspirationHightlightParams) => {
    const url = `/api/inspiration/${id}`;
    return axiosClient.put(url, { onHomepage });
  },
  getInspirationDetails: (id: number) => {
    const url = `/api/inspiration/${id}`;
    return axiosClient.get(url);
  },
  updateInspiration: (params: IUpdateInspirationParams) => {
    const { id, ...rest } = params;
    const url = `/api/inspiration/${id}`;
    return axiosClient.put(url, { ...rest });
  },
  addInspiration: (params: IAddInspirationParams) => {
    const url = '/api/inspiration';
    return axiosClient.post(url, params);
  },
};
